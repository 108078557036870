import { type LiveAppTableResponse } from '@/types/schema/LiveAppTable';

export const tableDemo: LiveAppTableResponse = {
  id: '665ddda7059a2f04c2b6a665',
  key: 'object_1',
  name: 'DemoForm',
  fields: [
    {
      type: 'short_text',
      required: true,
      default: 'Input Text',
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665ddda7059a2f04c2b6a667',
      name: 'Column 1',
      key: 'field_1'
    },
    {
      type: 'short_text',
      required: true,
      default: 'Input Text',
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665ddda7059a2f04c2b6a669',
      name: 'Column 2',
      key: 'field_2'
    }
  ]
};
