import { type ReportViewChart, type ReportViewRow } from '@/types/schema/views/ReportView';
import { ChartGroupContainer } from '@/components/views/report/ChartGroupContainer';
import { ChartRender } from '@/components/views/report/ChartRender';
import { SortableChartContextProvider, useSortableChartContext } from './SortableChartContext';

interface ChartsPageEditorPreviewProps {
  charts: ReportViewChart[];
  layout: ReportViewRow['layout'];
}

function ChartsPageEditorPreviewContent() {
  const { chart, chartData } = useSortableChartContext();
  return <ChartRender chart={chart} chartData={chartData} />;
}

export function ChartsPageEditorPreview({ charts, layout }: ChartsPageEditorPreviewProps) {
  return (
    <ChartGroupContainer layout={layout}>
      {charts.map((chart) => (
        <SortableChartContextProvider key={chart.id} chart={chart}>
          <ChartsPageEditorPreviewContent />
        </SortableChartContextProvider>
      ))}
    </ChartGroupContainer>
  );
}
