import { useQuery } from '@tanstack/react-query';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { type ChartData } from '@/types/schema/views/ReportView';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios, getAppBasedRequestHeaders } from '@/utils/axiosConfig';
import { usePageContext } from '@/context/PageContext';

async function getReport(pageKey: LiveAppPage['key'], viewKey: LiveAppViewKey) {
  if (!pageKey || !viewKey) {
    return undefined;
  }

  const { data } = await axios.get<{ reports: ChartData[] }>(
    `/v1/scenes/${pageKey}/views/${viewKey}/report?format=both&page=1&rows_per_page=25`,
    {
      withCredentials: true,
      headers: getAppBasedRequestHeaders()
    }
  );

  return data.reports;
}

export function useReportQuery({
  viewKey,
  enabled = true
}: {
  viewKey: LiveAppViewKey;
  enabled?: boolean;
}) {
  const { activePage } = usePageContext();

  if (!activePage) {
    throw new Error('Active page not found');
  }

  return useQuery({
    queryKey: [queryKeys.report, activePage.key, viewKey],
    queryFn: async () => getReport(activePage.key, viewKey),
    enabled,

    // We don't want this query to be refetched or invalidated unless the user manually refreshes the page
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    gcTime: Infinity
  });
}
