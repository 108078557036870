import { MapContainer, type MapContainerProps } from '@/components/views/map/engine/MapContainer';
import { MapContent, type MapContentProps } from '@/components/views/map/engine/MapContent';

export function Map({
  formattedViewRecords = [],
  selectedRecord,
  onSelectRecord,
  onUnselectRecord,
  addressFieldKey,
  titleFieldKey,
  mapHeight,
  pinColorDefault,
  pinColors,
  isEmptyState,
  fields = [],
  isSmallVersion,
  isLoading,
  isSearchTermValid,
  searchTerm,
  searchCoordinates
}: MapContainerProps & MapContentProps) {
  return (
    <MapContainer isSmallVersion={isSmallVersion} mapHeight={mapHeight}>
      <MapContent
        formattedViewRecords={formattedViewRecords}
        selectedRecord={selectedRecord}
        onSelectRecord={onSelectRecord}
        onUnselectRecord={onUnselectRecord}
        addressFieldKey={addressFieldKey}
        titleFieldKey={titleFieldKey}
        pinColorDefault={pinColorDefault}
        pinColors={pinColors}
        isEmptyState={isEmptyState}
        fields={fields}
        isLoading={isLoading}
        isSearchTermValid={isSearchTermValid}
        searchTerm={searchTerm}
        searchCoordinates={searchCoordinates}
      />
    </MapContainer>
  );
}
