import { type ReportViewRow } from '@/types/schema/views/ReportView';
import { cn } from '@/utils/tailwind';

interface ChartGroupContainerProps {
  layout: ReportViewRow['layout'];
  children: React.ReactNode;
}

export function ChartGroupContainer({ layout, children }: ChartGroupContainerProps) {
  return (
    <div
      className={cn('grid grid-cols-1 gap-4', {
        'md:grid-cols-2': layout === 'half',
        'md:grid-cols-3': layout === 'thirds'
      })}
    >
      {children}
    </div>
  );
}
