export const queryKeys = {
  application: 'application',
  account: 'account',
  gravatar: 'gravatar',
  page: 'page',
  entryPages: 'entry-pages',
  session: 'session',
  table: 'table',
  report: 'report',
  userRoles: 'user-roles',
  connectionRecord: 'connection-record',
  viewData: 'view-data',
  auth: {
    session: 'session'
  },
  export: 'export',
  addressGeoCodeData: 'address-geocode-data',
  credential: 'credential'
} as const;
