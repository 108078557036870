import { ColorsBorder } from './ColorsBorder';
import { ColorsBrand } from './colorsBrand';
import { ColorsSurface } from './ColorsSurface';
import { ColorsText } from './ColorsText';

export function ColorsDemo() {
  return (
    <>
      <ColorsBrand />
      <ColorsText />
      <ColorsSurface />
      <ColorsBorder />
    </>
  );
}
