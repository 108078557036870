import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { IMask, useIMask } from 'react-imask';
import { Input } from '@knack/asterisk-react';

import { type FormViewPhoneInput } from '@/types/schema/views/form/Phone';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';

const EXTENSION_MASK = ' x00000';

const phoneFormatMapping = {
  '(999) 999-9999': '(000) 000-0000',
  '999.999.9999': '000.000.0000',
  '+99 999 999 99?99': '+00 000 000 0000',
  '+99 (0)999 999 99?99': '+00 (\\0)000 000 0000',
  '99 9999 9999': '00 0000 0000',
  '9999 999 999': '0000 000 000',
  any: undefined
} as const;

const emptyPlaceholders = {
  '(999) 999-9999': '(___) ___-____',
  '999.999.9999': '___.___.____',
  '+99 999 999 99?99': '+__ ___ ___ ____',
  '+99 (0)999 999 99?99': '+__ (0)___ ___ ____',
  '99 9999 9999': '__ ____ ____',
  '9999 999 999': '____ ___ ___',
  any: undefined
} as const;

export function PhoneInput({ input }: { input: FormViewPhoneInput }) {
  const { view } = useViewContext();
  const {
    setValue: setFormValue,
    formState: { errors },
    register,
    getValues
  } = useFormContext();

  const inputValue = getValues(input.field.key);
  const includeExtension = input.format.extension;
  const phoneFormat = includeExtension
    ? phoneFormatMapping[input.format.format] + EXTENSION_MASK
    : phoneFormatMapping[input.format.format];
  const placeholder = emptyPlaceholders[input.format.format];

  const getCurrentValueWithoutPlaceholder = useCallback(
    (currentValue: string) => {
      // When using lazy:false, the value will include the placeholder. It doesn't seem possible to get the value without the placeholder
      // So here we run the mask again without lazy manually to get the phone number without the placeholder characters
      const phoneMask = IMask.createPipe({
        mask: phoneFormat
      });
      return phoneMask(currentValue);
    },
    [phoneFormat]
  );

  const {
    ref: inputRef,
    typedValue,
    value: iMaskValue
  } = useIMask<HTMLInputElement>(
    {
      mask: phoneFormat,
      lazy: false,
      placeholderChar: '_'
    },
    {
      defaultValue: inputValue,
      onAccept: (value) => {
        const valueWithoutPlaceholder = getCurrentValueWithoutPlaceholder(value);
        setFormValue(input.field.key, valueWithoutPlaceholder);
      }
    }
  );

  const { ref: formRef, onBlur, ...formRegister } = register(input.field.key);

  if (input.format.format === 'any') {
    return (
      <>
        <Input
          id={`${view.key}-${input.id}`}
          intent={errors[input.field.key] ? 'destructive' : 'default'}
          {...register(input.field.key)}
        />
        <FormErrorMessage errors={errors} name={input.field.key} />
      </>
    );
  }

  return (
    <>
      <Input
        id={`${view.key}-${input.id}`}
        disabled={input.read_only}
        ref={inputRef}
        placeholder={placeholder}
        intent={errors[input.field.key] ? 'destructive' : 'default'}
        value={typedValue !== undefined ? iMaskValue : inputValue}
        {...formRegister}
      />
      <FormErrorMessage errors={errors} name={input.field.key} />
    </>
  );
}
