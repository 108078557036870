import type { Active, Over } from '@dnd-kit/core';
import { useDndContext } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { type MenuViewLink } from '@/types/schema/views/MenuView';
import { MenuLinkEditor } from './MenuLinkEditor';

export function getLinkInsertPosition(
  active: Active | null,
  over: Over | null,
  link: MenuViewLink
) {
  if (over?.id === link.id && active?.id !== link.id) {
    const overIndex: number = over.data.current?.sortable.index ?? -1;
    const activeIndex: number = active?.data.current?.sortable.index ?? -1;

    return overIndex > activeIndex ? 'after' : 'before';
  }

  return null;
}

export function SortableMenuLink({ link }: { link: MenuViewLink }) {
  const dndContext = useDndContext();

  const { attributes, setNodeRef, transform, transition, isSorting } = useSortable({
    id: link.id,
    data: {
      type: 'input'
    }
  });

  const style = {
    transition,
    transform: isSorting ? undefined : CSS.Translate.toString(transform)
  };

  const insertPositionState = getLinkInsertPosition(dndContext.active, dndContext.over, link);

  return (
    <div ref={setNodeRef} className="relative flex" style={style} {...attributes}>
      <MenuLinkEditor link={link} draggingOverInsertPosition={insertPositionState} />
    </div>
  );
}
