import { useRef, useState } from 'react';
import { Controller, useFormContext, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePicker, Input } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import { type FormViewTimerInput } from '@/types/schema/views/form/Timer';
import { DAY_PICKER_FROM_YEAR, DAY_PICKER_TO_YEAR } from '@/utils/constants';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { defaultDateFormatMap } from '@/components/views/form/inputs/date-time/types';
import {
  getFormattedDatePickerDate,
  getTimeValue
} from '@/components/views/form/inputs/date-time/utils';
import { type TimerPayload } from '@/components/views/form/inputs/timer/types';
import { TimeInput } from '@/components/views/form/TimeInput';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';
import { getRawTime } from './helper';

export function TimerInput({ input }: { input: FormViewTimerInput }) {
  const [t] = useTranslation();
  const { theme } = useThemingContext();
  const { view } = useViewContext();
  const {
    formState: { errors }
  } = useFormContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const inputFormat = input.format;
  const isReadOnly = input.read_only;
  const dateFormat = defaultDateFormatMap[inputFormat.date_format];
  const defaultAmPmValue = 'AM';

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldValue: TimerPayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    const { value, id } = e.target;
    const isEndDateInput = id === `${view.key}-${input.id}-end-date-input`;
    // Only allow numbers and slashes
    if (!/^[0-9/]*$/.test(value)) {
      return;
    }

    if (isEndDateInput) {
      onChange({
        ...fieldValue,
        to: {
          ...fieldValue.to,
          date: value
        }
      });
    } else {
      onChange({
        ...fieldValue,
        from: {
          ...fieldValue.from,
          date: value
        }
      });
    }
  };

  const handleSelectDate = (
    date: Date,
    fieldValue: TimerPayload,
    onChange: ControllerRenderProps['onChange'],
    isEndDate?: boolean
  ) => {
    if (isEndDate) {
      onChange({
        ...fieldValue,
        to: { ...fieldValue.to, date: DateTime.fromJSDate(date).toFormat(dateFormat) }
      });
      return;
    }
    onChange({
      ...fieldValue,
      from: { ...fieldValue.from, date: DateTime.fromJSDate(date).toFormat(dateFormat) }
    });
  };

  return (
    <>
      <div
        className={cn(
          'flex h-auto min-w-[200px] flex-wrap items-center gap-1 border border-default p-1.5',
          {
            'border-destructive': errors[input.field.key],
            'rounded-lg': theme.appearance.corners === 'rounded'
          }
        )}
      >
        <Controller
          name={input.field.key}
          render={({ field: { value: fieldValue, onChange } }) => {
            const formattedFromDate = getFormattedDatePickerDate(fieldValue.from?.date, dateFormat);
            const formattedToDate = getFormattedDatePickerDate(fieldValue.to?.date, dateFormat);
            const timeValueFrom =
              fieldValue.from?.rawTime || getRawTime(fieldValue.from, inputFormat.time_format);
            const timeValueEnd =
              fieldValue.to?.rawTime || getRawTime(fieldValue.to, inputFormat.time_format);

            return (
              <>
                <DatePicker
                  mode="single"
                  selected={formattedFromDate}
                  onSelect={(date) => handleSelectDate(date, fieldValue, onChange)}
                  triggerRef={inputRef}
                  onDatePickerOpenChange={setIsDatePickerOpen}
                  calendarProps={{
                    mode: 'single',
                    fromYear: DAY_PICKER_FROM_YEAR,
                    toYear: DAY_PICKER_TO_YEAR
                  }}
                >
                  <Input
                    disabled={isReadOnly}
                    ref={inputRef}
                    id={input.id}
                    value={fieldValue.from?.date}
                    className={cn(
                      'h-auto w-auto min-w-16 max-w-[90px] border-0 bg-muted p-1 text-center',
                      {
                        rounded: theme.appearance.corners === 'rounded'
                      }
                    )}
                    data-testid="form-view-timer-input"
                    onChange={(e) => handleInputChange(e, fieldValue, onChange)}
                    onClick={(e) => {
                      if (isDatePickerOpen) {
                        e.stopPropagation();
                      }
                    }}
                  />
                </DatePicker>
                <TimeInput
                  inputProps={{
                    disabled: isReadOnly
                  }}
                  format={inputFormat.time_format}
                  value={timeValueFrom}
                  onValueChange={(time) =>
                    onChange({
                      ...fieldValue,
                      ...getTimeValue(time)
                    })
                  }
                >
                  {inputFormat.time_format === 'HH:MM am' && (
                    <TimeInput.TimeModeSelect
                      value={fieldValue?.am_pm || defaultAmPmValue}
                      onValueChange={(val) =>
                        onChange({
                          ...fieldValue,
                          am_pm: val
                        })
                      }
                    />
                  )}
                </TimeInput>
                <span>{t('keywords.to')}</span>
                <DatePicker
                  mode="single"
                  selected={formattedToDate}
                  onSelect={(date) => handleSelectDate(date, fieldValue, onChange, true)}
                  triggerRef={inputRef}
                  onDatePickerOpenChange={setIsDatePickerOpen}
                  calendarProps={{
                    mode: 'single',
                    fromYear: DAY_PICKER_FROM_YEAR,
                    toYear: DAY_PICKER_TO_YEAR
                  }}
                >
                  <Input
                    ref={endInputRef}
                    disabled={isReadOnly}
                    id={`${view.key}-${input.id}-end-date-input`}
                    value={fieldValue.to?.date}
                    className={cn(
                      'size-auto min-w-16 max-w-[90px] border-0 bg-muted p-1 text-center',
                      {
                        rounded: theme.appearance.corners === 'rounded'
                      }
                    )}
                    data-testid="form-view-timer-input"
                    onChange={(e) => handleInputChange(e, fieldValue, onChange)}
                    onClick={(e) => {
                      if (isDatePickerOpen) {
                        e.stopPropagation();
                      }
                    }}
                  />
                </DatePicker>
                <TimeInput
                  inputProps={{
                    disabled: isReadOnly
                  }}
                  format={inputFormat.time_format}
                  value={timeValueEnd}
                  onValueChange={(time) =>
                    onChange({
                      ...fieldValue,
                      to: { ...fieldValue.to, ...getTimeValue(time) }
                    })
                  }
                >
                  {inputFormat.time_format === 'HH:MM am' && (
                    <TimeInput.TimeModeSelect
                      value={fieldValue?.to?.am_pm || defaultAmPmValue}
                      onValueChange={(val) =>
                        onChange({
                          ...fieldValue,
                          to: { ...fieldValue.to, am_pm: val }
                        })
                      }
                    />
                  )}
                </TimeInput>
              </>
            );
          }}
        />
      </div>
      <FormErrorMessage errors={errors} name={input.field.key} />
    </>
  );
}
