import { type ChartData } from '@/types/schema/views/ReportView';

export const pieChartData: ChartData[] = [
  {
    records: [
      {
        group_0: 'Apples',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Oranges',
        calc_0: 2,
        raw_0: 2
      },
      {
        group_0: 'Bananas',
        calc_0: 3,
        raw_0: 3
      },
      {
        group_0: 'Lemons',
        calc_0: 4,
        raw_0: 4
      },
      {
        group_0: 'Carrots',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Melon',
        calc_0: 6,
        raw_0: 6
      },
      {
        group_0: 'WaterMelon',
        calc_0: 2,
        raw_0: 2
      },
      {
        group_0: 'Coconut',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Mango',
        calc_0: 5,
        raw_0: 5
      },
      {
        group_0: 'Strawberry',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Raspberry',
        calc_0: 3,
        raw_0: 3
      },
      {
        group_0: 'Kiwi',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Guava',
        calc_0: 2,
        raw_0: 2
      },
      {
        group_0: 'Kaki',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Mandalin',
        calc_0: 4,
        raw_0: 4
      },
      {
        group_0: 'Papaya',
        calc_0: 3,
        raw_0: 3
      }
    ],
    filters: [
      {
        header: 'Apples'
      },
      {
        header: 'Oranges'
      },
      {
        header: 'Bananas'
      },
      {
        header: 'Lemons'
      },
      {
        header: 'Carrots'
      },
      {
        header: 'Melon'
      },
      {
        header: 'WaterMelon'
      },
      {
        header: 'Coconut'
      },
      {
        header: 'Mango'
      },
      {
        header: 'Strawberry'
      },
      {
        header: 'Raspberry'
      },
      {
        header: 'Kiwi'
      },
      {
        header: 'Guava'
      },
      {
        header: 'Kaki'
      },
      {
        header: 'Mandalin'
      },
      {
        header: 'Papaya'
      }
    ],
    summaries: []
  },
  {
    records: [
      {
        group_0: 'Apples',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Oranges',
        calc_0: 2,
        raw_0: 2
      },
      {
        group_0: 'Bananas',
        calc_0: 3,
        raw_0: 3
      },
      {
        group_0: 'Lemons',
        calc_0: 4,
        raw_0: 4
      },
      {
        group_0: 'Carrots',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Melon',
        calc_0: 6,
        raw_0: 6
      },
      {
        group_0: 'WaterMelon',
        calc_0: 2,
        raw_0: 2
      },
      {
        group_0: 'Coconut',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Mango',
        calc_0: 5,
        raw_0: 5
      },
      {
        group_0: 'Strawberry',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Raspberry',
        calc_0: 3,
        raw_0: 3
      },
      {
        group_0: 'Kiwi',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Guava',
        calc_0: 2,
        raw_0: 2
      },
      {
        group_0: 'Kaki',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Mandalin',
        calc_0: 4,
        raw_0: 4
      },
      {
        group_0: 'Papaya',
        calc_0: 3,
        raw_0: 3
      }
    ],
    filters: [
      {
        header: 'Apples'
      },
      {
        header: 'Oranges'
      },
      {
        header: 'Bananas'
      },
      {
        header: 'Lemons'
      },
      {
        header: 'Carrots'
      },
      {
        header: 'Melon'
      },
      {
        header: 'WaterMelon'
      },
      {
        header: 'Coconut'
      },
      {
        header: 'Mango'
      },
      {
        header: 'Strawberry'
      },
      {
        header: 'Raspberry'
      },
      {
        header: 'Kiwi'
      },
      {
        header: 'Guava'
      },
      {
        header: 'Kaki'
      },
      {
        header: 'Mandalin'
      },
      {
        header: 'Papaya'
      }
    ],
    summaries: []
  },
  {
    records: [
      {
        group_0: 'Apples',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Oranges',
        calc_0: 2,
        raw_0: 2
      },
      {
        group_0: 'Bananas',
        calc_0: 3,
        raw_0: 3
      },
      {
        group_0: 'Lemons',
        calc_0: 4,
        raw_0: 4
      },
      {
        group_0: 'Carrots',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Melon',
        calc_0: 6,
        raw_0: 6
      },
      {
        group_0: 'WaterMelon',
        calc_0: 2,
        raw_0: 2
      },
      {
        group_0: 'Coconut',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Mango',
        calc_0: 5,
        raw_0: 5
      },
      {
        group_0: 'Strawberry',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Raspberry',
        calc_0: 3,
        raw_0: 3
      },
      {
        group_0: 'Kiwi',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Guava',
        calc_0: 2,
        raw_0: 2
      },
      {
        group_0: 'Kaki',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Mandalin',
        calc_0: 4,
        raw_0: 4
      },
      {
        group_0: 'Papaya',
        calc_0: 3,
        raw_0: 3
      }
    ],
    filters: [
      {
        header: 'Apples'
      },
      {
        header: 'Oranges'
      },
      {
        header: 'Bananas'
      },
      {
        header: 'Lemons'
      },
      {
        header: 'Carrots'
      },
      {
        header: 'Melon'
      },
      {
        header: 'WaterMelon'
      },
      {
        header: 'Coconut'
      },
      {
        header: 'Mango'
      },
      {
        header: 'Strawberry'
      },
      {
        header: 'Raspberry'
      },
      {
        header: 'Kiwi'
      },
      {
        header: 'Guava'
      },
      {
        header: 'Kaki'
      },
      {
        header: 'Mandalin'
      },
      {
        header: 'Papaya'
      }
    ],
    summaries: []
  }
];
