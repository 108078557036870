import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiTrash as DeleteIcon, HiPencil as EditIcon } from 'react-icons/hi2';
import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';

import { type FormView, type FormViewInput } from '@/types/schema/views/FormView';
import { cn } from '@/utils/tailwind';
import { FormFieldInput } from '@/components/views/form/FormFieldInput';
import { FormStaticInput } from '@/components/views/form/FormStaticInput';
import { useViewContext } from '@/components/views/ViewContext';
import { DragInsertIndicator } from '@/pages/page/page-editor/page-editor-sortable/DragInsertIndicator';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import {
  useSectionEditorContext,
  type ViewInputHoverIdentifier
} from '@/pages/page/page-editor/SectionEditorContext';

interface FormInputEditorProps {
  input: FormViewInput;
  draggingOverInsertPosition?: 'before' | 'after' | null;
  isDragOverlay?: boolean;
}

export function FormInputEditor({
  input,
  draggingOverInsertPosition,
  isDragOverlay
}: FormInputEditorProps) {
  const [t] = useTranslation();

  const { view } = useViewContext<FormView>();
  const { updatePage, selectedItem, isDraggingActive, startEditViewInput } = usePageEditorContext();
  const { setViewInputHover, currentHoveredViewInput } = useSectionEditorContext();
  const { listeners, setActivatorNodeRef, isDragging } = useSortable({
    id: input.id
  });

  const isConnectionInputWithChildForm = input.type === 'connection' && !!input.view;
  const hasActiveChildFormView =
    isConnectionInputWithChildForm &&
    selectedItem?.type === 'view' &&
    selectedItem.view.type === 'form' &&
    selectedItem.view.parent === view.key;

  const viewInputHoverIdentifier: ViewInputHoverIdentifier = `${view.key}:${input.id}`;
  const isInputHovered = currentHoveredViewInput === viewInputHoverIdentifier;
  const isInputActiveState = (isInputHovered || isDragOverlay) && !hasActiveChildFormView;

  const isInputEditable = input.type !== 'divider';
  const isStaticInput = input.type === 'section_break' || input.type === 'divider';

  function startEditInput() {
    startEditViewInput({ viewKey: view.key, viewInputId: input.id });
  }

  function onInputDelete(event: React.MouseEvent) {
    event.stopPropagation();

    updatePage({
      type: 'view',
      origin: 'live-app',
      action: 'update',
      updatedView: {
        ...view,
        groups: view.groups.map((group) => ({
          ...group,
          columns: group.columns.map((column) => ({
            ...column,
            inputs: column.inputs.filter((i) => i.id !== input.id)
          }))
        }))
      }
    });
  }

  function onInputClick(event: React.MouseEvent) {
    // We want to ensure that the click event originated from within the input and not from some other element that propagated the event back to the input.
    // For instance, when rendering a child form view in a modal, clicking on the modal backdrop should not trigger the click event on this connection input.
    if (event.target !== event.currentTarget) {
      return;
    }

    event.stopPropagation();
    startEditInput();
  }

  function onInputKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    event.preventDefault();

    if (event.key === 'Enter' || event.key === 'Space') {
      startEditInput();
    }
  }

  // Reset input hover state when component unmounts
  useEffect(
    () => () => {
      setViewInputHover(null);
    },
    [setViewInputHover]
  );

  return (
    <>
      <DragInsertIndicator isVisible={draggingOverInsertPosition === 'before'} />

      <section
        role="button"
        tabIndex={0}
        onClick={onInputClick}
        onKeyDown={onInputKeyDown}
        {...(!isDraggingActive && {
          onMouseEnter: () => setViewInputHover(viewInputHoverIdentifier),
          onMouseLeave: () => setViewInputHover(null)
        })}
        className={cn('relative -mx-1 rounded-lg border border-transparent', {
          'border-blue-500 after:absolute after:inset-0 after:bg-blue-500/10':
            isInputActiveState || isDragging,
          'opacity-20': isDragging,
          'bg-card': isDragOverlay
        })}
      >
        <div
          className={cn('-my-[1px] px-[3px]', {
            'pointer-events-none': !isConnectionInputWithChildForm
          })}
        >
          {isStaticInput ? <FormStaticInput input={input} /> : <FormFieldInput input={input} />}
        </div>

        {isInputActiveState && !isDragging && (
          <div className="border-1 absolute -left-[1px] top-0 z-10 rounded-br-md rounded-tl-lg border-blue-500 bg-blue-500">
            <div className="flex h-6 items-center px-1 text-white">
              <button
                type="button"
                className="mr-1 inline-flex size-5 cursor-move items-center justify-center rounded-md text-white hover:bg-white/25"
                ref={setActivatorNodeRef}
                {...listeners}
              >
                <DragIcon size={16} />
              </button>
              <button
                type="button"
                aria-label={t('actions.edit')}
                className={cn(
                  'mr-1 inline-flex size-5 items-center justify-center rounded-md text-white hover:bg-white/25',
                  {
                    'cursor-not-allowed': !isInputEditable
                  }
                )}
                onClick={startEditInput}
                disabled={!isInputEditable}
              >
                <EditIcon size={14} />
              </button>
              <button
                type="button"
                aria-label={t('actions.delete')}
                className="inline-flex size-5 items-center justify-center rounded-md text-white hover:bg-white/25"
                onClick={onInputDelete}
              >
                <DeleteIcon size={14} />
              </button>
            </div>
          </div>
        )}
      </section>

      <DragInsertIndicator isVisible={draggingOverInsertPosition === 'after'} />
    </>
  );
}
