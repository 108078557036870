import { useTranslation } from 'react-i18next';
import { Banner, Button, Input, Label, Select } from '@knack/asterisk-react';

import { ColorGroup, ExampleGroup, Grid } from './utils';

export function ColorsText() {
  const [t] = useTranslation();

  return (
    <Grid title={t('components.page_editor.demo_page.text')}>
      <ColorGroup
        color="content-subtle"
        name="Subtle"
        description="Used in hint texts under inputs and secondary buttons"
      >
        <ExampleGroup group="Selects & Inputs" isSingleColumn>
          <div className="mb-4 flex flex-col">
            <Input.Container>
              <Input className="min-w-60" placeholder="Example Text Input" />
              <Input.Hint>This is hint text</Input.Hint>
            </Input.Container>
          </div>
          <div className="mb-4 flex flex-col">
            <Select>
              <Select.Trigger placeholder="Select an option" className="min-w-60" />
              <Select.Content>
                <Select.Item value="light">Light</Select.Item>
                <Select.Item value="dark">Dark</Select.Item>
                <Select.Item value="system">System</Select.Item>
              </Select.Content>
            </Select>
            <Input.Hint>This is hint text</Input.Hint>
          </div>
        </ExampleGroup>
        <ExampleGroup group="Button">
          <Button intent="secondary" disabled>
            Disabled Secondary Button
          </Button>
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="content-default"
        name="Default"
        description="Used in most text bodies, including labels and table headers"
      >
        <p className="mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque auctor id lectus in
          rutrum. Nam rhoncus, augue sed mollis vehicula, nisl lectus ultrices turpis, vel convallis
          erat ex vel ex. Aenean sed velit ut odio pretium faucibus. Nunc consequat nec mi ultricies
          consequat.
        </p>
        <p>
          Quisque rhoncus massa et nunc volutpat, vehicula lobortis lorem elementum. Aenean at orci
          quam. Suspendisse ornare est at tortor interdum, vitae fringilla libero fermentum. Etiam
          dignissim nibh eget urna fermentum lacinia. Praesent orci neque, vulputate eu sem in,
          cursus ullamcorper augue.
        </p>
      </ColorGroup>
      <ColorGroup
        color="content-muted"
        name="Text Muted"
        description="Used in tint texts inside inputs"
      >
        <ExampleGroup group="Text Input" isSingleColumn>
          <Input className="min-w-60" placeholder="Example Muted Text" />
        </ExampleGroup>
        <ExampleGroup group="Dropdown">
          <Select>
            <Select.Trigger placeholder="Example Muted Text" className="min-w-60" />
            <Select.Content>
              <Select.Item value="light">Light</Select.Item>
              <Select.Item value="dark">Dark</Select.Item>
              <Select.Item value="system">System</Select.Item>
            </Select.Content>
          </Select>
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="content-emphasis"
        name="Emphasis"
        description="Used in page titles, element titles, and secondary buttons"
      >
        <h1 className="mb-6 text-2xl text-emphasis">Example Header</h1>
        <ExampleGroup group="Button">
          <Button intent="secondary">Secondary Button</Button>
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="content-success-emphasis"
        name="Success"
        description="Used in success messages"
      >
        <ExampleGroup group="Text">
          <span className="text-success-emphasis">
            This sample text demonstrates how content will appear
          </span>
        </ExampleGroup>
        <ExampleGroup group="Notification">
          <Banner title="Success Notification" className="w-full" intent="success" type="inline">
            <Banner.Message>This sample text demonstrates how content will appear</Banner.Message>
          </Banner>
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="content-warning-emphasis"
        name="Warning"
        description="Used in warning messages"
      >
        <ExampleGroup group="Text">
          <span className="text-warning-emphasis">
            This sample text demonstrates how content will appear
          </span>
        </ExampleGroup>
        <ExampleGroup group="Notification">
          <Banner title="Warning Notification" className="w-full" intent="warning" type="inline">
            <Banner.Message>This sample text demonstrates how content will appear</Banner.Message>
          </Banner>
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="content-destructive"
        name="Destructive"
        description="Used in destructive messages, secondary destructive buttons, and destructive states for selects and inputs"
      >
        <ExampleGroup group="Text">
          <span className="text-destructive">
            This sample text demonstrates how content will appear
          </span>
        </ExampleGroup>
        <ExampleGroup group="Secondary Destructive Button">
          <Button intent="destructiveSecondary">Secondary Button</Button>
        </ExampleGroup>
        <ExampleGroup group="Selects & Inputs" isSingleColumn>
          <div className="mb-4 flex flex-col">
            <Label intent="destructive" className="mb-1 text-md">
              Text Input
            </Label>
            <Input className="min-w-60" intent="destructive" placeholder="Example Muted Text" />
            <span className="mt-2 text-xs text-destructive">This is hint text</span>
          </div>
          <div className="mb-4 flex flex-col">
            <Label intent="destructive" className="mb-1 text-md">
              Text Dropdown
            </Label>
            <Select>
              <Select.Trigger
                placeholder="Example Muted Text"
                className="min-w-60"
                intent="destructive"
              />
              <Select.Content>
                <Select.Item value="light">Light</Select.Item>
                <Select.Item value="dark">Dark</Select.Item>
                <Select.Item value="system">System</Select.Item>
              </Select.Content>
            </Select>
            <span className="mt-2 text-xs text-destructive">This is hint text</span>
          </div>
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="content-destructive-emphasis"
        name="Destructive (Notification Only)"
        description="Used in destructive messages"
      >
        <ExampleGroup group="Text">
          <span className="text-destructive-emphasis">
            This sample text demonstrates how content will appear
          </span>
        </ExampleGroup>
        <ExampleGroup group="Notification">
          <Banner
            title="Destructive Notification"
            className="w-full"
            intent="destructive"
            type="inline"
          >
            <Banner.Message>This sample text demonstrates how content will appear</Banner.Message>
          </Banner>
        </ExampleGroup>
      </ColorGroup>
    </Grid>
  );
}
