import { useQuery } from '@tanstack/react-query';

import { type Session } from '@/types/session';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { useApplicationQuery } from './useApplicationQuery';

export async function getSession(id: string | undefined) {
  if (!id) {
    return null;
  }

  const { data } = await axios.get<{ session: Session }>(`/v1/live-app/${id}/session`, {
    withCredentials: true
  });

  return data.session;
}

export function useSessionQuery() {
  const { data: application } = useApplicationQuery();

  return useQuery({
    queryKey: [queryKeys.session],
    queryFn: () => getSession(application?.id),
    refetchOnMount: false
  });
}
