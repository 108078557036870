import { type ListViewGroupColumn } from '@/types/schema/views/ListView';
import type { FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { DetailsItemContent } from '@/components/views/details/DetailsItemContent';

type DetailsItemProps = {
  record: FormattedViewRecord;
  columns: ListViewGroupColumn[];
  isSmallVersion?: boolean;
};

export function DetailsItem({ columns, record, isSmallVersion }: DetailsItemProps) {
  return (
    <div className={cn('flex w-full gap-4 p-3', { 'flex-wrap': isSmallVersion })}>
      {columns.map((column) => (
        <div key={column.id} style={{ width: isSmallVersion ? '100%' : `${column.width}%` }}>
          {column.groups.map((group) => (
            <DetailsItemContent key={group.id} record={record} group={group} />
          ))}
        </div>
      ))}
    </div>
  );
}
