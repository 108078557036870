import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { type KnackFieldKey } from '@/types/schema/fields/KnackField';
import { type DetailsView } from '@/types/schema/views/DetailsView';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type TableView } from '@/types/schema/views/TableView';
import { type ViewFilters } from '@/hooks/useViewFilters';

const getDefaultSorting = (view: MapView | TableView | ListView | DetailsView) => {
  if (view.type === 'table') {
    const sortableColumn = view.columns.find((column) => column.field?.key);
    const sortOrder: ViewSortOrder = 'asc';
    return { sortField: sortableColumn?.field?.key, sortOrder };
  }
  return {};
};

const getDefaultParams = (view: TableView | ListView | MapView) => {
  const defaultParams: ViewQueryParams = {
    rowsPerPage: view.rows_per_page,
    page: 1,
    search: '',
    filters: null,
    mapFilters: null,
    allFilters: null,
    ...getDefaultSorting(view)
  };
  return defaultParams;
};

const extractFilters = (
  filtersParam: any
): {
  filters: ViewFilters | null;
  mapFilters: ViewFilters | null;
  allFilters: ViewFilters | null;
} => {
  if (!filtersParam) return { filters: null, mapFilters: null, allFilters: null };

  const parsedFilters = JSON.parse(filtersParam);
  const mapRules = parsedFilters.rules.filter((rule: any) => rule.operator === 'near');
  const filtersRules = parsedFilters.rules.filter((rule: any) => rule.operator !== 'near');

  return {
    filters: filtersRules.length > 0 ? { ...parsedFilters, rules: filtersRules } : null,
    mapFilters: mapRules.length > 0 ? { ...parsedFilters, rules: mapRules } : null,
    allFilters: parsedFilters
  };
};

export type ViewParam = keyof ViewQueryParams;

export type ViewSortOrder = 'asc' | 'desc';

export interface ViewQueryParams {
  rowsPerPage: string | null;
  page: number | null;
  search: string | null;
  filters: ViewFilters | null;
  mapFilters: ViewFilters | null;
  allFilters: ViewFilters | null;
  sortField?: KnackFieldKey | undefined;
  sortOrder?: ViewSortOrder;
}

export type ViewWithSearchParams = TableView | ListView | MapView;

export function useViewSearchParams(view: ViewWithSearchParams) {
  const [searchParams, setSearchParams] = useSearchParams();

  const viewOptions = useMemo(() => {
    const defaultParams = getDefaultParams(view);
    const viewParams = Object.fromEntries(searchParams.entries());
    if (!viewParams) return defaultParams;

    return Object.keys(viewParams).reduce((accumulatedParams, param) => {
      if (param.startsWith(`${view.key}_`)) {
        const extractedParam = param.substring(view.key.length + 1);
        const value =
          extractedParam === 'filters' ? extractFilters(viewParams[param]) : viewParams[param];

        const filters = extractedParam === 'filters' ? value : {};
        return {
          ...accumulatedParams,
          [extractedParam]: value,
          ...filters
        };
      }
      return accumulatedParams;
    }, defaultParams);
  }, [searchParams, view]);

  const setViewParam = (newParams: Partial<ViewQueryParams>) => {
    Object.entries(newParams).forEach(([key, value]) => {
      const prefixedKey = `${view.key}_${key as ViewParam}` as const;
      if (value === null) {
        searchParams.delete(prefixedKey);
        return;
      }
      if (value) {
        if (key === 'filters') {
          searchParams.set(prefixedKey, JSON.stringify(value));
          return;
        }
        if (typeof value === 'number') {
          searchParams.set(prefixedKey, value.toString());
          return;
        }
        searchParams.set(prefixedKey, value as string);
      }
    });
    setSearchParams(searchParams, { replace: true });
  };

  return {
    rowsPerPage: viewOptions.rowsPerPage,
    sortOrder: viewOptions.sortOrder,
    sortField: viewOptions.sortField,
    searchValueFromParams: viewOptions.search,
    currentPage: viewOptions.page,
    activeViewFilters: viewOptions.filters,
    setViewParam,
    mapFilters: viewOptions.mapFilters,
    allFilters: viewOptions.allFilters
  };
}
