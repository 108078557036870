import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios, getAppBasedRequestHeaders } from '@/utils/axiosConfig';

interface GeocodeResponse {
  latitude: number;
  longitude: number;
}

type GeocodeQuery = string | null;

async function getAddressGeocodeData(query: GeocodeQuery) {
  if (!query) {
    return undefined;
  }

  const { accountSlug, appSlug } = getApplicationBasePathSegments();
  const url = `/v1/live-app/${accountSlug}/${appSlug}/geocode`;
  const queryParams = new URLSearchParams({ q: query });

  const { data } = await axios.get<GeocodeResponse>(url, {
    params: queryParams,
    withCredentials: true,
    headers: getAppBasedRequestHeaders()
  });

  return data;
}

export function useGeocodeQuery({
  enabled = true,
  query
}: {
  enabled?: boolean;
  query: GeocodeQuery;
}) {
  const queryKey = [queryKeys.addressGeoCodeData, query];

  return useQuery({
    queryKey,
    queryFn: () => getAddressGeocodeData(query),
    enabled
  });
}
