import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiXMark as ResetIcon, HiMagnifyingGlass as SearchIcon } from 'react-icons/hi2';
import { Button, Input } from '@knack/asterisk-react';

import { useViewSearchParams, type ViewWithSearchParams } from '@/hooks/useViewSearchParams';
import { useViewContext } from '@/components/views/ViewContext';

export function ViewSearchInput() {
  const { view } = useViewContext<ViewWithSearchParams>();
  const { searchValueFromParams, setViewParam } = useViewSearchParams(view);
  const [t] = useTranslation();
  const [inputValue, setInputValue] = useState(searchValueFromParams || '');

  const handleResetSearchInput = () => {
    setViewParam({ search: null });
    setInputValue('');
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setViewParam({ search: inputValue, page: null });
      }}
    >
      <Input.Container>
        <Input
          aria-label={t('components.views.table.table_search_input')}
          value={inputValue}
          placeholder={t('actions.search')}
          className="h-8 w-full sm:min-w-80"
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Input.Icon>
          {inputValue || searchValueFromParams ? (
            <Button
              aria-label={t('components.views.table.table_search_reset_button')}
              intent="minimal"
              size="xs"
              onClick={handleResetSearchInput}
            >
              <ResetIcon size={14} className="shrink-0" />
            </Button>
          ) : (
            <Button
              intent="minimal"
              size="xs"
              aria-label={t('components.views.table.table_search_button')}
            >
              <SearchIcon size={14} className="shrink-0" />
            </Button>
          )}
        </Input.Icon>
      </Input.Container>
    </form>
  );
}
