import { type ViewRecordsResponse } from '@/hooks/api/queries/useViewMultipleRecordsQuery';

export const tableDemoData: ViewRecordsResponse = {
  location_fail: false,
  total_pages: 40,
  current_page: 1,
  total_records: 1000,
  records: [
    {
      id: '66b23119ffa83450ac71a508',
      field_61: '12/01/2022',
      field_61_raw: {
        date: '12/01/2022',
        date_formatted: '12/01/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1669852800000,
        iso_timestamp: '2022-12-01T00:00:00.000Z',
        timestamp: '12/01/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'CMC Regulatory Affairs',
      field_55_raw: 'CMC Regulatory Affairs',
      field_60: 'False',
      field_60_raw: false,
      field_22: 'Lyndsay Acarson',
      field_22_raw: 'Lyndsay Acarson'
    },
    {
      id: '66b23119ffa83100ac71a508',
      field_61: '12/01/2022',
      field_61_raw: {
        date: '12/01/2022',
        date_formatted: '12/01/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1669852800000,
        iso_timestamp: '2022-12-01T00:00:00.000Z',
        timestamp: '12/01/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'CMC Regulatory Affairs',
      field_55_raw: 'CMC Regulatory Affairs',
      field_60: 'False',
      field_60_raw: false,
      field_22: 'Lyndsay Acarson',
      field_22_raw: 'Lyndsay Acarson'
    },
    {
      id: '66b23118ffa83100ac71a341',
      field_61: '12/02/2022',
      field_61_raw: {
        date: '12/02/2022',
        date_formatted: '12/02/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1669939200000,
        iso_timestamp: '2022-12-02T00:00:00.000Z',
        timestamp: '12/02/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'Therapists',
      field_55_raw: 'Therapists',
      field_60: 'False',
      field_60_raw: false,
      field_22: 'Bird Tungay',
      field_22_raw: 'Bird Tungay'
    },
    {
      id: '66b2311effa83100ac71aa5d',
      field_61: '12/02/2022',
      field_61_raw: {
        date: '12/02/2022',
        date_formatted: '12/02/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1669939200000,
        iso_timestamp: '2022-12-02T00:00:00.000Z',
        timestamp: '12/02/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'cDNA',
      field_55_raw: 'cDNA',
      field_60: 'True',
      field_60_raw: true,
      field_22: 'Binnie Winstone',
      field_22_raw: 'Binnie Winstone'
    },
    {
      id: '66b23122ffa83100ac71b15b',
      field_61: '12/02/2022',
      field_61_raw: {
        date: '12/02/2022',
        date_formatted: '12/02/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1669939200000,
        iso_timestamp: '2022-12-02T00:00:00.000Z',
        timestamp: '12/02/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'MVNE',
      field_55_raw: 'MVNE',
      field_60: 'True',
      field_60_raw: true,
      field_22: 'Maude Tolley',
      field_22_raw: 'Maude Tolley'
    },
    {
      id: '66b2311fffa83100ac71ac06',
      field_61: '12/03/2022',
      field_61_raw: {
        date: '12/03/2022',
        date_formatted: '12/03/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670025600000,
        iso_timestamp: '2022-12-03T00:00:00.000Z',
        timestamp: '12/03/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'SharePoint',
      field_55_raw: 'SharePoint',
      field_60: 'True',
      field_60_raw: true,
      field_22: 'Nichols Neesham',
      field_22_raw: 'Nichols Neesham'
    },
    {
      id: '66b23120ffa83100ac71ae27',
      field_61: '12/03/2022',
      field_61_raw: {
        date: '12/03/2022',
        date_formatted: '12/03/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670025600000,
        iso_timestamp: '2022-12-03T00:00:00.000Z',
        timestamp: '12/03/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'WordPerfect',
      field_55_raw: 'WordPerfect',
      field_60: 'False',
      field_60_raw: false,
      field_22: 'Chrotoem Cater',
      field_22_raw: 'Chrotoem Cater'
    },
    {
      id: '66b23117ffa83100ac71a14d',
      field_61: '12/04/2022',
      field_61_raw: {
        date: '12/04/2022',
        date_formatted: '12/04/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670112000000,
        iso_timestamp: '2022-12-04T00:00:00.000Z',
        timestamp: '12/04/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'CV',
      field_55_raw: 'CV',
      field_60: 'False',
      field_60_raw: false,
      field_22: 'Byrann Erangy',
      field_22_raw: 'Byrann Erangy'
    },
    {
      id: '66b2311affa83100ac71a675',
      field_61: '12/04/2022',
      field_61_raw: {
        date: '12/04/2022',
        date_formatted: '12/04/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670112000000,
        iso_timestamp: '2022-12-04T00:00:00.000Z',
        timestamp: '12/04/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'LDRA',
      field_55_raw: 'LDRA',
      field_60: 'True',
      field_60_raw: true,
      field_22: 'Coraline Blaxall',
      field_22_raw: 'Coraline Blaxall'
    },
    {
      id: '66b2311affa83100ac71a6e3',
      field_61: '12/04/2022',
      field_61_raw: {
        date: '12/04/2022',
        date_formatted: '12/04/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670112000000,
        iso_timestamp: '2022-12-04T00:00:00.000Z',
        timestamp: '12/04/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'CFDs',
      field_55_raw: 'CFDs',
      field_60: 'False',
      field_60_raw: false,
      field_22: 'Tammie Mum',
      field_22_raw: 'Tammie Mum'
    },
    {
      id: '66b23121ffa83100ac71b093',
      field_61: '12/04/2022',
      field_61_raw: {
        date: '12/04/2022',
        date_formatted: '12/04/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670112000000,
        iso_timestamp: '2022-12-04T00:00:00.000Z',
        timestamp: '12/04/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'Judo',
      field_55_raw: 'Judo',
      field_60: 'True',
      field_60_raw: true,
      field_22: 'Tanner Schwant',
      field_22_raw: 'Tanner Schwant'
    },
    {
      id: '66b23118ffa83100ac71a24c',
      field_61: '12/05/2022',
      field_61_raw: {
        date: '12/05/2022',
        date_formatted: '12/05/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670198400000,
        iso_timestamp: '2022-12-05T00:00:00.000Z',
        timestamp: '12/05/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'Capital IQ',
      field_55_raw: 'Capital IQ',
      field_60: 'True',
      field_60_raw: true,
      field_22: 'Jocelyn Harwick',
      field_22_raw: 'Jocelyn Harwick'
    },
    {
      id: '66b23120ffa83100ac71ad96',
      field_61: '12/05/2022',
      field_61_raw: {
        date: '12/05/2022',
        date_formatted: '12/05/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670198400000,
        iso_timestamp: '2022-12-05T00:00:00.000Z',
        timestamp: '12/05/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'IC',
      field_55_raw: 'IC',
      field_60: 'True',
      field_60_raw: true,
      field_22: 'Graig Scoggans',
      field_22_raw: 'Graig Scoggans'
    },
    {
      id: '66b23117ffa83100ac71a157',
      field_61: '12/06/2022',
      field_61_raw: {
        date: '12/06/2022',
        date_formatted: '12/06/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670284800000,
        iso_timestamp: '2022-12-06T00:00:00.000Z',
        timestamp: '12/06/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'Audio Editing',
      field_55_raw: 'Audio Editing',
      field_60: 'True',
      field_60_raw: true,
      field_22: 'Kimmi Gleadle',
      field_22_raw: 'Kimmi Gleadle'
    },
    {
      id: '66b23118ffa83100ac71a1e3',
      field_61: '12/07/2022',
      field_61_raw: {
        date: '12/07/2022',
        date_formatted: '12/07/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670371200000,
        iso_timestamp: '2022-12-07T00:00:00.000Z',
        timestamp: '12/07/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'UCC filings',
      field_55_raw: 'UCC filings',
      field_60: 'False',
      field_60_raw: false,
      field_22: 'Brear Lammert',
      field_22_raw: 'Brear Lammert'
    },
    {
      id: '66b2311dffa83100ac71a8e6',
      field_61: '12/07/2022',
      field_61_raw: {
        date: '12/07/2022',
        date_formatted: '12/07/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670371200000,
        iso_timestamp: '2022-12-07T00:00:00.000Z',
        timestamp: '12/07/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'SRM',
      field_55_raw: 'SRM',
      field_60: 'False',
      field_60_raw: false,
      field_22: 'Jaquelyn Dungay',
      field_22_raw: 'Jaquelyn Dungay'
    },
    {
      id: '66b23122ffa83100ac71b156',
      field_61: '12/07/2022',
      field_61_raw: {
        date: '12/07/2022',
        date_formatted: '12/07/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670371200000,
        iso_timestamp: '2022-12-07T00:00:00.000Z',
        timestamp: '12/07/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'LWUIT',
      field_55_raw: 'LWUIT',
      field_60: 'True',
      field_60_raw: true,
      field_22: 'Alisa Nuttey',
      field_22_raw: 'Alisa Nuttey'
    },
    {
      id: '66b2311fffa83100ac71ac60',
      field_61: '12/08/2022',
      field_61_raw: {
        date: '12/08/2022',
        date_formatted: '12/08/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670457600000,
        iso_timestamp: '2022-12-08T00:00:00.000Z',
        timestamp: '12/08/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'xPC Target',
      field_55_raw: 'xPC Target',
      field_60: 'False',
      field_60_raw: false,
      field_22: 'Marj Laste',
      field_22_raw: 'Marj Laste'
    },
    {
      id: '66b2311fffa83100ac71ac65',
      field_61: '12/08/2022',
      field_61_raw: {
        date: '12/08/2022',
        date_formatted: '12/08/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670457600000,
        iso_timestamp: '2022-12-08T00:00:00.000Z',
        timestamp: '12/08/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'Kinesiology',
      field_55_raw: 'Kinesiology',
      field_60: 'False',
      field_60_raw: false,
      field_22: 'Cos Maseres',
      field_22_raw: 'Cos Maseres'
    },
    {
      id: '66b23117ffa83100ac719fb8',
      field_61: '12/09/2022',
      field_61_raw: {
        date: '12/09/2022',
        date_formatted: '12/09/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670544000000,
        iso_timestamp: '2022-12-09T00:00:00.000Z',
        timestamp: '12/09/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'DNA quantification',
      field_55_raw: 'DNA quantification',
      field_60: 'True',
      field_60_raw: true,
      field_22: 'Sampson Abrahart',
      field_22_raw: 'Sampson Abrahart'
    },
    {
      id: '66b2311effa83100ac71ab48',
      field_61: '12/09/2022',
      field_61_raw: {
        date: '12/09/2022',
        date_formatted: '12/09/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670544000000,
        iso_timestamp: '2022-12-09T00:00:00.000Z',
        timestamp: '12/09/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'Waterfront',
      field_55_raw: 'Waterfront',
      field_60: 'False',
      field_60_raw: false,
      field_22: 'Nevin Rainforth',
      field_22_raw: 'Nevin Rainforth'
    },
    {
      id: '66b23120ffa83100ac71aec2',
      field_61: '12/09/2022',
      field_61_raw: {
        date: '12/09/2022',
        date_formatted: '12/09/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670544000000,
        iso_timestamp: '2022-12-09T00:00:00.000Z',
        timestamp: '12/09/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'Ion Chromatography',
      field_55_raw: 'Ion Chromatography',
      field_60: 'False',
      field_60_raw: false,
      field_22: 'Alethea Thorington',
      field_22_raw: 'Alethea Thorington'
    },
    {
      id: '66b2311dffa83100ac71a9ea',
      field_61: '12/10/2022',
      field_61_raw: {
        date: '12/10/2022',
        date_formatted: '12/10/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670630400000,
        iso_timestamp: '2022-12-10T00:00:00.000Z',
        timestamp: '12/10/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'Green Belt',
      field_55_raw: 'Green Belt',
      field_60: 'False',
      field_60_raw: false,
      field_22: 'Gusty Fetteplace',
      field_22_raw: 'Gusty Fetteplace'
    },
    {
      id: '66b23121ffa83100ac71af58',
      field_61: '12/10/2022',
      field_61_raw: {
        date: '12/10/2022',
        date_formatted: '12/10/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670630400000,
        iso_timestamp: '2022-12-10T00:00:00.000Z',
        timestamp: '12/10/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'UV disinfection',
      field_55_raw: 'UV disinfection',
      field_60: 'True',
      field_60_raw: true,
      field_22: 'Nanci Bitchener',
      field_22_raw: 'Nanci Bitchener'
    },
    {
      id: '66b23116ffa83100ac719e73',
      field_61: '12/11/2022',
      field_61_raw: {
        date: '12/11/2022',
        date_formatted: '12/11/2022',
        hours: '12',
        minutes: '00',
        am_pm: 'AM',
        unix_timestamp: 1670716800000,
        iso_timestamp: '2022-12-11T00:00:00.000Z',
        timestamp: '12/11/2022 12:00 am',
        time: 0,
        all_day: true
      },
      field_55: 'PyMEL',
      field_55_raw: 'PyMEL',
      field_60: 'True',
      field_60_raw: true,
      field_22: 'Ava Szymanowski',
      field_22_raw: 'Ava Szymanowski'
    }
  ]
};
