import { useLayoutEffect, useState, type RefObject } from 'react';

type UseContainerQueryOptions = {
  minWidth?: number;
  maxWidth?: number;
  defaultValue?: boolean;
};

export function useContainerQuery(
  containerRef: RefObject<HTMLDivElement>,
  { minWidth, maxWidth, defaultValue = false }: UseContainerQueryOptions = {}
): boolean {
  const [matches, setMatches] = useState<boolean>(defaultValue);

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (!container) return undefined;

    const handleChange = () => {
      const containerWidth = container.offsetWidth;

      const isMatch =
        (minWidth === undefined || containerWidth >= minWidth) &&
        (maxWidth === undefined || containerWidth <= maxWidth);

      setMatches(isMatch);
    };

    handleChange();

    const resizeObserver = new ResizeObserver(handleChange);
    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, minWidth, maxWidth]);

  return matches;
}
