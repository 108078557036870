import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  HiMiniArrowTopRightOnSquare as ExternalIcon,
  HiMiniLightBulb as IdeaIcon
} from 'react-icons/hi2';
import { useBlocker } from 'react-router-dom';
import { Banner, Tabs, ThemeProviderContext } from '@knack/asterisk-react';

import { getApplicationBasePath } from '@/utils/application';
import { LoginViewRender } from '@/components/views/auth/login/LoginViewRender';
import { FormViewRender } from '@/components/views/form/FormViewRender';
import { ListViewRender } from '@/components/views/list/ListViewRender';
import { RichTextViewRender } from '@/components/views/rich-text/RichTextViewRender';
import { TableViewRender } from '@/components/views/table/TableViewRender';
import { formViewDemoView } from '@/pages/theme/demo/formViewDemo';
import { listViewDemoView } from '@/pages/theme/demo/listViewDemo';
import { loginViewDemoView } from '@/pages/theme/demo/loginViewDemo';
import { richTextDemoView } from '@/pages/theme/demo/richTextViewDemo';
import { tableDemoData } from '@/pages/theme/demo/tableDemoData';
import { tableViewDemoView } from '@/pages/theme/demo/tableViewDemo';
import { useMessagingContext } from '@/context/MessagingContext';
import { Charts } from './demo/charts/Charts';
import { ColorsDemo } from './demo/colors/colorsDemo';
import { listDemoData } from './demo/listDemoData';
import { DemoView } from './demo/utils';

export function ThemePage() {
  const [t] = useTranslation();
  const { messageFromBuilder, sendMessageToBuilder } = useMessagingContext();
  const { setDarkMode } = useContext(ThemeProviderContext);
  const items = [
    {
      children: <span>{t('components.page_editor.demo_page.tabs.colors')}</span>,
      value: 'colors'
    },
    {
      children: <span>{t('components.page_editor.demo_page.tabs.list_details')}</span>,
      value: 'list'
    },
    {
      children: <span>{t('components.page_editor.demo_page.tabs.form')}</span>,
      value: 'form'
    },
    {
      children: <span>{t('components.page_editor.demo_page.tabs.table')}</span>,
      value: 'table'
    },
    {
      children: <span>{t('components.page_editor.demo_page.tabs.content')}</span>,
      value: 'content'
    },
    {
      children: <span>{t('components.page_editor.demo_page.tabs.login')}</span>,
      value: 'login'
    },
    {
      children: <span>{t('components.page_editor.demo_page.tabs.charts')}</span>,
      value: 'charts'
    }
  ];

  // Block internal navigation since the app is running inside the builder
  useBlocker(true);

  // Tell the builder app that this page is ready to receive messages
  sendMessageToBuilder({ action: 'request-page-sync' });

  useEffect(() => {
    if (!messageFromBuilder) {
      return;
    }

    if (messageFromBuilder.action === 'set-theme-preview-mode') {
      // While the Live App is in preview mode inside the builder, we must avoid updating
      // the themeMode key in the localStorage to prevent affecting other active theme
      if (messageFromBuilder.mode === 'system') {
        const media = window.matchMedia('(prefers-color-scheme: dark)');
        const isSystemDark = media.matches;
        setDarkMode(isSystemDark);
      } else {
        setDarkMode(messageFromBuilder.mode === 'dark');
      }
    }
  }, [messageFromBuilder, setDarkMode]);

  return (
    <div className="mb-20">
      <Banner className="mb-6" intent="success" icon={IdeaIcon}>
        <Trans i18nKey="components.page_editor.demo_page.description">
          <a
            href={getApplicationBasePath()}
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Knack
          </a>
          <ExternalIcon className="ml-0.5 inline-block" />
        </Trans>
      </Banner>
      <Tabs defaultValue="colors">
        <Tabs.List intent="page" items={items} />
        <Tabs.Content intent="page" value="colors">
          <ColorsDemo />
        </Tabs.Content>
        <Tabs.Content intent="page" value="list">
          <DemoView view={listViewDemoView}>
            <ListViewRender sourceData={listDemoData} />
          </DemoView>
        </Tabs.Content>
        <Tabs.Content intent="page" value="form">
          <DemoView view={formViewDemoView}>
            <FormViewRender />
          </DemoView>
        </Tabs.Content>
        <Tabs.Content intent="page" value="table">
          <DemoView view={tableViewDemoView}>
            <TableViewRender sourceData={tableDemoData} />
          </DemoView>
        </Tabs.Content>
        <Tabs.Content intent="page" value="content">
          <DemoView view={richTextDemoView}>
            <RichTextViewRender />
          </DemoView>
        </Tabs.Content>
        <Tabs.Content intent="page" value="login">
          <DemoView view={loginViewDemoView}>
            <LoginViewRender />
          </DemoView>
        </Tabs.Content>
        <Tabs.Content intent="page" value="charts">
          <Charts />
        </Tabs.Content>
      </Tabs>
    </div>
  );
}
