import { type MenuView } from '@/types/schema/views/MenuView';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { MenuLinksSortable } from './MenuLinksSortable';

interface MenuLinksSortableWrapperProps {
  view: MenuView;
  children: React.ReactNode;
}

export function MenuLinksSortableWrapper({ view, children }: MenuLinksSortableWrapperProps) {
  const { page } = usePageEditorContext();

  // If the menu is a parent linked menu, it cannot be edited since the view doesn't actually belong to the current page
  if (
    page.parentLinkedMenuViews &&
    page.parentLinkedMenuViews.some((parentLinkedMenuView) => parentLinkedMenuView.key === view.key)
  ) {
    return children;
  }

  return <MenuLinksSortable view={view} />;
}
