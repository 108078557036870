import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type Session } from '@/types/session';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';

interface GoogleSsoSigninParams {
  pageKey: string;
  viewKey: string;
  googleCredentialResponse: google.accounts.id.CredentialResponse;
}

export async function googleSsoSignin({
  pageKey,
  viewKey,
  googleCredentialResponse
}: GoogleSsoSigninParams) {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  const { data } = await axios.post<{ session: Session }>(
    `/v1/live-app/${accountSlug}/${appSlug}/sso/google`,
    {
      loginPageKey: pageKey,
      loginViewKey: viewKey,
      ...googleCredentialResponse
    },
    { withCredentials: true }
  );

  return data.session;
}

export function useGoogleSsoSigninMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: googleSsoSignin,
    onSuccess: (session) => {
      if (session.user.accountStatus === 'active') {
        queryClient.setQueryData([queryKeys.auth.session], session);
        void queryClient.invalidateQueries({ queryKey: [queryKeys.entryPages] });
      }
    }
  });
}
