import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Banner, Spinner } from '@knack/asterisk-react';

import { useGoogleSsoSigninMutation } from '@/hooks/api/mutations/useGoogleSsoSigninMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { GsiScriptState, useLoadGsiScript } from '@/hooks/useLoadGsiScript';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { useAuthFlow } from '@/pages/page/AuthFlowContext';
import { usePageContext } from '@/context/PageContext';
import { PendingApprovalBanner } from './PendingApprovalBanner';

interface GoogleButtonProps {
  onSubmit?: () => void;
  shouldResetErrors?: boolean;
}

export function GoogleButton({ onSubmit, shouldResetErrors }: GoogleButtonProps) {
  const [t] = useTranslation();

  const { data: application } = useApplicationQuery();
  const { mutate: googleSsoSignin } = useGoogleSsoSigninMutation();
  const { activePage } = usePageContext();
  const { loginViewKey } = useAuthFlow();

  const googleAuthContainerRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState('');
  const [isAccountPendingApproval, setIsAccountPendingApproval] = useState(false);

  useEffect(() => {
    if (shouldResetErrors) {
      setError('');
      setIsAccountPendingApproval(false);
    }
  }, [shouldResetErrors]);

  function handleGoogle(credentialResponse: google.accounts.id.CredentialResponse) {
    setError('');

    googleSsoSignin(
      {
        pageKey: activePage?.key ?? '',
        viewKey: loginViewKey ?? '',
        googleCredentialResponse: credentialResponse
      },
      {
        onSuccess: (session) => {
          // If the account was created but is pending approval, we don't log the user in and show a banner instead
          if (session.user.accountStatus === 'pending approval') {
            setIsAccountPendingApproval(true);
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (err: any) => {
          let errorMessage = t('errors.generic_error');

          if (Array.isArray(err.response?.data?.errors)) {
            const errorKey = err.response.data.errors[0]?.message;

            if (errorKey === 'account_pending_approval') {
              setIsAccountPendingApproval(true);
              return;
            }

            if (errorKey === 'account_inactive') {
              errorMessage = t('components.views.login.errors.account_inactive');
            }

            if (errorKey === 'forbidden') {
              errorMessage = t('components.auth.forbidden');
            }
          }

          setError(errorMessage);
        },
        onSettled: () => {
          if (onSubmit) {
            onSubmit();
          }
        }
      }
    );
  }

  const { scriptState } = useLoadGsiScript({
    onScriptLoadSuccess: () => {
      if (!window?.google || !googleAuthContainerRef.current) return;

      window.google.accounts?.id.initialize({
        client_id: application?.settings.sso?.google?.client_id ?? import.meta.env.PUBLIC_GOOGLE_ID,
        client_secret: application?.settings.sso?.google?.client_secret ?? '',
        callback: handleGoogle
      });
      window.google.accounts?.id.renderButton(googleAuthContainerRef.current, {
        size: 'large',
        text: 'continue_with',
        theme: 'outline',
        type: 'standard',
        width: 275 as unknown as string
      });
    },
    onScriptLoadError: () => {
      // eslint-disable-next-line no-console
      console.error('Failed to load Google SSO Script');
    }
  });

  return (
    <div
      data-testid="auth-login-google-button"
      className="flex w-full flex-col items-center justify-center"
    >
      {scriptState === GsiScriptState.Loading && <Spinner className="flex h-[40px]" />}
      <div
        ref={googleAuthContainerRef}
        className={cn({
          'flex h-[40px]': scriptState !== GsiScriptState.Loading,
          'pointer-events-none cursor-pointer': isInternalBuilderIframe()
        })}
      />
      {error && (
        <Banner intent="destructive" className="mt-4">
          <Banner.Message>{error}</Banner.Message>
        </Banner>
      )}
      {isAccountPendingApproval && <PendingApprovalBanner className="mt-4" />}
    </div>
  );
}
