import { createBrowserRouter, RouterProvider, type RouteObject } from 'react-router-dom';

import { App } from './App';
import { RouterErrorBoundary } from './components/errors/RouterErrorBoundary';
import { BuilderAppPageLayout } from './components/layout/BuilderAppPageLayout';
import { PageLayout } from './components/layout/PageLayout';
import { ResetPasswordWrapper } from './components/views/auth/reset/ResetPasswordWrapper';
import { Page } from './pages/page/Page';
import { ThemePage } from './pages/theme/ThemePage';
import { getApplicationBasePath } from './utils/application';
import { isInternalBuilderIframe } from './utils/iframe';

export const ROUTES = {
  ROOT: '/',
  RESET_PASSWORD: '/knack-password/reset/:token',
  CATCH_ALL: '*'
} as const;

export const BUILDER_APP_ROUTES = {
  ROOT: '/',
  THEME: '/theme',
  CATCH_ALL: '*'
} as const;

const routes: RouteObject[] = [
  {
    element: (
      <RouterErrorBoundary>
        <App />
      </RouterErrorBoundary>
    ),
    children: [
      {
        element: <PageLayout />,
        children: [
          {
            path: ROUTES.CATCH_ALL,
            element: <Page />
          },
          {
            path: ROUTES.RESET_PASSWORD,
            element: <ResetPasswordWrapper />
          }
        ]
      }
    ]
  }
];

const builderAppRoutes: RouteObject[] = [
  {
    element: (
      <RouterErrorBoundary>
        <App />
      </RouterErrorBoundary>
    ),
    children: [
      {
        element: <BuilderAppPageLayout />,
        children: [
          {
            path: BUILDER_APP_ROUTES.THEME,
            element: <ThemePage />
          },
          {
            path: BUILDER_APP_ROUTES.CATCH_ALL,
            element: <Page />
          }
        ]
      }
    ]
  }
];

const router = createBrowserRouter(isInternalBuilderIframe() ? builderAppRoutes : routes, {
  basename: getApplicationBasePath()
});

export function Router() {
  return <RouterProvider router={router} />;
}
