import { useTranslation } from 'react-i18next';
import { Badge, Skeleton } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';
import { useViewContext } from './ViewContext';

export function FallBackView() {
  const [t] = useTranslation();
  const { view } = useViewContext();
  const { theme } = useThemingContext();

  return (
    <div
      className={cn('flex h-full flex-col', {
        'rounded-lg': theme.appearance.corners === 'rounded'
      })}
    >
      <div className="flex w-full flex-wrap justify-between gap-2">
        <h2 className="text-base">{view.title}</h2>
        <Badge intent="neutral" className="whitespace-nowrap">
          {t('keywords.coming_soon')}
        </Badge>
      </div>
      <div className="relative h-full">
        <div className="flex w-full flex-col items-center justify-center blur-sm">
          <Skeleton className="my-2 w-[90%] p-3" />
          <Skeleton className="my-2 w-[80%] p-3" />
          <Skeleton className="my-2 w-[85%] p-3" />
          <Skeleton className="my-2 w-[60%] p-3" />
          <Skeleton className="my-2 w-[80%] p-3" />
        </div>
        <div className="absolute top-0 flex size-full flex-col items-center justify-center text-center">
          <p
            className={cn('border border-dashed border-brand bg-base p-2', {
              'rounded-lg': theme.appearance.corners === 'rounded'
            })}
          >
            {t('components.views.we_are_working')}
          </p>
        </div>
      </div>
    </div>
  );
}
