import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiEllipsisHorizontal as EllipsisHorizontalIcon,
  HiTrash as TrashIcon
} from 'react-icons/hi2';
import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';
import { DropdownMenu } from '@knack/asterisk-react';

import { type LiveAppView, type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { cn } from '@/utils/tailwind';
import { View } from '@/components/views/View';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { useSectionEditorContext } from '@/pages/page/page-editor/SectionEditorContext';
import { AddNewButton } from './AddNewButton';
import { DragInsertIndicator } from './page-editor-sortable/DragInsertIndicator';

interface AddViewButtonProps {
  buttonPosition: 'top' | 'bottom';
  originViewKey: LiveAppViewKey;
  columnId: string;
  sectionId: string;
}

interface ViewEditorProps {
  view: LiveAppView;
  columnId: string;
  draggingOverInsertPosition?: 'before' | 'after' | null;
  isDragOverlay?: boolean;
}

function AddViewButton({ originViewKey, buttonPosition, columnId, sectionId }: AddViewButtonProps) {
  const { startAddView } = usePageEditorContext();

  const onAddView = (event: React.MouseEvent) => {
    event.stopPropagation();

    startAddView({
      columnId,
      sectionId,
      originViewKey,
      position: buttonPosition === 'top' ? 'above' : 'below'
    });
  };

  return <AddNewButton onClick={onAddView} position={buttonPosition} tooltip="Add New Element" />;
}

export function ViewEditor({
  view,
  columnId,
  draggingOverInsertPosition,
  isDragOverlay
}: ViewEditorProps) {
  const [t] = useTranslation();

  const { selectItem, selectedItem, updatePage, isDraggingActive } = usePageEditorContext();
  const { section, setViewHover, currentHoveredViewKey, currentHoveredViewInput } =
    useSectionEditorContext();
  const { listeners, setActivatorNodeRef, isDragging } = useSortable({ id: view.key });

  const [isOptionsDropdownOpen, setIsOptionsDropdownOpen] = useState(false);

  const hasActiveChildFormView =
    selectedItem?.type === 'view' &&
    selectedItem.view.type === 'form' &&
    selectedItem.view.parent === view.key;

  const isViewSelected = selectedItem?.type === 'view' && selectedItem.view.key === view.key;
  const isViewHovered = currentHoveredViewKey === view.key && !currentHoveredViewInput;
  const isViewActiveState =
    isViewSelected ||
    isViewHovered ||
    isOptionsDropdownOpen ||
    hasActiveChildFormView ||
    isDragOverlay;

  function selectView() {
    selectItem({ type: 'view', viewKey: view.key });
  }

  const onViewDelete = (event: React.MouseEvent) => {
    event.stopPropagation();

    updatePage({
      type: 'view',
      origin: 'live-app',
      action: 'delete',
      viewKey: view.key,
      sectionId: section.id
    });
  };

  function onViewClick(event: React.MouseEvent) {
    if (event.target !== event.currentTarget) {
      return;
    }

    event.stopPropagation();
    selectView();
  }

  function onViewKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    event.preventDefault();

    if (event.key === 'Enter' || event.key === 'Space') {
      selectView();
    }
  }

  // Reset view hover state when component unmounts
  useEffect(
    () => () => {
      setViewHover(null);
    },
    [setViewHover]
  );

  return (
    <>
      <DragInsertIndicator isVisible={draggingOverInsertPosition === 'before'} className="mb-4" />

      <section
        role="button"
        tabIndex={0}
        onClick={onViewClick}
        onKeyDown={onViewKeyDown}
        {...(!isDraggingActive && {
          onMouseEnter: () => setViewHover(view.key),
          onMouseLeave: () => setViewHover(null)
        })}
        className={cn('relative rounded-lg border border-transparent', {
          'border-blue-500': isViewActiveState,
          'after:absolute after:inset-0 after:bg-blue-500/10':
            (isViewHovered || isDragOverlay) && !isViewSelected && !hasActiveChildFormView,
          'opacity-20': isDragging,
          'p-3 pt-8': view.type === 'menu'
        })}
      >
        <div className={cn({ 'pointer-events-none': !isViewSelected })}>
          <View view={view} />
        </div>

        {isViewHovered && !isDraggingActive && (
          <>
            <AddViewButton
              buttonPosition="top"
              originViewKey={view.key}
              columnId={columnId}
              sectionId={section.id}
            />
            <AddViewButton
              buttonPosition="bottom"
              originViewKey={view.key}
              columnId={columnId}
              sectionId={section.id}
            />
          </>
        )}

        {isViewActiveState && (
          <div className="border-1 absolute -left-[1px] top-0 z-10 rounded-br-md rounded-tl-lg border-blue-500 bg-blue-500">
            <div className="flex h-6 items-center px-1 text-white">
              <button
                type="button"
                className="mr-1 inline-flex size-5 cursor-move items-center justify-center rounded-md text-white hover:bg-white/25"
                ref={setActivatorNodeRef}
                {...listeners}
              >
                <DragIcon size={16} />
              </button>
              <span className="mr-2">{t(`components.view_names.${view.type}`)}</span>
              <DropdownMenu open={isOptionsDropdownOpen} onOpenChange={setIsOptionsDropdownOpen}>
                <DropdownMenu.Trigger asChild>
                  <button
                    type="button"
                    aria-label={t('components.page_editor.view_options')}
                    className="inline-flex size-5 items-center justify-center rounded-md text-white hover:bg-white/25 focus:outline-0"
                  >
                    <EllipsisHorizontalIcon size={14} />
                  </button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content className="min-w-44 p-1" align="start">
                  <DropdownMenu.Item onClick={onViewDelete} className="p-1">
                    <TrashIcon size={14} className="mr-1" />
                    {t('actions.delete')}
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu>
            </div>
          </div>
        )}
      </section>

      <DragInsertIndicator isVisible={draggingOverInsertPosition === 'after'} className="mt-4" />
    </>
  );
}
