import { type ReactNode } from 'react';
import { HiMagnifyingGlass as SearchIcon } from 'react-icons/hi2';

export function MapDetailsEmptyState({ children }: { children: ReactNode }) {
  return (
    <div className="flex size-full items-center justify-center">
      <div className="flex flex-col items-center gap-4">
        <SearchIcon size={34} fill="#A9A2A8" />
        <div className="text-center text-sm font-semibold text-default">{children}</div>
      </div>
    </div>
  );
}
