import type { ComboboxOption } from '@knack/asterisk-react';

import { type MapView } from '@/types/schema/views/MapView';
import { ViewFiltersPopover } from '@/components/views/view-options/ViewFiltersPopover';

export function MapViewFiltersPopover({ view }: { view: MapView }) {
  const columnOptions: ComboboxOption[] = view.columns.flatMap((column) =>
    column.groups.flatMap((group) =>
      group.columns.flatMap((cols) =>
        cols.flatMap((col) => {
          if (col.type === 'field') {
            return [
              {
                key: col.key,
                label: col.name
              }
            ];
          }
          return [];
        })
      )
    )
  );

  return <ViewFiltersPopover view={view} columnOptions={columnOptions} />;
}
