import { type ReactNode } from 'react';
import { Card } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';

export function ColorGroup({
  color,
  name,
  description,
  children
}: {
  color: string;
  name: string;
  description: string;
  children: ReactNode;
}) {
  const { theme } = useThemingContext();
  return (
    <Card className="sm:p-6 md:p-6">
      <div className="flex items-center gap-4">
        <div className="mb-1 flex shrink-0 items-center gap-2">
          <div
            className={cn('size-4 shrink-0 border border-subtle', {
              rounded: theme.appearance.corners === 'rounded'
            })}
            style={{ backgroundColor: `rgb(var(--${color}))` }}
          />
          <h2 className="shrink-0 text-md">{name}</h2>
        </div>
      </div>
      <div className="ml-6">
        <p className="mb-6 text-xs text-subtle">{description}</p>
        {children}
      </div>
    </Card>
  );
}

export function ExampleGroup({
  group,
  isSingleColumn,
  children
}: {
  group: string;
  isSingleColumn?: boolean;
  children: ReactNode;
}) {
  return (
    <>
      <h3 className="mb-1 flex text-md text-subtle">{group}</h3>
      <div
        className={cn('mb-5 flex gap-2', {
          'flex-col': isSingleColumn
        })}
      >
        {children}
      </div>
    </>
  );
}

export function Grid({ title, children }: { title: string; children: ReactNode }) {
  return (
    <>
      <h1 className="mb-4 text-xl">{title}</h1>
      <div
        className="mb-14 grid gap-4"
        style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(450px, 1fr))' }}
      >
        {children}
      </div>
    </>
  );
}
