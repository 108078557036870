import type { ReactNode } from 'react';

import { cn } from '@/utils/tailwind';

export type MapContainerProps = {
  isSmallVersion?: boolean;
  mapHeight: number;
};

export function MapContainer({
  children,
  isSmallVersion = false,
  mapHeight
}: MapContainerProps & { children: ReactNode }) {
  return (
    <div
      className={cn('flex w-full flex-1 items-center justify-center', {
        'min-w-72': !isSmallVersion
      })}
      style={{ height: mapHeight }}
    >
      {children}
    </div>
  );
}
