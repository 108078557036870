import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from '@knack/asterisk-react';

import { type FormViewAddressInput } from '@/types/schema/views/form/Address';
import { cn } from '@/utils/tailwind';
import { AddressGoogleAutocompleteInput } from '@/components/AddressGoogleAutocompleteInput';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { formatGoogleAutocomplete } from '@/components/views/form/utils';
import { useViewContext } from '@/components/views/ViewContext';
import { LatituteLongitudeInput } from './LatitudeLongitudeInput';
import { type GoogleMapsPlace } from './types';

export interface Address {
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  latitude: string;
  longitude: string;
}

export function AddressInput({ input }: { input: FormViewAddressInput }) {
  const [t] = useTranslation();
  const { view } = useViewContext();
  const {
    setValue: setFormValue,
    formState: { errors }
  } = useFormContext();

  const inputFormat = input.format;
  const addressInputSections =
    inputFormat?.input === 'address'
      ? [
          { key: 'street', label: t('components.views.form.address_input.street_address') },
          { key: 'street2', label: t('components.views.form.address_input.street_address_2') },
          { key: 'city', label: t('components.views.form.address_input.city') },
          {
            key: 'state',
            label:
              inputFormat.format === 'US'
                ? t('components.views.form.address_input.state')
                : t('components.views.form.address_input.province')
          },
          {
            key: 'zip',
            label:
              inputFormat.format === 'US'
                ? t('components.views.form.address_input.zip')
                : t('components.views.form.address_input.postal_code')
          },
          {
            key: 'country',
            label: t('components.views.form.address_input.country')
          }
        ]
      : [
          { key: 'latitude', label: t('components.views.form.address_input.latitude') },
          { key: 'longitude', label: t('components.views.form.address_input.longitude') }
        ];

  const onPlaceSelected = (place: GoogleMapsPlace) => {
    if (!place) return;

    const formattedAddress = formatGoogleAutocomplete(place);
    setFormValue(input.field.key, formattedAddress);
  };

  return (
    <>
      <div className="grid min-w-[200px] grid-cols-2 gap-2">
        {addressInputSections.map((section) => {
          const id = `${view.key}-${input.id}_${section.key}`;
          const isRequiredField =
            section.key === 'street' ||
            section.key === 'city' ||
            section.key === 'state' ||
            section.key === 'zip';
          const isSplitSection =
            section.key === 'state' ||
            section.key === 'zip' ||
            section.key === 'latitude' ||
            section.key === 'longitude';

          if (section.key === 'country' && inputFormat?.format !== 'international_country') {
            return null;
          }

          return (
            <div
              key={id}
              className={cn('col-span-2 w-full space-y-2', {
                'col-span-1': isSplitSection
              })}
            >
              <Label htmlFor={id} className="text-default">
                {section.label}
              </Label>

              <Controller
                name={`${input.field.key}.${section.key}`}
                render={({ field: { value: fieldValue, ref, ...rest } }) => {
                  // Avoid console errors when value is null
                  const addressFieldValue = fieldValue || '';

                  if (section.key === 'latitude' || section.key === 'longitude') {
                    return (
                      <LatituteLongitudeInput
                        inputFieldKey={`${input.field.key}.${section.key}`}
                        value={addressFieldValue}
                        type={section.key}
                      />
                    );
                  }
                  return (
                    <AddressGoogleAutocompleteInput
                      disabled={input.read_only}
                      id={id}
                      intent={
                        errors[input.field.key] && isRequiredField ? 'destructive' : 'default'
                      }
                      {...(section.key === 'street' &&
                        inputFormat?.enable_address_autocomplete && {
                          placeholder: t('components.views.form.address_input.placeholder')
                        })}
                      onPlaceSelected={onPlaceSelected}
                      types={['address']}
                      value={addressFieldValue}
                      {...rest}
                    />
                  );
                }}
              />
            </div>
          );
        })}
      </div>
      <FormErrorMessage errors={errors} name={input.field.key} />
    </>
  );
}
