import { type LiveAppTableResponse } from '@/types/schema/LiveAppTable';

export const signatureDemo: LiveAppTableResponse = {
  id: '665ddda7059a2f04c2b6a665',
  key: 'object_1',
  name: 'Signature Form',
  fields: [
    {
      type: 'signature',
      required: true,
      default: '',
      unique: false,
      user: false,
      conditional: false,
      rules: [],
      validation: [],
      _id: '665ddda7059a2f04c2b6a667',
      name: 'Column 1',
      key: 'field_1'
    }
  ]
};
