import { DateTime } from 'luxon';

import { type KnackField } from '@/types/schema/fields/KnackField';
import {
  type DateTimeCriteriaValue,
  type LinkCriteriaValue,
  type RuleCriteria,
  type RuleCriteriaValue
} from '@/types/schema/LiveAppView';
import { type MapRuleCriteria } from '@/types/schema/views/MapView';
import { getBooleanValue } from '@/components/views/form/inputs/boolean/helper';
import { defaultDateFormatMap } from '@/components/views/form/inputs/date-time/types';

function getDateCriteriaTypeFormatted(
  criteriaType: RuleCriteria['type'] = 'days'
): 'week' | 'day' | 'month' | 'year' {
  if (criteriaType === 'rolling months' || criteriaType === 'months') return 'month';
  if (criteriaType === 'rolling weeks' || criteriaType === 'weeks') return 'week';
  if (criteriaType === 'rolling years' || criteriaType === 'years') return 'year';

  return 'day';
}

function getFormFieldValue(fieldValue: RuleCriteriaValue, field: KnackField) {
  if (typeof fieldValue === 'boolean') {
    return fieldValue as boolean;
  }

  if (typeof fieldValue === 'number') {
    return fieldValue as number;
  }

  if (typeof fieldValue === 'string') {
    if (field.type === 'boolean') {
      return getBooleanValue(fieldValue, field.format);
    }

    return fieldValue.toLowerCase();
  }

  if (field.type === 'link') {
    return (fieldValue as LinkCriteriaValue).url.toLowerCase();
  }

  if (Array.isArray(fieldValue) && field.type === 'multiple_choice') {
    // When we have a Multiple choice field using a multi select, it is possible to receive an array of values
    const fieldValueArrayToLowerCase = (fieldValue as string[]).map((v: string) => v.toLowerCase());
    // When we compare an array of values, for operators like "is", "is not", that refer to a single value,
    // we must take the first value of the array to make the comparison and only when the array has a single value
    if (fieldValueArrayToLowerCase.length === 1) {
      return fieldValueArrayToLowerCase[0];
    }
    return fieldValueArrayToLowerCase;
  }

  if (Array.isArray(fieldValue) && field.type === 'connection') {
    if (fieldValue.length === 0) return [];
    return fieldValue[0];
  }

  return null;
}

export function isCriteriaMet(
  fieldValue: RuleCriteriaValue,
  field: KnackField,
  criteria: RuleCriteria
): boolean {
  const { operator, value: criteriaValue, type = 'days', range = 1 } = criteria;

  if (field.type === 'date_time') {
    const dateTimeCriteriaValue = criteriaValue as DateTimeCriteriaValue;
    const dateTimeFieldValue = fieldValue as DateTimeCriteriaValue;
    const dateFormat = field.format ? defaultDateFormatMap[field.format.date_format] : 'MM/dd/yyyy';

    const fieldValueDate = dateTimeFieldValue?.date
      ? DateTime.fromFormat(dateTimeFieldValue.date, dateFormat)
      : null;

    const criteriaValueDate = dateTimeCriteriaValue?.date
      ? DateTime.fromFormat(dateTimeCriteriaValue.date, 'MM/dd/yyyy')
      : null;

    const formattedDurationType = getDateCriteriaTypeFormatted(type);
    const hasHour = dateTimeCriteriaValue?.time !== '';

    switch (operator) {
      case 'is':
        return JSON.stringify(fieldValueDate) === JSON.stringify(criteriaValueDate);
      case 'is not':
        return JSON.stringify(fieldValueDate) !== JSON.stringify(criteriaValueDate);
      case 'is before':
        return !!fieldValueDate && !!criteriaValueDate && fieldValueDate < criteriaValueDate;
      case 'is after':
        return !!fieldValueDate && !!criteriaValueDate && fieldValueDate > criteriaValueDate;
      case 'is before current time':
        return !!fieldValueDate && fieldValueDate < DateTime.now();
      case 'is after current time':
        return !!fieldValueDate && fieldValueDate > DateTime.now();
      case 'is during the current':
        return !!fieldValueDate && fieldValueDate.hasSame(DateTime.now(), formattedDurationType);
      case 'is during the previous':
        if (type === 'weeks' || type === 'months' || type === 'years') {
          const startPreviousPeriod = DateTime.now()
            .startOf(formattedDurationType)
            .minus({ [type]: range });
          const endPreviousPeriod = startPreviousPeriod
            .plus({ [type]: 1 })
            .endOf(formattedDurationType);
          return (
            !!fieldValueDate &&
            fieldValueDate >= startPreviousPeriod &&
            fieldValueDate <= endPreviousPeriod
          );
        }
        return (
          !!fieldValueDate &&
          fieldValueDate >= DateTime.now().minus({ [formattedDurationType]: range }) &&
          fieldValueDate <= DateTime.now()
        );
      case 'is during the next':
        if (type === 'weeks' || type === 'months' || type === 'years') {
          const startNextPeriod = DateTime.now()
            .startOf(formattedDurationType)
            .plus({ [type]: 1 });
          const endNextPeriod = startNextPeriod
            .plus({ [type]: range })
            .endOf(formattedDurationType);
          return (
            !!fieldValueDate && fieldValueDate >= startNextPeriod && fieldValueDate <= endNextPeriod
          );
        }
        return (
          !!fieldValueDate &&
          fieldValueDate >= DateTime.now() &&
          fieldValueDate <= DateTime.now().plus({ [formattedDurationType]: range })
        );
      case 'is before the previous':
        if (type === 'weeks' || type === 'months' || type === 'years') {
          const endPreviousPeriod = DateTime.now()
            .startOf(formattedDurationType)
            .minus({ [type]: range })
            .endOf(formattedDurationType);
          return !!fieldValueDate && fieldValueDate < endPreviousPeriod;
        }
        return (
          !!fieldValueDate &&
          fieldValueDate < DateTime.now().minus({ [formattedDurationType]: range })
        );
      case 'is after the next':
        if (type === 'weeks' || type === 'months' || type === 'years') {
          const startNextPeriod = DateTime.now()
            .startOf(formattedDurationType)
            .plus({ [type]: range })
            .startOf(formattedDurationType);
          return !!fieldValueDate && fieldValueDate > startNextPeriod;
        }
        return (
          !!fieldValueDate &&
          fieldValueDate > DateTime.now().plus({ [formattedDurationType]: range })
        );
      case 'is today':
        return !!fieldValueDate && fieldValueDate.hasSame(DateTime.now(), 'day');
      case 'is today or before':
        return !!fieldValueDate && fieldValueDate <= DateTime.now();
      case 'is today or after':
        return hasHour
          ? !!fieldValueDate && fieldValueDate >= DateTime.now()
          : !!fieldValueDate && fieldValueDate >= DateTime.now().startOf('day');
      case 'is before today':
        return hasHour
          ? !!fieldValueDate && fieldValueDate < DateTime.now()
          : !!fieldValueDate && fieldValueDate < DateTime.now().startOf('day');
      case 'is after today':
        return !!fieldValueDate && fieldValueDate > DateTime.now();
      case 'is blank':
        return !fieldValueDate;
      case 'is not blank':
        return fieldValueDate !== null;
      default:
        return false;
    }
  } else {
    const formFieldValue = getFormFieldValue(fieldValue, field);

    if (formFieldValue === null) return false;

    if (typeof formFieldValue === 'boolean' && typeof criteriaValue === 'boolean') {
      switch (operator) {
        case 'is':
          return formFieldValue === criteriaValue;
        case 'is not':
          return formFieldValue !== criteriaValue;
        // The following cases may not be necessary because we always receive a value, but they are here to ensure that the behavior is consistent
        case 'is blank':
          return formFieldValue === null;
        case 'is not blank':
          return formFieldValue !== null;
        default:
          return false;
      }
    }

    switch (operator) {
      case 'contains': {
        if (
          (Array.isArray(formFieldValue) || typeof formFieldValue === 'string') &&
          typeof criteriaValue === 'string'
        ) {
          return formFieldValue.includes(criteriaValue.toLowerCase());
        }
        break;
      }
      case 'does not contain': {
        if (
          (Array.isArray(formFieldValue) || typeof formFieldValue === 'string') &&
          typeof criteriaValue === 'string'
        ) {
          return !formFieldValue.includes(criteriaValue.toLowerCase());
        }
        break;
      }
      case 'is': {
        if (
          (typeof formFieldValue === 'string' || typeof formFieldValue === 'number') &&
          (typeof criteriaValue === 'string' ||
            typeof criteriaValue === 'number' ||
            Array.isArray(criteriaValue))
        ) {
          if (Array.isArray(criteriaValue) && field.type === 'connection') {
            return formFieldValue.toString() === criteriaValue[0].toString().toLowerCase();
          }
          return formFieldValue.toString() === criteriaValue.toString().toLowerCase();
        }
        break;
      }
      case 'is not': {
        if (
          (typeof formFieldValue === 'string' || typeof formFieldValue === 'number') &&
          (typeof criteriaValue === 'string' || typeof criteriaValue === 'number')
        ) {
          return formFieldValue.toString() !== criteriaValue.toString().toLowerCase();
        }
        break;
      }
      case 'starts with': {
        if (typeof formFieldValue === 'string' && typeof criteriaValue === 'string') {
          return formFieldValue.startsWith(criteriaValue);
        }
        break;
      }
      case 'ends with': {
        if (typeof formFieldValue === 'string' && typeof criteriaValue === 'string') {
          return formFieldValue.endsWith(criteriaValue);
        }
        break;
      }
      case 'is blank': {
        if (Array.isArray(formFieldValue)) {
          return formFieldValue.length === 0; // Multiple choice field with blank option
        }
        if (typeof formFieldValue === 'string') {
          return formFieldValue === 'kn-blank' || formFieldValue === '';
        }
        break;
      }
      case 'is not blank': {
        if (Array.isArray(formFieldValue)) {
          return formFieldValue.length > 0;
        }
        if (typeof formFieldValue === 'string') {
          return formFieldValue !== 'kn-blank' && formFieldValue !== '';
        }
        break;
      }
      case 'higher than': {
        let formFieldValueNumber: number | null = null;
        let criteriaValueNumber: number | null = null;

        if (typeof formFieldValue === 'string') {
          formFieldValueNumber = parseInt(formFieldValue, 10);
        } else if (typeof formFieldValue === 'number') {
          formFieldValueNumber = formFieldValue;
        }

        if (typeof criteriaValue === 'string') {
          criteriaValueNumber = parseInt(criteriaValue, 10);
        } else if (typeof criteriaValue === 'number') {
          criteriaValueNumber = criteriaValue;
        }

        if (formFieldValueNumber === null || criteriaValueNumber === null) {
          break;
        }

        return formFieldValueNumber > criteriaValueNumber;
      }
      case 'lower than': {
        let formFieldValueNumber: number | null = null;
        let criteriaValueNumber: number | null = null;

        if (typeof formFieldValue === 'string') {
          formFieldValueNumber = parseInt(formFieldValue, 10);
        } else if (typeof formFieldValue === 'number') {
          formFieldValueNumber = formFieldValue;
        }

        if (typeof criteriaValue === 'string') {
          criteriaValueNumber = parseInt(criteriaValue, 10);
        } else if (typeof criteriaValue === 'number') {
          criteriaValueNumber = criteriaValue;
        }

        if (formFieldValueNumber === null || criteriaValueNumber === null) {
          break;
        }

        return formFieldValueNumber < criteriaValueNumber;
      }
      default:
        return false;
    }

    return false;
  }
}

function criteriaPredicate(
  data: { [key: string]: any },
  fields: KnackField[],
  criteriaRule: RuleCriteria | MapRuleCriteria,
  onCriteriaMet?: ({
    data,
    field,
    criteria
  }: {
    data: { [key: string]: any };
    field: KnackField;
    criteria: RuleCriteria | MapRuleCriteria;
  }) => void
): boolean {
  const { field: criteriaField } = criteriaRule;
  const field = fields.find((f) => f.key === criteriaField);
  if (!field) return false;

  const fieldValue = data[criteriaField];
  if (fieldValue === undefined) return false;

  if (field.type === 'connection' && Array.isArray(fieldValue) && fieldValue.length > 0) {
    const isMet = isCriteriaMet([fieldValue[0].id], field, criteriaRule);
    if (isMet && onCriteriaMet) {
      onCriteriaMet({
        data,
        field,
        criteria: criteriaRule
      });
    }
    return isMet;
  }

  const isMet = isCriteriaMet(fieldValue, field, criteriaRule);
  if (isMet && onCriteriaMet) {
    onCriteriaMet({
      data,
      field,
      criteria: criteriaRule
    });
  }
  return isMet;
}

type OnCriteriaMet<T> = ({
  data,
  field,
  criteria
}: {
  data: { [key: string]: any };
  field: KnackField;
  criteria: T;
}) => void;

export function isEveryCriteriaMet(
  data: { [key: string]: any },
  fields: KnackField[],
  criteria?: RuleCriteria[],
  onCriteriaMet?: OnCriteriaMet<RuleCriteria>
): boolean;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export function isEveryCriteriaMet(
  data: { [key: string]: any },
  fields: KnackField[],
  criteria?: MapRuleCriteria[],
  onCriteriaMet?: OnCriteriaMet<MapRuleCriteria>
): boolean;
export function isEveryCriteriaMet(
  data: { [key: string]: any },
  fields: KnackField[],
  criteria?: RuleCriteria[] | MapRuleCriteria[],
  onCriteriaMet?: OnCriteriaMet<RuleCriteria | MapRuleCriteria>
): boolean {
  if (!criteria || criteria.length === 0) return false;
  return criteria.every((criteriaRule) =>
    criteriaPredicate(data, fields, criteriaRule, onCriteriaMet)
  );
}

export function isSomeCriteriaMet(
  data: { [key: string]: any },
  fields: KnackField[],
  criteria?: RuleCriteria[],
  onCriteriaMet?: OnCriteriaMet<RuleCriteria>
): boolean;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export function isSomeCriteriaMet(
  data: { [key: string]: any },
  fields: KnackField[],
  criteria?: MapRuleCriteria[],
  onCriteriaMet?: OnCriteriaMet<MapRuleCriteria>
): boolean;
export function isSomeCriteriaMet(
  data: { [key: string]: any },
  fields: KnackField[],
  criteria?: RuleCriteria[] | MapRuleCriteria[],
  onCriteriaMet?: OnCriteriaMet<RuleCriteria | MapRuleCriteria>
): boolean {
  if (!criteria || criteria.length === 0) return false;
  return criteria.some((criteriaRule) =>
    criteriaPredicate(data, fields, criteriaRule, onCriteriaMet)
  );
}
