import { cn } from '@/utils/tailwind';

interface DragInsertIndicatorProps {
  isVisible: boolean;
  className?: string;
  direction?: 'horizontal' | 'vertical';
}

export function DragInsertIndicator({
  isVisible,
  className,
  direction = 'horizontal'
}: DragInsertIndicatorProps) {
  return (
    <div
      className={cn(
        'hidden rounded-md bg-blue-500',
        {
          block: isVisible,
          'h-1': direction === 'horizontal',
          'h-full w-1': direction === 'vertical'
        },
        className
      )}
    />
  );
}
