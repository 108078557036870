import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronRight as ArrowIcon } from 'react-icons/hi2';
import { Link, useLocation } from 'react-router-dom';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { useAppInit } from '@/hooks/useAppInit';
import { getPageAncestorsUrl } from '@/utils/application';
import { cn } from '@/utils/tailwind';
import { usePageContext } from '@/context/PageContext';

export function Breadcrumbs() {
  const [t] = useTranslation();
  const { application } = useAppInit();
  const location = useLocation();
  const { activePage } = usePageContext();
  const linkClasses = 'max-w-40 truncate';

  // Skip showing breadcrumbs if the page is in the root level
  if (!activePage || !application?.pages || (!activePage.parentSlug && !activePage.menuPageKey)) {
    return null;
  }

  const ancestorPages: LiveAppPage[] = getPageAncestorsUrl(activePage, application.pages);

  return (
    <div className="mb-4 flex items-center gap-2">
      <Link to={`/${location.search}`} className={cn('hover:text-brand-default', linkClasses)}>
        {t('keywords.home')}
      </Link>
      <ArrowIcon />
      {ancestorPages.map((page) => {
        // Skip dropdown pages from breadcrumbs
        if (!page.dropdownPages) {
          return (
            <React.Fragment key={page.key}>
              <Link
                to={`${page.slug}${location.search}`}
                className={cn('hover:text-brand-default', linkClasses)}
              >
                {page.name}
              </Link>
              <ArrowIcon />
            </React.Fragment>
          );
        }
        return null;
      })}
      <span className={cn('text-brand-default', linkClasses)}>{activePage.name}</span>
    </div>
  );
}
