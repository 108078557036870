import { useContext, useRef, type ComponentPropsWithoutRef } from 'react';
import { Chart, ThemeProviderContext, type ChartConfig } from '@knack/asterisk-react';
import {
  Label,
  LabelList,
  Pie,
  PieChart,
  Legend as RechartsLegend,
  Tooltip as RechartsTooltip
} from 'recharts';
import { type HorizontalAlignmentType } from 'recharts/types/component/DefaultLegendContent';
import { type ViewBox } from 'recharts/types/util/types';

import type { ChartData, ChartRecord, ReportViewChart } from '@/types/schema/views/ReportView';
import { getDefaultChartLayout } from '@/utils/schema/patch/reports';
import { useThemingContext } from '@/context/ThemingContext';
import { ChartDataTable } from './shared/ChartDataTable';
import { ChartEmptyState } from './shared/ChartEmptyState';
import { ChartExportButton } from './shared/ChartExportButton';
import { ChartHeader } from './shared/ChartHeader';
import { getColors } from './shared/colors';
import { CustomLabel } from './shared/CustomLabel';

interface PieChartRenderProps extends ComponentPropsWithoutRef<'div'> {
  chart: ReportViewChart;
  chartData: ChartData;
}

const getKeyForChartRecord = (record: ChartRecord) =>
  typeof record.group_0 === 'string'
    ? record.group_0?.replaceAll(' ', '-').toLowerCase()
    : record.group_0;

export function PieChartRender({ chart, chartData, ...rest }: PieChartRenderProps) {
  const { theme } = useThemingContext();
  const { isDarkMode } = useContext(ThemeProviderContext);

  const chartRef = useRef<HTMLDivElement>(null);

  const chartRecords = chartData.records.map((record) => {
    const key = getKeyForChartRecord(record);
    const recordModified: ChartRecord = {
      ...record,
      group_0: key,
      fill: `var(--color-${key})`
    };
    return recordModified;
  });

  if (chartRecords.length === 0) {
    return <ChartEmptyState />;
  }

  const total = chartRecords.reduce((acc, record) => {
    if (typeof record.calc_0 !== 'number') {
      return acc;
    }
    return acc + record.calc_0;
  }, 0);

  const totalLabel = (props: { viewBox?: ViewBox }) => {
    const { viewBox } = props; // Extract viewBox from props, which could be undefined
    if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
      return (
        <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
          <tspan x={viewBox.cx} y={viewBox.cy} className="fill-current text-3xl font-bold">
            {total}
          </tspan>
          <tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24} className="fill-current">
            {chart.calculations[0].label}
          </tspan>
        </text>
      );
    }
    return null;
  };

  const chartLayout = chart.layout || getDefaultChartLayout();
  const chartConfig: ChartConfig = {};

  const colors = getColors(theme, isDarkMode, chart.layout?.colors) || [];

  chartData.records.forEach((record, index) => {
    const key = getKeyForChartRecord(record);
    chartConfig[key] = {
      label: record.group_0,
      color: colors[index % colors.length] as string
    };
  });

  const shouldUseCustomLabel = chartLayout.data_labels && chartLayout.label_position === 'outside';
  const customLabel = ({
    payload,
    ...props
  }: {
    payload: { raw_0: string };
    [key: string]: unknown;
  }) => <CustomLabel chartConfig={chartConfig} payload={payload} {...props} />;
  const labelValue = shouldUseCustomLabel ? customLabel : chartLayout.data_labels;

  const shouldUseVerticalLegend = chartLayout.legend === 'right' || chartLayout.legend === 'left';
  const { shouldShowDataTable, export_links: shouldShowExportButton } = chart.options;

  return (
    <div data-testid="pie-chart" {...rest}>
      <ChartHeader chart={chart} />
      {shouldShowExportButton && <ChartExportButton chart={chart} chartRef={chartRef} />}
      <Chart ref={chartRef} config={chartConfig} className="w-full">
        <PieChart>
          <RechartsTooltip content={<Chart.TooltipContent hideLabel />} />
          {chartLayout.legend !== 'none' && (
            <RechartsLegend
              content={<Chart.LegendContent nameKey="group_0" />}
              layout={shouldUseVerticalLegend ? 'vertical' : 'horizontal'}
              verticalAlign={shouldUseVerticalLegend ? 'middle' : undefined}
              align={
                chartLayout.legend === 'bottom'
                  ? 'center'
                  : (chartLayout.legend as HorizontalAlignmentType)
              }
            />
          )}
          <Pie
            data={chartRecords}
            dataKey="calc_0"
            nameKey="group_0"
            innerRadius={chartLayout.pie_type === 'donut' ? '50%' : undefined}
            labelLine={!shouldUseCustomLabel}
            label={chartLayout.label_position !== 'inside' && labelValue}
            isAnimationActive={false}
          >
            {chartLayout.data_labels && chartLayout.label_position === 'inside' && (
              <LabelList
                dataKey="group_0"
                fill="white"
                stroke="none"
                fontSize={12}
                formatter={(value: keyof typeof chartConfig) => chartConfig[value]?.label}
              />
            )}
            {chartLayout.shouldShowTotal && chartLayout.pie_type === 'donut' && (
              <Label content={totalLabel} />
            )}
          </Pie>
        </PieChart>
      </Chart>
      {shouldShowDataTable && <ChartDataTable chart={chart} chartRecords={chartRecords} />}
    </div>
  );
}
