import { useEffect, useRef, type ReactNode } from 'react';

import type { FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';

type DetailsItemContainerProps = {
  record: FormattedViewRecord;
  selectedRecord?: FormattedViewRecord;
  onClick?: (record: FormattedViewRecord) => void;
  children: ReactNode;
};

export function MapDetailsItemContainer({
  children,
  record,
  selectedRecord,
  onClick
}: DetailsItemContainerProps) {
  const detailsItemRef = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    if (selectedRecord?.values.id && detailsItemRef.current[selectedRecord.values.id]) {
      detailsItemRef.current[selectedRecord.values.id]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [selectedRecord]);

  return (
    <div
      role="button"
      tabIndex={0}
      ref={(el) => {
        if (!record.values.id) return;
        detailsItemRef.current[record.values.id] = el;
      }}
      className={cn('block cursor-pointer p-0 text-left', {
        'hover:bg-subtle': selectedRecord?.values.id !== record.values.id,
        'bg-subtle': selectedRecord?.values.id === record.values.id
      })}
      onClick={() => onClick && onClick(record)}
      onKeyDown={(e) => {
        if (onClick && (e.key === 'Enter' || e.key === 'Space')) {
          onClick(record);
        }
      }}
    >
      {children}
    </div>
  );
}
