import { Fragment, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Checkbox,
  Combobox,
  Label,
  MultiSelect,
  RadioGroup,
  Spinner,
  type ComboboxOption
} from '@knack/asterisk-react';

import { type ConnectionField } from '@/types/schema/fields';
import { type FormViewConnectionInput } from '@/types/schema/views/form/Connection';
import { type FormView } from '@/types/schema/views/FormView';
import { useConnectionRecordsQuery } from '@/hooks/api/queries/useConnectionRecordsQuery';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { formatConnectionInputIdentifier } from '@/components/views/form/utils';
import { useViewContext } from '@/components/views/ViewContext';
import { AddConnectedRecordButton } from './AddConnectedRecordButton';
import {
  getConnectionRecordFromId,
  handleSelectConnection,
  handleSelectMultipleCheckboxes,
  handleSelectMultipleConnections
} from './helper';
import { type ConnectionRecord } from './types';

const CONNECTION_LIMIT = 15;

export function ConnectionInput({ input }: { input: FormViewConnectionInput }) {
  const { view, sourceTable } = useViewContext<FormView>();
  const {
    setValue: setFormValue,
    formState: { errors },
    getValues
  } = useFormContext();

  // If we are in the builder, we need to get the connection input field so we can get the relationship object key. This is needed to fetch the records.
  const connectionInputField = isInternalBuilderIframe()
    ? (sourceTable.fields.find(
        (f) => f.type === 'connection' && f.key === input.field.key
      ) as ConnectionField)
    : undefined;

  const { data: connectionRecords, isLoading } = useConnectionRecordsQuery({
    viewKey: view.key,
    relationshipObjectKey: connectionInputField?.relationship.object,
    fieldKey: input.field.key,
    filters: input.source?.filters
  });

  const allowOptionInserts = input.allow_option_inserts;
  const isViewActionCreate = view.action === 'create';
  const inputFormat = input.format;
  const defaultConnectionValues = getValues(input.field.key);
  const hasManyRelationship = input.relationship?.has === 'many';
  const isCheckboxInput = inputFormat.input === 'checkbox';
  const triggerMinWidth = 'min-w-[150px]';
  const isReadOnly = input.read_only;

  const [newConnectionRecordId, setNewConnectionRecordId] = useState('');

  // We need the useEffect because all the process to get the records is async
  useEffect(() => {
    if (view.action === 'update') {
      setFormValue(input.field.key, defaultConnectionValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We update the input with the new connection record we have introduced
  useEffect(() => {
    if (newConnectionRecordId && connectionRecords) {
      const newConnectionRecord = getConnectionRecordFromId(
        connectionRecords.records,
        newConnectionRecordId
      );
      if (hasManyRelationship) {
        setFormValue(input.field.key, [...getValues(input.field.key), newConnectionRecord]);
      } else {
        setFormValue(input.field.key, [newConnectionRecord]);
      }
      setNewConnectionRecordId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionRecords]);

  const getRecordIdentifierFromId = (id: string) => {
    if (!connectionRecords) return '';
    const record = connectionRecords.records.find((connection) => connection.id === id);
    return record?.identifier || '';
  };

  if (isLoading) return <Spinner className="block" />;

  if (!connectionRecords) return null;

  // If the input is a checkbox and has many relationships, we need to check if we have reached the limit of connections
  const hasReachedConnectionLimit = connectionRecords.records?.length >= CONNECTION_LIMIT;

  const shouldRenderMultiSelectComponent =
    (hasManyRelationship && !isCheckboxInput) ||
    (hasManyRelationship && isCheckboxInput && hasReachedConnectionLimit);

  const shouldRenderSingleSelectComponent =
    inputFormat.input === 'chosen' || (inputFormat.input === 'radio' && hasReachedConnectionLimit);

  if (shouldRenderMultiSelectComponent) {
    const multiSelectId = `${input.field.key}-connections-multi-select`;
    const options = connectionRecords.records.map((record) => ({
      label: formatConnectionInputIdentifier(record.identifier),
      key: record.id
    }));

    return (
      <>
        <Controller
          name={input.field.key}
          {...(isViewActionCreate && {
            defaultValue:
              input.format.conn_default !== 'none' ? [connectionRecords.records?.[0]] : []
          })}
          render={({ field: { onChange, value } }) => {
            const fieldValues = value
              ? value.map((val: ConnectionRecord) => ({
                  label: val.identifier,
                  key: val.id
                }))
              : [];

            return (
              <MultiSelect
                id={multiSelectId}
                data-testid={multiSelectId}
                selectedOptions={fieldValues}
                onSelectOptions={(values) => handleSelectMultipleConnections(values, onChange)}
                maxVisibleChips={3}
                triggerClassName={triggerMinWidth}
                options={options}
                isSearchEnabled
              />
            );
          }}
        />
        <FormErrorMessage errors={errors} name={input.field.key} />
        {allowOptionInserts && input.view && (
          <AddConnectedRecordButton
            connectionInput={input}
            onSuccess={(newRecordId) => setNewConnectionRecordId(newRecordId)}
          />
        )}
      </>
    );
  }

  if (shouldRenderSingleSelectComponent) {
    const options = connectionRecords.records.map((record) => ({
      label: formatConnectionInputIdentifier(record.identifier),
      key: record.id
    }));

    return (
      <>
        <Controller
          name={input.field.key}
          {...(isViewActionCreate && {
            defaultValue: input.format.conn_default !== 'none' ? [connectionRecords.records[0]] : []
          })}
          render={({ field: { value: fieldValue, onChange } }) => {
            const formattedValue: ComboboxOption = {
              key: fieldValue?.[0]?.id,
              label: fieldValue?.[0]?.identifier || getRecordIdentifierFromId(fieldValue?.[0]?.id)
            };

            return (
              <Combobox
                id={`${view.key}-${input.id}-combobox-single-connection`}
                options={options}
                isSearchEnabled
                selectedOption={formattedValue}
                triggerClassName={triggerMinWidth}
                onSelectOption={(option) => handleSelectConnection(option, onChange)}
              />
            );
          }}
        />
        <FormErrorMessage errors={errors} name={input.field.key} />
        {allowOptionInserts && input.view && (
          <AddConnectedRecordButton
            connectionInput={input}
            onSuccess={(newRecordId) => setNewConnectionRecordId(newRecordId)}
          />
        )}
      </>
    );
  }

  if (isCheckboxInput) {
    return (
      <>
        {connectionRecords.records.map((record) => {
          const elementId = `${input.field.key}-${record.id}-checkbox`;

          return (
            <Fragment key={elementId}>
              <div className="flex items-center gap-2">
                <Controller
                  name={input.field.key}
                  {...(isViewActionCreate && {
                    defaultValue:
                      input.format.conn_default !== 'none' ? [connectionRecords.records[0]] : []
                  })}
                  render={({ field: { value: fieldValues, onChange } }) => (
                    <Checkbox
                      disabled={isReadOnly}
                      checked={fieldValues.some(
                        (value: ConnectionRecord) => value.id === record.id
                      )}
                      id={elementId}
                      data-testid={elementId}
                      onClick={() => handleSelectMultipleCheckboxes(record, fieldValues, onChange)}
                    />
                  )}
                />
                <Label htmlFor={elementId}>
                  {formatConnectionInputIdentifier(record.identifier)}
                </Label>
              </div>
            </Fragment>
          );
        })}
        <FormErrorMessage errors={errors} name={input.field.key} />
        {allowOptionInserts && input.view && !isReadOnly && (
          <AddConnectedRecordButton
            connectionInput={input}
            onSuccess={(newRecordId) => setNewConnectionRecordId(newRecordId)}
          />
        )}
      </>
    );
  }

  return (
    <Controller
      name={input.field.key}
      {...(isViewActionCreate && {
        defaultValue:
          input.format.conn_default !== 'none'
            ? [connectionRecords.records[0]]
            : [{ id: undefined, identifier: '' }]
      })}
      render={({ field: { value: fieldValue, onChange } }) => (
        <>
          <RadioGroup disabled={isReadOnly} value={fieldValue?.[0]?.id}>
            {connectionRecords?.records.map((record) => {
              const elementId = `${input.field.key}-${record.id}-radio`;
              return (
                <RadioGroup.Container key={elementId}>
                  <RadioGroup.Item
                    value={record.id}
                    id={elementId}
                    data-testid={elementId}
                    onClick={() => onChange([record])}
                  />
                  <Label htmlFor={elementId}>
                    {formatConnectionInputIdentifier(record.identifier)}
                  </Label>
                </RadioGroup.Container>
              );
            })}
          </RadioGroup>
          <FormErrorMessage errors={errors} name={input.field.key} />
          {allowOptionInserts && input.view && !isReadOnly && (
            <AddConnectedRecordButton
              connectionInput={input}
              onSuccess={(newRecordId) => setNewConnectionRecordId(newRecordId)}
            />
          )}
        </>
      )}
    />
  );
}
