import { type FormView } from '@/types/schema/views/FormView';
import { FormFieldInput } from './FormFieldInput';
import { FormStaticInput } from './FormStaticInput';

interface FormGroupsProps {
  view: FormView;
}

export function FormGroups({ view }: FormGroupsProps) {
  return (
    <div className="flex flex-col gap-12">
      {view.groups.map((group) => (
        <div key={group.id} className="block flex-wrap gap-4 sm:flex sm:flex-nowrap">
          {group.columns.map((column) => (
            <div key={column.id} className="flex flex-1 flex-col">
              {column.inputs.map((input) => {
                const isStaticField = input.type === 'section_break' || input.type === 'divider';

                if (isStaticField) {
                  return <FormStaticInput key={input.id} input={input} />;
                }

                return <FormFieldInput key={input.id} input={input} />;
              })}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
