import { type ChartData } from '@/types/schema/views/ReportView';

export const chartData: ChartData[] = [
  {
    records: [
      {
        group_0: 'Apples',
        calc_0: '3',
        raw_0: '3'
      },
      {
        group_0: 'Oranges',
        calc_0: '1',
        raw_0: '1'
      },
      {
        group_0: 'Bananas',
        calc_0: '2',
        raw_0: '2'
      },
      {
        group_0: 'Lemons',
        calc_0: 4,
        raw_0: 4
      },
      {
        group_0: 'Carrots',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Melon',
        calc_0: 6,
        raw_0: 6
      }
    ],
    filters: [
      {
        header: 'Apples'
      },
      {
        header: 'Oranges'
      },
      {
        header: 'Bananas'
      },
      {
        header: 'Lemons'
      },
      {
        header: 'Carrots'
      },
      {
        header: 'Melon'
      }
    ],
    summaries: []
  },
  {
    records: [
      {
        group_0: 'Apples',
        calc_0: '3',
        raw_0: '3'
      },
      {
        group_0: 'Oranges',
        calc_0: '1',
        raw_0: '1'
      },
      {
        group_0: 'Bananas',
        calc_0: '2',
        raw_0: '2'
      },
      {
        group_0: 'Lemons',
        calc_0: 4,
        raw_0: 4
      },
      {
        group_0: 'Carrots',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Melon',
        calc_0: 6,
        raw_0: 6
      }
    ],
    filters: [
      {
        header: 'Apples'
      },
      {
        header: 'Oranges'
      },
      {
        header: 'Bananas'
      },
      {
        header: 'Lemons'
      },
      {
        header: 'Carrots'
      },
      {
        header: 'Melon'
      }
    ],
    summaries: []
  },
  {
    records: [
      {
        group_0: 'Apples',
        calc_0: '1',
        raw_0: '1'
      },
      {
        group_0: 'Oranges',
        calc_0: '1',
        raw_0: '1'
      },
      {
        group_0: 'Bananas',
        calc_0: '1',
        raw_0: '1'
      },
      {
        group_0: 'Lemons',
        calc_0: 4,
        raw_0: 4
      },
      {
        group_0: 'Carrots',
        calc_0: 1,
        raw_0: 1
      },
      {
        group_0: 'Melon',
        calc_0: 6,
        raw_0: 6
      }
    ],
    filters: [
      {
        header: 'Apples'
      },
      {
        header: 'Oranges'
      },
      {
        header: 'Bananas'
      },
      {
        header: 'Lemons'
      },
      {
        header: 'Carrots'
      },
      {
        header: 'Melon'
      }
    ],
    summaries: []
  }
];
