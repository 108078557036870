import { type FormView } from '@/types/schema/views/FormView';

export const signatureDemoView: FormView = {
  id: '664b0b939b49ff013f8c40fd',
  submitButtonText: 'Submit',
  columns: [],
  links: [],
  inputs: [],
  groups: [
    {
      id: 'group_1',
      columns: [
        {
          id: 'column_1',
          inputs: [
            {
              id: 'field_1',
              instructions: '',
              field: {
                key: 'field_1'
              },
              label: 'Signature',
              type: 'signature'
            }
          ]
        }
      ]
    }
  ],
  rules: {
    submits: [
      {
        key: 'submit_1',
        action: 'message',
        message: 'Form successfully submitted.',
        reload_show: true,
        is_default: true
      }
    ],
    fields: [],
    records: [],
    emails: []
  },
  type: 'form',
  reportType: null,
  name: 'Add Demo Form',
  source: {
    criteria: {
      match: 'all',
      rules: [],
      groups: []
    },
    limit: '',
    sort: [
      {
        field: 'field_1',
        order: 'asc'
      }
    ],
    object: 'object_1'
  },
  action: 'create',
  title: 'Form Element',
  description: '',
  alert: 'none',
  key: 'view_1',
  design: {}
};
