import { forwardRef, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';

import { LabelWithIcon } from '@/components/LabelWithIcon';

interface ActionButtonProps {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  icon:
    | {
        icon: string;
        align?: 'left' | 'right';
      }
    | undefined;
  label: string | undefined;
  linkTo?: string;
  isDelete?: boolean;
}

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ icon, label = '', onClick, linkTo, isDelete }, ref) => {
    const [t] = useTranslation();
    const location = useLocation();

    const content = (
      <LabelWithIcon icon={icon?.icon} iconPosition={icon?.align || 'left'}>
        {label}
      </LabelWithIcon>
    );

    return (
      <Button
        aria-label={t('components.views.action_button')}
        ref={ref}
        intent={isDelete ? 'destructive' : 'secondary'}
        size="sm"
        asChild={!!linkTo}
        className="truncate"
        {...(onClick && { onClick })}
      >
        {linkTo ? <Link to={`${linkTo}${location.search}`}>{content}</Link> : content}
      </Button>
    );
  }
);

ActionButton.displayName = 'ActionButton';
