import { useTranslation } from 'react-i18next';
import { SimplePagination } from '@knack/asterisk-react';

import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type TableView } from '@/types/schema/views/TableView';
import { useViewSearchParams } from '@/hooks/useViewSearchParams';
import { cn } from '@/utils/tailwind';
import { SelectPagination } from '@/components/views/view-options/SelectPagination';
import { RowsPerPageSelect } from './view-options/RowsPerPageSelect';
import { useViewContext } from './ViewContext';

type RecordsRangeProps = {
  totalRecords: number;
  rowsPerPage: number;
  currentPage: number;
};

function RecordsRange({ totalRecords, rowsPerPage, currentPage }: RecordsRangeProps) {
  const [t] = useTranslation();
  const firstRecord = (currentPage - 1) * rowsPerPage + 1;
  const lastRecord = Math.min(currentPage * rowsPerPage, totalRecords);
  return (
    <div className="text-xs text-subtle">
      {t('components.views.records_range', {
        firstRecord,
        lastRecord,
        totalRecords
      })}
    </div>
  );
}

interface ViewFooterSectionProps {
  totalPageNumber: number;
  className?: string;
  isSmallVersion?: boolean;
  totalRecords?: number;
}

type PaginationProps = {
  shouldAllowChangeRowsPerPage: boolean;
  shouldRenderPagination: boolean;
  totalPageNumber: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  totalRecords?: number;
  rowsPerPage: number | null;
};

function PaginationSmallVersion({
  shouldAllowChangeRowsPerPage,
  shouldRenderPagination,
  totalPageNumber,
  currentPage,
  onPageChange,
  totalRecords,
  rowsPerPage
}: PaginationProps) {
  return (
    <div
      className={cn(
        'flex w-full items-center',
        shouldAllowChangeRowsPerPage || (!shouldAllowChangeRowsPerPage && totalRecords)
          ? 'justify-between'
          : 'justify-end'
      )}
    >
      {shouldAllowChangeRowsPerPage && (
        <div>
          <RowsPerPageSelect />
        </div>
      )}
      {!shouldAllowChangeRowsPerPage && totalRecords && rowsPerPage && (
        <RecordsRange
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
        />
      )}
      {shouldRenderPagination && (
        <SelectPagination
          totalPages={totalPageNumber}
          currentPage={Number(currentPage)}
          onPageChange={(page) => onPageChange(page)}
        />
      )}
    </div>
  );
}

function PaginationLargeVersion({
  shouldAllowChangeRowsPerPage,
  shouldRenderPagination,
  totalPageNumber,
  currentPage,
  onPageChange,
  totalRecords,
  rowsPerPage
}: PaginationProps) {
  const [t] = useTranslation();
  return (
    <>
      {shouldAllowChangeRowsPerPage && (
        <div className="flex items-center gap-2">
          <RowsPerPageSelect />
          {totalRecords && rowsPerPage && (
            <RecordsRange
              totalRecords={totalRecords}
              rowsPerPage={rowsPerPage}
              currentPage={currentPage}
            />
          )}
        </div>
      )}
      {!shouldAllowChangeRowsPerPage && rowsPerPage && (
        <div className="text-xs text-subtle">{`${t('components.views.showing')} ${rowsPerPage} ${t('components.views.per_page')}`}</div>
      )}
      {shouldRenderPagination && (
        <div>
          <SimplePagination
            totalPages={totalPageNumber}
            currentPage={Number(currentPage)}
            onPageChange={(page) => onPageChange(page)}
            maxVisiblePages={3}
          />
        </div>
      )}
    </>
  );
}

export function ViewFooterSection({
  totalPageNumber,
  className,
  isSmallVersion = false,
  totalRecords
}: ViewFooterSectionProps) {
  const { view } = useViewContext<ListView | TableView | MapView>();
  const { currentPage, rowsPerPage, setViewParam } = useViewSearchParams(view);

  const { allow_limit: shouldAllowChangeRowsPerPage } = view;

  const shouldRenderPagination = totalPageNumber > 1;
  const shouldRenderFooter = shouldAllowChangeRowsPerPage || shouldRenderPagination;

  if (!shouldRenderFooter) {
    return null;
  }

  if (!shouldAllowChangeRowsPerPage && !shouldRenderPagination) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex w-full items-center justify-between gap-3 border-t border-default p-2 md:flex-row',
        className,
        {
          'justify-end': !shouldAllowChangeRowsPerPage && !totalRecords
        }
      )}
    >
      {isSmallVersion ? (
        <PaginationSmallVersion
          shouldAllowChangeRowsPerPage={shouldAllowChangeRowsPerPage}
          shouldRenderPagination={shouldRenderPagination}
          totalPageNumber={totalPageNumber}
          totalRecords={totalRecords}
          currentPage={Number(currentPage)}
          rowsPerPage={rowsPerPage ? Number(rowsPerPage) : null}
          onPageChange={(page) => setViewParam({ page })}
        />
      ) : (
        <PaginationLargeVersion
          shouldAllowChangeRowsPerPage={shouldAllowChangeRowsPerPage}
          shouldRenderPagination={shouldRenderPagination}
          totalPageNumber={totalPageNumber}
          totalRecords={totalRecords}
          currentPage={Number(currentPage)}
          rowsPerPage={rowsPerPage ? Number(rowsPerPage) : null}
          onPageChange={(page) => setViewParam({ page })}
        />
      )}
    </div>
  );
}
