import { type ReactNode } from 'react';
import { PiMapPinFill as LocationIcon } from 'react-icons/pi';

export function MapEmptyState({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-col items-center gap-4">
      <LocationIcon size={34} fill="#D3A0CB" />
      <div className="text-center text-sm font-semibold text-default">{children}</div>
    </div>
  );
}
