import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { type LiveAppPageFull } from '@/types/schema/LiveAppPage';
import { queryKeys } from '@/hooks/api/queryKeys';
import {
  useMessagingContext,
  type StartAddViewPayload,
  type StartDeleteViewInputPayload,
  type StartEditViewInputPayload
} from '@/context/MessagingContext';
import {
  type PageEditorItemToSelect,
  type PageEditorSelectedItem,
  type PageEditorUpdate
} from './helpers/types';

type PageEditorContextState = {
  page: LiveAppPageFull;
  selectedItem: PageEditorSelectedItem | null;
  isDraggingActive: boolean;
  isSyncingPage: boolean;
  updatePage: (update: PageEditorUpdate) => void;
  startAddView: ({ columnId, sectionId, position }: StartAddViewPayload) => void;
  startEditViewInput: ({ viewKey, viewInputId }: StartEditViewInputPayload) => void;
  startDeleteViewInput: ({ viewKey, viewInputId }: StartDeleteViewInputPayload) => void;
  selectItem: (itemToSelect: PageEditorItemToSelect) => void;
  setIsDraggingActive: (isDraggingActive: boolean) => void;
} | null;

const PageEditorContext = createContext<PageEditorContextState>(null);

type PageEditorContextProviderProps = {
  initialPage: LiveAppPageFull;
  children: React.ReactNode;
};

export function PageEditorContextProvider({
  initialPage,
  children
}: PageEditorContextProviderProps) {
  const { sendMessageToBuilder, messageFromBuilder } = useMessagingContext();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(initialPage);
  const [selectedItem, setSelectedItem] = useState<PageEditorSelectedItem>(null);
  const [isDraggingActive, setIsDraggingActive] = useState(false);
  const [isSyncingPage, setIsSyncingPage] = useState(true);

  const selectItem = useCallback(
    (itemToSelect: PageEditorItemToSelect) => {
      sendMessageToBuilder({
        action: 'select',
        itemToSelect
      });
    },
    [sendMessageToBuilder]
  );

  const startAddView = useCallback(
    (payload: StartAddViewPayload) => {
      sendMessageToBuilder({
        action: 'start-add-view',
        ...payload
      });
    },
    [sendMessageToBuilder]
  );

  const startEditViewInput = useCallback(
    (payload: StartEditViewInputPayload) => {
      sendMessageToBuilder({
        action: 'start-edit-view-input',
        ...payload
      });
    },
    [sendMessageToBuilder]
  );

  const startDeleteViewInput = useCallback(
    (payload: StartDeleteViewInputPayload) => {
      sendMessageToBuilder({
        action: 'start-delete-view-input',
        ...payload
      });
    },
    [sendMessageToBuilder]
  );

  const updatePage = useCallback(
    (update: PageEditorUpdate) => {
      sendMessageToBuilder({
        action: 'update',
        update
      });
    },
    [sendMessageToBuilder]
  );

  const contextValue = useMemo(
    () => ({
      page,
      selectedItem,
      isDraggingActive,
      isSyncingPage,
      updatePage,
      startAddView,
      startEditViewInput,
      startDeleteViewInput,
      selectItem,
      setIsDraggingActive
    }),
    [
      page,
      selectedItem,
      isDraggingActive,
      isSyncingPage,
      updatePage,
      startAddView,
      startEditViewInput,
      startDeleteViewInput,
      selectItem
    ]
  );

  useEffect(() => {
    /**
     * When the Live App iFrame first loads, it requests the current page data so both local pages can be in sync.
     * This is necessary for two reasons:
     *   - If the iFrame needs to be mounted under a different component (e.g. in Preview mode), it will know the current state of the page
     *   - Ensures both the builder and Live App have the same section and column IDs
     *     Context:
     *      When fetching pages from the server, unique identifiers are generated on-the-fly for sections and columns that lack an ID,
     *      which results in the builder and the Live App having different IDs for the same entities, causing issues when communicating
     *      page editor changes between the two applications. We treat the builder as the source of truth for the page data, so the Live App
     *      requests the updated page from the builder and updates its own local page to match.
     */
    sendMessageToBuilder({
      action: 'request-page-sync'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!messageFromBuilder) {
      return;
    }

    // If the builder is sending the page requested, we update the Live App to match
    if (messageFromBuilder.action === 'page-sync') {
      setPage(messageFromBuilder.page);

      if (messageFromBuilder.initialSelectedItem) {
        setSelectedItem(messageFromBuilder.initialSelectedItem);
      }

      setIsSyncingPage(false);
    }

    if (messageFromBuilder.action === 'select') {
      setSelectedItem(messageFromBuilder.selectedItem);
    }

    if (messageFromBuilder.action === 'update') {
      setPage(messageFromBuilder.updatedPage);

      // The builder could also send a selected item along with the updated page (e.g. when a new section or view is added so that it is selected automatically)
      if (messageFromBuilder.selectedItem) {
        setSelectedItem(messageFromBuilder.selectedItem);
      }
    }

    if (messageFromBuilder.action === 'update-main-navigation') {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.entryPages] });
    }
  }, [messageFromBuilder, queryClient]);

  return <PageEditorContext.Provider value={contextValue}>{children}</PageEditorContext.Provider>;
}

export const usePageEditorContext = () => {
  const context = useContext(PageEditorContext);
  if (!context) {
    throw new Error('usePageEditorContext must be used within a PageEditorContextProvider');
  }
  return context;
};
