import { useRef, useState } from 'react';
import { Controller, type ControllerRenderProps } from 'react-hook-form';
import { HiCalendar as CalendarIcon } from 'react-icons/hi2';
import { DatePicker, Input } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import { type DateTimeField } from '@/types/schema/fields';
import { DAY_PICKER_FROM_YEAR, DAY_PICKER_TO_YEAR } from '@/utils/constants';
import { cn } from '@/utils/tailwind';
import { defaultDateFormatMap } from '@/components/views/form/inputs/date-time/types';
import { getFormattedDatePickerDate } from '@/components/views/form/inputs/date-time/utils';
import { TimeInput } from '@/components/views/form/TimeInput';
import { type ViewFilterSpecialInput } from '@/components/views/view-options/ViewFilterRow';
import { useThemingContext } from '@/context/ThemingContext';

export function FilterDateTimeInput({
  formFieldName,
  field
}: ViewFilterSpecialInput<DateTimeField>) {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { theme } = useThemingContext();
  const inputFormat = field.format;
  const defaultDateFormat = inputFormat.date_format;
  const dateFormat = defaultDateFormatMap[defaultDateFormat];
  const shouldRenderTime = inputFormat.time_format !== 'Ignore Time';
  const shouldShowTimeMode = field.format.time_format === 'HH:MM am';

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: ControllerRenderProps['onChange']
  ) => {
    const { value } = e.target;

    // Only allow numbers and slashes
    if (!/^[0-9/]*$/.test(value)) {
      return;
    }
    onChange(value);
  };

  const getFormattedDate = (date: Date) => DateTime.fromJSDate(date).toFormat(dateFormat);

  const handleSelectDate = (
    date: Date | undefined,
    onChange: ControllerRenderProps['onChange']
  ) => {
    if (!date) {
      return;
    }
    onChange(getFormattedDate(date));
  };

  const getDefaultDate = () => DateTime.now().toFormat(dateFormat);

  const defaultValueTime = shouldShowTimeMode
    ? DateTime.now().toFormat('hh:mma').toLowerCase()
    : DateTime.now().toFormat('HH:mm');

  return (
    <div
      className={cn(
        'flex h-auto w-full items-center justify-between gap-1 border border-default bg-input p-1',
        {
          'rounded-lg': theme.appearance.corners === 'rounded'
        }
      )}
    >
      <Controller
        name={`${formFieldName}.date`}
        defaultValue={getDefaultDate()}
        render={({ field: { value, onChange } }) => {
          const formattedDate = getFormattedDatePickerDate(value, dateFormat) || getDefaultDate();
          return (
            <DatePicker
              selected={formattedDate}
              onSelect={(val) => handleSelectDate(val, onChange)}
              mode="single"
              triggerRef={inputRef}
              onDatePickerOpenChange={setIsDatePickerOpen}
              calendarProps={{
                mode: 'single',
                fromYear: DAY_PICKER_FROM_YEAR,
                toYear: DAY_PICKER_TO_YEAR
              }}
            >
              <Input.Container>
                <CalendarIcon
                  size={16}
                  className="absolute top-0 ml-2 flex h-full flex-col justify-center"
                />
                <Input
                  id={`${formFieldName}-filter-date-input`}
                  ref={inputRef}
                  value={value}
                  className="h-auto min-w-[120px] border-0 p-1 pl-7 focus:outline-0"
                  onChange={(e) => handleInputChange(e, onChange)}
                  onClick={(e) => {
                    if (isDatePickerOpen) {
                      e.stopPropagation();
                    }
                  }}
                />
              </Input.Container>
            </DatePicker>
          );
        }}
      />
      {shouldRenderTime && (
        <Controller
          name={`${formFieldName}.time`}
          defaultValue={defaultValueTime}
          render={({ field: { value, onChange } }) => {
            // value comes as 00:00am format
            const timeModeSelectValue = value.slice(-2);
            const timeValue = shouldShowTimeMode ? value.slice(0, -2) : value;

            return (
              <TimeInput
                value={timeValue}
                format={field.format.time_format}
                onValueChange={(val) => {
                  if (shouldShowTimeMode) {
                    onChange(`${val}${timeModeSelectValue}`);
                    return;
                  }
                  onChange(val);
                }}
              >
                {shouldShowTimeMode && (
                  <TimeInput.TimeModeSelect
                    value={timeModeSelectValue.toUpperCase()}
                    onValueChange={(val) => onChange(`${timeValue}${val.toLowerCase()}`)}
                  />
                )}
              </TimeInput>
            );
          }}
        />
      )}
    </div>
  );
}
