import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { type KnackFieldKey } from '@/types/schema/fields/KnackField';
import { type LiveAppTableKey } from '@/types/schema/LiveAppTable';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios, getAppBasedRequestHeaders } from '@/utils/axiosConfig';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { usePageContext } from '@/context/PageContext';
import { type FormattedViewRecord, type ViewRecord } from './useViewRecordQuery';

export type GeoOrigin = {
  latitude: number;
  longitude: number;
};

export interface ViewRecordsResponse {
  geo_origin?: GeoOrigin;
  records: ViewRecord[];
  total_pages: number;
  current_page: number;
  total_records: number;
  location_fail: boolean;
}

type ViewRecordsQueryOptions = {
  format?: 'both' | 'raw';
  rowsPerPage?: string | null;
  page?: number | null;
  search?: string;
  sortField?: KnackFieldKey;
  sortOrder?: 'asc' | 'desc';
  filters?: string | null;
};

type GetViewDataParams = {
  pageKey: string;
  viewKey: LiveAppViewKey;
  objectKey: LiveAppTableKey;
  options?: ViewRecordsQueryOptions;
  emptyResponse: boolean;
};

export type PagedViewRecords = {
  records: FormattedViewRecord[];
  totalRecords: number;
  totalPages: number;
  currentPage: number;
  isSearchTermValid: boolean;
  geoOrigin?: GeoOrigin;
};

export const formatData = (data: ViewRecordsResponse): PagedViewRecords => {
  if (data.location_fail) {
    return {
      records: [],
      totalRecords: 0,
      totalPages: 0,
      currentPage: 0,
      isSearchTermValid: false,
      geoOrigin: data.geo_origin
    };
  }

  const formattedRecords = data.records.map((record) => {
    const formattedRecord: { values: ViewRecord; rawValues: ViewRecord } = {
      values: {
        id: record.id
      },
      rawValues: {
        id: record.id
      }
    };

    Object.keys(record).forEach((key) => {
      if (key.endsWith('_raw')) {
        const newKey = key.replace('_raw', '');
        formattedRecord.rawValues[newKey] = record[key];

        // If the field is a phone field
        if (record[key]?.area) {
          formattedRecord.rawValues[newKey] = record[key].formatted;
        }

        // If the field is a timer, we need to format the data to match the expected in the frontend
        if (record[key]?.times) {
          [formattedRecord.rawValues[newKey]] = record[key].times;
        }
      } else if (key === 'geo_distance') {
        formattedRecord.values[key] = record[key];
        formattedRecord.rawValues[key] = record[key];
      } else {
        formattedRecord.values[key] = record[key];
      }
    });

    return formattedRecord;
  });

  return {
    records: formattedRecords,
    totalRecords: data.total_records,
    totalPages: data.total_pages,
    currentPage: data.current_page,
    isSearchTermValid: true,
    geoOrigin: data.geo_origin
  };
};

async function getViewData({
  pageKey,
  viewKey,
  objectKey,
  options,
  emptyResponse
}: GetViewDataParams) {
  if (emptyResponse) {
    return {
      records: [],
      totalRecords: 0,
      totalPages: 0,
      currentPage: 0,
      isSearchTermValid: true
    };
  }

  const { rowsPerPage, page, sortOrder, sortField, filters, ...restOptions } = options || {};
  const queryParams = new URLSearchParams({
    ...restOptions,
    ...(rowsPerPage && { rows_per_page: rowsPerPage }),
    ...(page && { page: page.toString() }),
    ...(sortOrder && { sort_order: sortOrder }),
    ...(sortField && { sort_field: sortField }),
    ...(filters && filters !== 'null' && { filters })
  } as Record<string, string>);

  // When iframed by the builder we need to fetch the records from the object endpoint so it returns all fields.
  // The exception in the builder is for the map view, where we need to fetch the records from the scene endpoint
  // which returns the geo_distance necessary to center the map according to the starting address location.
  const url = isInternalBuilderIframe()
    ? `v1/objects/${objectKey}/records`
    : `v1/scenes/${pageKey}/views/${viewKey}/records`;

  const { data: formData } = await axios.get<ViewRecordsResponse>(url, {
    params: queryParams,
    withCredentials: true,
    headers: getAppBasedRequestHeaders()
  });

  return formatData(formData);
}

export function useViewMultipleRecordsQuery({
  viewKey,
  objectKey,
  enabled = true,
  options,
  emptyResponse = false,
  searchTerm = ''
}: {
  viewKey: LiveAppViewKey;
  objectKey: LiveAppTableKey;
  enabled?: boolean;
  options?: ViewRecordsQueryOptions;
  emptyResponse?: boolean;
  searchTerm?: string;
}) {
  const { activePage } = usePageContext();

  if (!activePage) {
    throw new Error('useViewMultipleRecordsQuery must be used within a page context');
  }

  const optionEntries = Object.entries(options || {}).map(([key, value]) => `${key}-${value}`);
  const queryKey = [queryKeys.viewData, viewKey, ...optionEntries, searchTerm];

  return useQuery({
    queryKey,
    queryFn: async () =>
      getViewData({ pageKey: activePage.key, viewKey, objectKey, options, emptyResponse }),
    enabled,
    placeholderData: keepPreviousData
  });
}
