import type { ReactNode } from 'react';

import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';

export type MapDetailsItemsContainerProps = {
  detailsHeight: number;
  detailsWidth: number;
  isSmallVersion?: boolean;
};

export function MapDetailsItemsContainer({
  children,
  detailsHeight,
  detailsWidth,
  isSmallVersion
}: MapDetailsItemsContainerProps & { children: ReactNode }) {
  const { theme } = useThemingContext();

  return (
    <div
      className={cn('flex max-w-full flex-col items-center justify-center bg-default', {
        'rounded-lg': theme.appearance.corners === 'rounded'
      })}
      style={{
        width: isSmallVersion ? '100%' : detailsWidth,
        height: detailsHeight
      }}
    >
      {children}
    </div>
  );
}
