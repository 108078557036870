import { useTranslation } from 'react-i18next';
import { HiArrowDownTray as ExportIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';
import { Document, Image, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import * as htmlToImage from 'html-to-image';

import { type ReportViewChart } from '@/types/schema/views/ReportView';

interface ChartsPDFDocumentProps {
  chartImageSrc: string;
  title: string;
}

interface ChartExportButtonProps {
  chart: ReportViewChart;
  chartRef: React.RefObject<HTMLDivElement>;
}

function ChartsPDFDocument({ chartImageSrc, title }: ChartsPDFDocumentProps) {
  const styles = StyleSheet.create({
    section: {
      margin: 10,
      padding: 10
    },
    text: {
      textAlign: 'center'
    }
  });

  return (
    <Document>
      <Page size="A4">
        <View style={styles.section}>
          {title && <Text style={styles.text}>{title}</Text>}
          <Image src={chartImageSrc} />
        </View>
      </Page>
    </Document>
  );
}

export function ChartExportButton({ chart, chartRef }: ChartExportButtonProps) {
  const [t] = useTranslation();

  const handleExportChart = async () => {
    if (!chartRef.current) {
      return;
    }

    const response = await htmlToImage.toPng(chartRef.current);
    const blob = await pdf(
      <ChartsPDFDocument chartImageSrc={response} title={chart.title} />
    ).toBlob();
    const fileName = `${chart.title || 'chart'}.pdf`;
    saveAs(blob, fileName);
  };

  return (
    <Button className="mb-4" intent="secondary" onClick={handleExportChart}>
      <ExportIcon className="mr-2" size="16" />
      {t('actions.export')}
    </Button>
  );
}
