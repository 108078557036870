import { useEffect, useRef, useState } from 'react';
import { Spinner } from '@knack/asterisk-react';

import { type MapView } from '@/types/schema/views/MapView';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useViewMultipleRecordsQuery } from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { useContainerQuery } from '@/hooks/useContainerQuery';
import { useViewSearchParams } from '@/hooks/useViewSearchParams';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { MapDetailsItems } from '@/components/views/map/details/MapDetailsItems';
import { Map } from '@/components/views/map/engine/Map';
import { MapSearch } from '@/components/views/map/filters/MapSearch';
import {
  MapLocationProvider,
  useMapLocationContext
} from '@/components/views/map/MapLocationContext';
import { ViewToolbarSection } from '@/components/views/view-options/ViewToolbarSection';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';

function MapViewContent() {
  const { view, sourceTable } = useViewContext<MapView>();
  const [selectedRecord, setSelectedRecord] = useState<FormattedViewRecord>();
  const { data: application, isLoading: isLoadingApplication } = useApplicationQuery();
  const { rowsPerPage, currentPage, searchValueFromParams, allFilters, mapFilters } =
    useViewSearchParams(view);

  const {
    location,
    isLoading: isLoadingLocation,
    createNewMapFilters,
    range
  } = useMapLocationContext();

  const isIframedBuilder = isInternalBuilderIframe();

  let searchFilters = allFilters;
  if (isIframedBuilder && location) {
    searchFilters = createNewMapFilters({
      location,
      range,
      activeViewFilters: allFilters,
      addressFieldKey: view.address_field.key
    });
  }

  const { data: pagedViewRecords, isFetching: isFetchingRecords } = useViewMultipleRecordsQuery({
    searchTerm: location,
    emptyResponse: !isIframedBuilder && (location === '' || !mapFilters),
    viewKey: view.key,
    objectKey: view.source.object,
    options: {
      rowsPerPage,
      page: currentPage,
      ...(searchValueFromParams && { search: searchValueFromParams }),
      ...(searchFilters && { filters: JSON.stringify(searchFilters) })
    }
  });

  useEffect(() => {
    setSelectedRecord(undefined);
  }, [pagedViewRecords]);

  const containerRef = useRef(null);
  const isSmallVersion = useContainerQuery(containerRef, { maxWidth: 750 });

  const isFiltered = !!location || !!(view.starting_point === 'blank' && allFilters);
  const isSearchTermValid = !!pagedViewRecords?.isSearchTermValid;
  const isEmptyState = !isFiltered;
  const isLoading = isFetchingRecords || isLoadingLocation;

  return (
    <div ref={containerRef} className="relative w-full">
      {(isLoadingApplication || isLoading || !application) && (
        <div className="absolute z-10 flex size-full bg-card bg-opacity-60">
          <div className="flex size-full items-center justify-center">
            <Spinner />
          </div>
        </div>
      )}
      <div>
        <ViewHeaderSection view={view} className="mb-6" />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <MapSearch />
            <div className="flex">
              <ViewToolbarSection />
            </div>
          </div>

          <div className={cn('flex w-full gap-4', isSmallVersion && 'flex-wrap')}>
            <Map
              formattedViewRecords={pagedViewRecords?.records}
              selectedRecord={selectedRecord}
              onSelectRecord={(r) => setSelectedRecord(r)}
              onUnselectRecord={() => setSelectedRecord(undefined)}
              mapHeight={view.map_height}
              addressFieldKey={view.address_field.key}
              titleFieldKey={view.title_field.key}
              pinColorDefault={view.pin_color_default}
              pinColors={view.pin_colors}
              fields={sourceTable.fields}
              isSmallVersion={isSmallVersion}
              isEmptyState={isEmptyState}
              isLoading={isLoading}
              isSearchTermValid={isSearchTermValid}
              searchTerm={location}
              searchCoordinates={pagedViewRecords?.geoOrigin}
            />
            <MapDetailsItems
              pagedViewRecords={pagedViewRecords}
              selectedRecord={selectedRecord}
              onSelectRecord={(r) => setSelectedRecord(r)}
              columns={view.details.columns}
              detailsWidth={view.list_width}
              detailsHeight={view.map_height}
              isSmallVersion={isSmallVersion}
              isEmptyState={isEmptyState}
              searchTerm={location}
              isLoading={isLoading}
              noDataText={view.no_data_text}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function MapViewRender() {
  return (
    <MapLocationProvider>
      <MapViewContent />
    </MapLocationProvider>
  );
}
