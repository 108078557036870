import { max, mean, min, sum } from 'mathjs';

import { type CurrencyField } from '@/types/schema/fields';
import {
  type KnackField,
  type KnackFieldKey,
  type KnackFieldType
} from '@/types/schema/fields/KnackField';
import { type TableViewColumn, type TableViewTotalSummary } from '@/types/schema/views/TableView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';

const DEFAULT_COLUMN_WIDTH = 50;

interface TableRecord {
  rawValues: Record<string, any>;
  values: Record<string, any>;
}

type SummariesValues = Record<TableViewTotalSummary['calc'], number>;

export interface ColumnSummariesValues {
  [key: KnackFieldKey]: SummariesValues;
}

const fieldTypesAllowedForSummary: KnackFieldType[] = [
  'number',
  'currency',
  'min',
  'max',
  'sum',
  'average',
  'count',
  'rating'
];

interface GetInlineEditInputParams {
  selectedField: KnackField;
}

export const getInlineEditInput = ({ selectedField }: GetInlineEditInputParams) => ({
  id: selectedField.key,
  type: selectedField.type,
  field: {
    key: selectedField.key
  },
  instructions: '',
  label: '',
  format: selectedField.format
});

export function calculateSummaries(records: TableRecord[], sourceTableFields: KnackField[]) {
  const numericColumnValues: { [key: string]: number[] } = {};

  records.forEach((record) => {
    Object.entries(record.rawValues).forEach(([key, value]) => {
      const sourceTableField = sourceTableFields.find((field) => field.key === key);
      if (!sourceTableField) return;

      if (fieldTypesAllowedForSummary.includes(sourceTableField.type)) {
        if (!numericColumnValues[key]) {
          numericColumnValues[key] = [];
        }
        if (sourceTableField.type === 'currency') {
          const formattedValue = value.replace(/[^0-9.-]+/g, '');
          if (formattedValue === '') return;

          numericColumnValues[key].push(Number(formattedValue));
          return;
        }

        numericColumnValues[key].push(value);
      }
    });
  });

  return Object.entries(numericColumnValues).reduce((result, [key, values]) => {
    if (values.length === 0) return result;

    return {
      ...result,
      [key]: {
        sum: sum(values),
        average: mean(values),
        min: min(values),
        max: max(values)
      }
    };
  }, {} as ColumnSummariesValues);
}

export const getSummaryValue = (
  summariesValues: ColumnSummariesValues,
  fieldKey: KnackFieldKey,
  summaryType: TableViewTotalSummary['calc']
) => {
  if (!summariesValues || !summariesValues[fieldKey]) return '';

  switch (summaryType) {
    case 'sum':
      return summariesValues[fieldKey].sum;
    case 'average':
      return summariesValues[fieldKey].average;
    case 'min':
      return summariesValues[fieldKey].min;
    case 'max':
      return summariesValues[fieldKey].max;
    default:
      return '';
  }
};

export const formatCurrencyValueForSummary = (
  value: number,
  format: CurrencyField['format'] | undefined
) => {
  if (!format) return value;
  const currencySymbols = {
    '€': 'EUR',
    '£': 'GBP',
    $: 'USD'
  } as const;

  if (format.format === '€_after') return `${value}€`;
  // At this moment we only support en-US currency formatting in Currency Fields
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: currencySymbols[format.format]
  });
};

export function hasExceededMaxLines(div: HTMLElement, maxLines = 2) {
  if (!div) return false;

  // Create a temporary div to measure a single line height
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.visibility = 'hidden';
  tempDiv.style.height = 'auto';
  tempDiv.style.width = `${div.clientWidth}px`;
  tempDiv.style.fontSize = window.getComputedStyle(div).fontSize;
  tempDiv.style.lineHeight = window.getComputedStyle(div).lineHeight;
  tempDiv.innerText = 'A'; // A single character for measurement

  document.body.appendChild(tempDiv);
  const singleLineHeight = tempDiv.clientHeight;
  document.body.removeChild(tempDiv);

  // Get the height of the original div
  const divHeight = div.clientHeight;

  // Compare the height of the div to twice the height of a single line
  return divHeight > singleLineHeight * maxLines;
}

export function calculateCharactersInWidth(maxWidthPx: number) {
  const fontSizePx = 14;
  // Approximate average character width in em for most common fonts
  const avgCharWidthEm = 0.5;

  // Calculate average character width in pixels
  const avgCharWidthPx = avgCharWidthEm * fontSizePx;

  // Calculate the number of characters that can fit in the given width
  const numChars = Math.floor(maxWidthPx / avgCharWidthPx);

  return numChars;
}

export const getColumnStyles = (column: TableViewColumn): React.CSSProperties | null => {
  let width: string | number = DEFAULT_COLUMN_WIDTH;

  if (column.width && column.width.type === 'custom') {
    width = `${column.width.amount}${column.width.units === 'px' ? 'px' : '%'}`;
  }

  return { width };
};

export const getEmptyColumns = (records: FormattedViewRecord[]) => {
  const keyMap: Record<KnackFieldKey, boolean> = {};

  records.forEach((record) => {
    Object.entries(record.values).forEach(([key, value]) => {
      if (!(key in keyMap)) {
        keyMap[key as KnackFieldKey] = true;
      }
      if (value) {
        keyMap[key as KnackFieldKey] = false;
      }
    });
  });

  return Object.keys(keyMap).filter((key) => keyMap[key as KnackFieldKey]) as KnackFieldKey[];
};
