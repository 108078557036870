import { useTranslation } from 'react-i18next';
import { MdHourglassTop as SandClockIcon } from 'react-icons/md';
import { Banner, type BannerProps } from '@knack/asterisk-react';

export function PendingApprovalBanner(bannerProps: BannerProps) {
  const [t] = useTranslation();

  return (
    <Banner
      intent="warning"
      icon={SandClockIcon}
      title={t('components.views.login.errors.pending_approval')}
      {...bannerProps}
    >
      <Banner.Message>
        {t('components.views.login.errors.pending_approval_description')}
      </Banner.Message>
    </Banner>
  );
}
