import { useEffect, useRef } from 'react';
import isNil from 'lodash/isNil';
import { LngLatBounds, Map, Marker, NavigationControl, Popup } from 'maplibre-gl';

import 'maplibre-gl/dist/maplibre-gl.css';

import { renderToString } from 'react-dom/server';

import { type KnackField } from '@/types/schema/fields/KnackField';
import { type MapPinColor } from '@/types/schema/views/MapView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isSomeCriteriaMet } from '@/utils/criteriaRules';
import { cn } from '@/utils/tailwind';
import { MapPopup } from '@/components/views/map/engine/MapPopup';
import { MAP_DEFAULT_COORDINATES } from '@/components/views/map/engine/providers/constants';
import { useThemingContext } from '@/context/ThemingContext';

type MapLibreMapProps = {
  formattedViewRecords: FormattedViewRecord[];
  selectedRecord?: FormattedViewRecord;
  addressFieldKey: string;
  titleFieldKey?: string;
  pinColorDefault: string;
  pinColors: MapPinColor[];
  fields: KnackField[];
  onSelectRecord?: (record: FormattedViewRecord) => void;
  onUnselectRecord?: () => void;
  defaultAddress?: { latitude: number; longitude: number };
};

function renderPins({
  map,
  formattedViewRecords: records,
  addressFieldKey,
  titleFieldKey,
  selectedRecord,
  pinColorDefault,
  pinColors,
  fields,
  onSelectRecord
}: MapLibreMapProps & { map: Map }): Record<string, Marker> {
  const pins: Record<string, Marker> = {};
  const bounds = new LngLatBounds();

  records.forEach((record) => {
    if (!record.values.id) return;

    const address = record.rawValues[addressFieldKey];

    if (isNil(address) || isNil(address.latitude) || isNil(address.longitude)) return;

    let pinColor = pinColorDefault;
    isSomeCriteriaMet(record.rawValues, fields, pinColors, ({ criteria }) => {
      pinColor = criteria.color;
    });

    const popup = new Popup({ offset: 25, focusAfterOpen: false }).setHTML(
      renderToString(
        <MapPopup record={record} addressFieldKey={addressFieldKey} titleFieldKey={titleFieldKey} />
      )
    );
    const marker = new Marker({ color: pinColor }).setLngLat([address.longitude, address.latitude]);
    marker.setPopup(popup);
    marker.getElement().addEventListener('click', (e) => {
      e.stopPropagation();
      marker.togglePopup();
      if (onSelectRecord) onSelectRecord(record);
    });
    pins[record.values.id] = marker;

    bounds.extend([address.longitude, address.latitude]);

    marker.addTo(map);
  });

  if (!bounds.isEmpty() && !selectedRecord) {
    map.fitBounds(bounds, { padding: 100, maxZoom: 13, animate: true, speed: 10 });
  }

  return pins;
}

export function MapLibreMap({
  formattedViewRecords,
  selectedRecord,
  addressFieldKey,
  titleFieldKey,
  pinColorDefault,
  pinColors,
  fields,
  onSelectRecord,
  onUnselectRecord,
  defaultAddress
}: MapLibreMapProps) {
  const { theme } = useThemingContext();
  const mapContainer = useRef<HTMLDivElement>(null);
  const mapRef = useRef<Map | null>(null);
  const pinsRef = useRef<Record<string, Marker>>({});
  const previousPinRef = useRef<Marker | undefined>();

  useEffect(() => {
    if (mapRef.current || !mapContainer.current) return undefined;

    const apiKey = import.meta.env.PUBLIC_MAPTILER_API_KEY;
    mapRef.current = new Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${apiKey}`,
      center: [
        defaultAddress?.longitude || MAP_DEFAULT_COORDINATES.longitude,
        defaultAddress?.latitude || MAP_DEFAULT_COORDINATES.latitude
      ],
      zoom: 13
    });
    mapRef.current.addControl(new NavigationControl(), 'top-right');

    return () => {
      Object.values(pinsRef.current).forEach((pin) => pin.remove());
      pinsRef.current = {};
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [defaultAddress]);

  useEffect(() => {
    if (!mapRef.current) return;

    Object.values(pinsRef.current).forEach((pin) => pin.remove());
    previousPinRef.current = undefined;

    pinsRef.current = renderPins({
      map: mapRef.current,
      formattedViewRecords,
      selectedRecord,
      addressFieldKey,
      titleFieldKey,
      pinColorDefault,
      pinColors,
      fields,
      onSelectRecord
    });

    mapRef.current.on('click', () => {
      previousPinRef.current?.togglePopup();
      previousPinRef.current = undefined;
      if (onUnselectRecord) onUnselectRecord();
    });
  }, [
    formattedViewRecords,
    addressFieldKey,
    titleFieldKey,
    pinColorDefault,
    pinColors,
    fields,
    selectedRecord,
    onSelectRecord,
    onUnselectRecord
  ]);

  useEffect(() => {
    if (!mapRef.current || !selectedRecord || !selectedRecord.values.id) return;

    const selectedPin = pinsRef.current[selectedRecord.values.id];
    if (selectedPin) {
      selectedPin.togglePopup();
      mapRef.current.panTo(selectedPin.getLngLat());
      if (previousPinRef.current) {
        previousPinRef.current.togglePopup();
      }
      previousPinRef.current = selectedPin;
    }
  }, [selectedRecord]);

  return (
    <div
      ref={mapContainer}
      className={cn('size-full', {
        'rounded-lg': theme.appearance.corners === 'rounded'
      })}
    />
  );
}
