import { useTranslation } from 'react-i18next';

import type { FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';

function getDirectionsLink(address: any): string | null {
  if (!address) return null;

  let link = 'https://maps.google.com/maps?daddr=';
  if (address.street) {
    link += address.street;
  }
  if (address.street2) {
    link += ` ${address.street2}`;
  }
  if (address.city) {
    link += ` ${address.city},`;
  }
  if (address.state) {
    link += ` ${address.state}`;
  }
  if (address.zip) {
    link += ` ${address.zip}`;
  }
  if (!link) return null;

  link += '&directionsmode=driving';
  return link;
}

export function MapPopup({
  record,
  addressFieldKey,
  titleFieldKey
}: {
  record: FormattedViewRecord;
  addressFieldKey: string;
  titleFieldKey?: string;
}) {
  const [t] = useTranslation();
  const title = titleFieldKey ? record.values[titleFieldKey] : '';
  const address = record.rawValues[addressFieldKey];
  const directionsLink = getDirectionsLink(address);
  const addressValue = record.values[addressFieldKey];
  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-base font-bold" dangerouslySetInnerHTML={{ __html: title }} />
      <p className="text-sm" dangerouslySetInnerHTML={{ __html: addressValue }} />
      {directionsLink && (
        <p>
          <a
            href={directionsLink}
            target="_blank"
            className="underline hover:text-blue-500"
            rel="noreferrer"
          >
            {t('components.views.map.directions')}
          </a>
        </p>
      )}
    </div>
  );
}
