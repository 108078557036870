import { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useCredentialsQuery } from '@/hooks/api/queries/useCredentialsQuery';

let googleApiPromise: Promise<void> | null = null;

export function useGoogleApi() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { data: application, isLoading: isLoadingApplication } = useApplicationQuery();

  const { data: credential, isLoading: isLoadingCredentials } = useCredentialsQuery({
    credential: application?.settings.googleMapsApiKey || '',
    enabled: !!application?.settings.googleMapsApiKey
  });

  const isLoadingApplicationOrCredentials = isLoadingApplication || isLoadingCredentials;

  useEffect(() => {
    if (isLoadingApplicationOrCredentials) {
      return;
    }

    if (!googleApiPromise) {
      const loader = new Loader({
        apiKey: credential?.key || import.meta.env.PUBLIC_GOOGLE_MAPS_KEY,
        libraries: ['core', 'marker', 'places'],
        id: 'google-maps-script'
      });

      googleApiPromise = loader
        .load()
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          googleApiPromise = null;
        });
    } else {
      googleApiPromise
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [isLoadingApplicationOrCredentials, credential?.key]);

  return { isLoading, error };
}
