import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type Session } from '@/types/session';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

export interface SignInMutationParams {
  email: string;
  password: string;
  remember?: boolean;
}

export async function signIn({ email, password, remember }: SignInMutationParams) {
  const { data } = await axios.post<{ session: Session }>(
    '/v1/live-app/session',
    {
      email,
      password,
      remember
    },
    {
      withCredentials: true
    }
  );

  return data.session;
}

export function useSignInMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ email, password, remember }: SignInMutationParams) =>
      signIn({ email, password, remember }),
    onSuccess: (session) => {
      queryClient.setQueryData([queryKeys.auth.session], session);
      void queryClient.invalidateQueries({ queryKey: [queryKeys.entryPages] });
    }
  });
}
