import { forwardRef, useEffect, useRef, type MutableRefObject } from 'react';
import { Input } from '@knack/asterisk-react';
import { type InputProps } from '@knack/asterisk-react/dist/src/components/input';

import { useGoogleApi } from '@/hooks/useGoogleApi';
import type { GoogleMapsPlace } from '@/components/views/form/inputs/address/types';

type AddressEditInputProps = InputProps & {
  onPlaceSelected?: (place: GoogleMapsPlace) => void;
  types?: Array<'address'>;
};

export const AddressGoogleAutocompleteInput = forwardRef<HTMLInputElement, AddressEditInputProps>(
  ({ onPlaceSelected, types = [], ...props }, parentRef) => {
    const { isLoading: isLoadingGoogleApi } = useGoogleApi();
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (isLoadingGoogleApi || !inputRef.current) return undefined;

      const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
        types: types.length === 0 ? ['geocode'] : types,
        fields: ['address_components']
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (onPlaceSelected) onPlaceSelected(place as GoogleMapsPlace);
      });

      return () => {
        google.maps.event.clearInstanceListeners(autocomplete);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingGoogleApi]);

    return (
      <Input
        ref={(el) => {
          const streetRef = inputRef as MutableRefObject<HTMLInputElement | null>;
          streetRef.current = el;
          if (typeof parentRef === 'function') {
            parentRef(el);
          } else if (parentRef) {
            const ref = parentRef as MutableRefObject<HTMLInputElement | null>;
            ref.current = el;
          }
        }}
        {...props}
      />
    );
  }
);

AddressGoogleAutocompleteInput.displayName = 'AddressGoogleAutocompleteInput';
