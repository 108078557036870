import { type UniqueIdentifier } from '@dnd-kit/core';

import { type MenuViewLink } from '@/types/schema/views/MenuView';
import { MenuLinkEditor } from './MenuLinkEditor';

interface DragOverlayContentProps {
  links: MenuViewLink[];
  beingDraggedLinkId: UniqueIdentifier | null;
}
export function MenuLinkDragOverlay({ links, beingDraggedLinkId }: DragOverlayContentProps) {
  const link = links.find((l) => l.id === beingDraggedLinkId);

  if (!link) {
    return null;
  }

  return <MenuLinkEditor link={link} isDragOverlay />;
}
