import type { Active, Over } from '@dnd-kit/core';
import { useDndContext } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { type ReportViewChart } from '@/types/schema/views/ReportView';
import { cn } from '@/utils/tailwind';
import { ChartEditor } from './ChartEditor';
import { useSortableChartContext } from './SortableChartContext';

export function getLinkInsertPosition(
  active: Active | null,
  over: Over | null,
  chart: ReportViewChart
) {
  if (over?.id === chart.id && active?.id !== chart.id) {
    const overIndex: number = over.data.current?.sortable.index ?? -1;
    const activeIndex: number = active?.data.current?.sortable.index ?? -1;

    return overIndex > activeIndex ? 'after' : 'before';
  }

  return null;
}

export function SortableChart() {
  const dndContext = useDndContext();

  const { chart } = useSortableChartContext();

  const { attributes, setNodeRef, transform, transition, isSorting } = useSortable({
    id: chart.id,
    data: {
      type: 'chart'
    }
  });

  const style = {
    transition,
    transform: isSorting ? undefined : CSS.Translate.toString(transform)
  };

  const insertPositionState = getLinkInsertPosition(dndContext.active, dndContext.over, chart);

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={cn({
        flex: insertPositionState !== null
      })}
    >
      <ChartEditor draggingOverInsertPosition={insertPositionState} />
    </div>
  );
}
