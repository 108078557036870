import { useTranslation } from 'react-i18next';
import { Divider } from '@knack/asterisk-react';

import type { ListViewGroupColumn } from '@/types/schema/views/ListView';
import type { PagedViewRecords } from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import type { FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { DetailsItem } from '@/components/views/details/DetailsItem';
import { MapDetailsEmptyState } from '@/components/views/map/details/MapDetailsEmptyState';
import { MapDetailsItemContainer } from '@/components/views/map/details/MapDetailsItemContainer';
import { ViewFooterSection } from '@/components/views/ViewFooterSection';
import { useThemingContext } from '@/context/ThemingContext';

export type MapDetailsItemsContentProps = {
  pagedViewRecords?: PagedViewRecords;
  selectedRecord?: FormattedViewRecord;
  columns: ListViewGroupColumn[];
  onSelectRecord?: (record: FormattedViewRecord) => void;
  isEmptyState: boolean;
  isSmallVersion: boolean;
  isLoading: boolean;
  searchTerm?: string;
  noDataText?: string;
};

export function MapDetailsItemsContent({
  pagedViewRecords,
  selectedRecord,
  columns,
  onSelectRecord,
  isEmptyState,
  searchTerm,
  isLoading,
  isSmallVersion,
  noDataText
}: MapDetailsItemsContentProps) {
  const [t] = useTranslation();
  const { theme } = useThemingContext();

  const records = pagedViewRecords?.records || [];

  if (records.length === 0 && (isEmptyState || !searchTerm)) {
    return (
      <MapDetailsEmptyState>
        <p>{t('components.views.map.enter_search_term')}</p>
      </MapDetailsEmptyState>
    );
  }
  if (isLoading && records.length === 0) {
    return (
      <MapDetailsEmptyState>
        <p>{t('components.views.map.searching')}</p>
      </MapDetailsEmptyState>
    );
  }
  if (searchTerm && records.length === 0) {
    return (
      <MapDetailsEmptyState>
        <p>{noDataText || t('components.views.map.errors.no_results')}</p>
        <p>{searchTerm}</p>
        <p className="mt-2 font-light">{t('components.views.map.change_search')}</p>
      </MapDetailsEmptyState>
    );
  }

  return (
    <>
      <div
        className={cn('flex size-full flex-col overflow-auto', {
          'rounded-t-lg': theme.appearance.corners === 'rounded'
        })}
      >
        {records.map((record, recordIndex) => (
          <MapDetailsItemContainer
            key={record.values.id}
            record={record}
            selectedRecord={selectedRecord}
            onClick={onSelectRecord}
          >
            {recordIndex !== 0 && <Divider />}
            {record.rawValues.geo_distance && (
              <div className="flex justify-end px-3 pt-3">
                <span
                  className={cn('bg-subtle p-1 text-right text-sm text-default', {
                    'rounded-md': theme.appearance.corners === 'rounded'
                  })}
                >
                  {record.rawValues.geo_distance}
                </span>
              </div>
            )}
            <DetailsItem columns={columns} record={record} isSmallVersion={isSmallVersion} />
          </MapDetailsItemContainer>
        ))}
      </div>

      <Divider />
      <ViewFooterSection
        totalPageNumber={pagedViewRecords?.totalPages || 0}
        totalRecords={pagedViewRecords?.totalRecords}
        isSmallVersion={isSmallVersion}
      />
    </>
  );
}
