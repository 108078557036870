import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiTrash as DeleteIcon, HiPencil as EditIcon } from 'react-icons/hi2';
import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';
import {
  autoUpdate,
  FloatingPortal,
  offset,
  safePolygon,
  useFloating,
  useHover,
  useInteractions
} from '@floating-ui/react';

import { type ReportView } from '@/types/schema/views/ReportView';
import { cn } from '@/utils/tailwind';
import { ChartRender } from '@/components/views/report/ChartRender';
import { useViewContext } from '@/components/views/ViewContext';
import { DragInsertIndicator } from '@/pages/page/page-editor/page-editor-sortable/DragInsertIndicator';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import {
  useSectionEditorContext,
  type ViewInputHoverIdentifier
} from '@/pages/page/page-editor/SectionEditorContext';
import { useSortableChartContext } from './SortableChartContext';

interface ChartEditorProps {
  draggingOverInsertPosition?: 'before' | 'after' | null;
  isDragOverlay?: boolean;
}

export function ChartEditor({ draggingOverInsertPosition, isDragOverlay }: ChartEditorProps) {
  const [t] = useTranslation();

  const { view } = useViewContext<ReportView>();
  const { isDraggingActive, startEditViewInput, updatePage } = usePageEditorContext();
  const { setViewInputHover, currentHoveredViewInput } = useSectionEditorContext();
  const { chart, chartData } = useSortableChartContext();

  const { listeners, setActivatorNodeRef, isDragging } = useSortable({
    id: chart.id
  });

  const viewInputHoverIdentifier: ViewInputHoverIdentifier = `${view.key}:${chart.id}`;
  const isChartHovered = currentHoveredViewInput === viewInputHoverIdentifier;
  const isChartActiveState = isChartHovered || isDragOverlay;

  const {
    refs,
    floatingStyles,
    elements,
    context: floatingContext
  } = useFloating({
    open: isChartActiveState,
    onOpenChange: (open) => {
      if (open) {
        setViewInputHover(viewInputHoverIdentifier);
      } else {
        setViewInputHover(null);
      }
    },
    placement: 'bottom-start',
    middleware: [offset(({ rects }) => -rects.floating.height)],
    whileElementsMounted(referenceEl, floatingEl, update) {
      const cleanup = autoUpdate(referenceEl, floatingEl, update, {
        animationFrame: true
      });
      return cleanup;
    }
  });
  const hover = useHover(floatingContext, {
    handleClose: safePolygon({
      blockPointerEvents: true
    }),
    enabled: !isDraggingActive
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  function onChartDelete(event: React.MouseEvent) {
    event.stopPropagation();
    updatePage({
      type: 'view',
      origin: 'live-app',
      action: 'update',
      updatedView: {
        ...view,
        rows: [
          {
            ...view.rows[0],
            reports: view.rows[0].reports.filter((c) => c.id !== chart.id)
          }
        ]
      }
    });
  }

  function onChartClick(event: React.MouseEvent) {
    // We want to ensure that the click event originated from within the link and not from some other element that propagated the event back to the link.
    // For instance, when rendering a child form view in a modal, clicking on the modal backdrop should not trigger the click event on this connection input.
    if (event.target !== event.currentTarget) {
      return;
    }

    event.stopPropagation();
    startEditViewInput({ viewKey: view.key, viewInputId: chart.id });
  }

  function onChartKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    event.preventDefault();

    if (event.key === 'Enter' || event.key === 'Space') {
      startEditViewInput({ viewKey: view.key, viewInputId: chart.id });
    }
  }

  // Reset input hover state when component unmounts
  useEffect(
    () => () => {
      setViewInputHover(null);
    },
    [setViewInputHover]
  );

  return (
    <>
      <DragInsertIndicator
        direction="vertical"
        className="z-20 -ml-[6px] mr-[2px]"
        isVisible={draggingOverInsertPosition === 'before'}
      />

      <div
        ref={refs.setReference}
        className={cn({
          'opacity-20': isDragging,
          'bg-card': isDragOverlay,
          'pointer-events-none': isDraggingActive
        })}
        {...getReferenceProps()}
      >
        <ChartRender className="pointer-events-none" chart={chart} chartData={chartData} />
      </div>

      {isChartActiveState && !isDragging && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            className="relative flex flex-col items-start"
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div
              role="button"
              tabIndex={0}
              onClick={onChartClick}
              onKeyDown={onChartKeyDown}
              className="relative rounded-lg border border-blue-500 after:absolute after:inset-0 after:bg-blue-500/10"
              style={{
                width: elements.domReference?.clientWidth,
                height: elements.domReference?.clientHeight
              }}
            >
              <span className="sr-only">{t('components.page_editor.editable_element')}</span>
            </div>
            <div className="absolute left-0 top-0 rounded-br-lg rounded-tl-lg border border-blue-500 bg-blue-500">
              <div className="flex h-6 items-center px-1 text-sm text-white">
                <button
                  type="button"
                  aria-label={t('actions.drag')}
                  className="mr-1 inline-flex size-5 cursor-move items-center justify-center rounded-md text-white hover:bg-white/25"
                  ref={setActivatorNodeRef}
                  {...listeners}
                >
                  <DragIcon size={16} />
                </button>
                <button
                  type="button"
                  aria-label={t('actions.edit')}
                  className="mr-1 inline-flex size-5 items-center justify-center rounded-md text-white hover:bg-white/25"
                  onClick={() => startEditViewInput({ viewKey: view.key, viewInputId: chart.id })}
                >
                  <EditIcon size={14} />
                </button>
                <button
                  type="button"
                  aria-label={t('actions.delete')}
                  className="inline-flex size-5 items-center justify-center rounded-md text-white hover:bg-white/25"
                  onClick={onChartDelete}
                >
                  <DeleteIcon size={14} />
                </button>
              </div>
            </div>
          </div>
        </FloatingPortal>
      )}

      <DragInsertIndicator
        direction="vertical"
        className="z-20 -mr-[6px] ml-[2px]"
        isVisible={draggingOverInsertPosition === 'after'}
      />
    </>
  );
}
