import { type TableView } from '@/types/schema/views/TableView';

export const tableViewDemoView: TableView = {
  id: '66b2314eceffd900ad8e0f7d',
  links: [],
  inputs: [],
  groups: [],
  no_data_text: 'No data',
  totals: [],
  preset_filters: [],
  hide_empty: false,
  table_design_active: false,
  keyword_search_fields: 'view',
  name: 'Demo Table',
  type: 'table',
  source: {
    criteria: {
      match: 'all',
      rules: [],
      groups: []
    },
    limit: null,
    sort: [
      {
        field: 'field_1',
        order: 'asc'
      }
    ],
    object: 'object_1'
  },
  title: '',
  description: '',
  rows_per_page: '25',
  keyword_search: true,
  allow_exporting: false,
  allow_preset_filters: false,
  filter_type: 'fields',
  menu_filters: [],
  filter_fields: 'view',
  key: 'view_42',
  allow_limit: false,
  design: undefined,
  options: {
    cell_editor: undefined
  },
  columns: [
    {
      grouping: false,
      group_sort: 'asc',
      ignore_edit: false,
      ignore_summary: false,
      conn_separator: '',
      conn_link: '',
      link_type: 'text',
      link_text: '',
      link_field: '',
      link_design_active: false,
      icon: {
        icon: '',
        align: 'left'
      },
      img_gallery: '',
      width: {
        type: 'default',
        units: 'px',
        amount: '50'
      },
      align: 'left',
      rules: [],
      field: {
        key: 'field_1'
      },
      id: 'field_1',
      header: 'Column 1',
      type: 'field'
    },
    {
      grouping: false,
      group_sort: 'asc',
      ignore_edit: false,
      ignore_summary: false,
      conn_separator: '',
      conn_link: '',
      link_type: 'text',
      link_text: '',
      link_field: '',
      link_design_active: false,
      icon: {
        icon: '',
        align: 'left'
      },
      img_gallery: '',
      width: {
        type: 'default',
        units: 'px',
        amount: '50'
      },
      align: 'left',
      rules: [],
      field: {
        key: 'field_2'
      },
      id: 'field_2',
      header: 'Column 2',
      type: 'field'
    }
  ]
};
