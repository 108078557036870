import { useTranslation } from 'react-i18next';
import { Button, Input, Select, Tabs } from '@knack/asterisk-react';

import { PaginationDemo, TabsDemo } from './colorsDemoData';
import { ColorGroup, ExampleGroup, Grid } from './utils';

export function ColorsBrand() {
  const [t] = useTranslation();
  const tabItems = [
    {
      children: <span>Active Example Page</span>,
      value: 'active'
    },
    {
      children: <span>Example Page</span>,
      value: 'example1'
    },
    {
      children: <span>Example Page</span>,
      value: 'example2'
    }
  ];

  return (
    <Grid title={t('components.page_editor.demo_page.brand')}>
      <ColorGroup
        color="bg-brand-accent"
        name="Primary/Brand"
        description="Used in primary buttons, focus state rings, brand text, and active navigation states"
      >
        <ExampleGroup group="Buttons">
          <Button intent="primary">Primary Button</Button>
          <Button intent="primary" disabled>
            Disabled Button
          </Button>
        </ExampleGroup>
        <ExampleGroup group="Navigation">
          <div className="w-full">
            <Tabs defaultValue="active">
              <Tabs.List intent="page" items={tabItems} className="mb-0" />
            </Tabs>
          </div>
        </ExampleGroup>
        <ExampleGroup group="Focus State">
          <div className="flex flex-col">
            <Input className="min-w-60" placeholder="Example Text Input" autoFocus />
            <span className="mt-2 text-xs text-muted">Click to see branded focus ring</span>
          </div>
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="bg-brand-subtle"
        name="Secondary"
        description="Used in selected states such as button navigations, selected menu items, and pagination"
      >
        <ExampleGroup group="Button Tabs">
          <TabsDemo />
        </ExampleGroup>
        <ExampleGroup group="Pagination">
          <PaginationDemo />
        </ExampleGroup>
        <ExampleGroup group="Menu">
          <Select>
            <Select.Trigger placeholder="Open to see menu states" className="min-w-60" />
            <Select.Content>
              <Select.Item value="light">Light</Select.Item>
              <Select.Item value="dark">Dark</Select.Item>
              <Select.Item value="system">System</Select.Item>
            </Select.Content>
          </Select>
        </ExampleGroup>
      </ColorGroup>
      <ColorGroup
        color="bg-brand-muted"
        name="Minimal"
        description="Hover over button navigations, selected menu items, and pagination to see where the minimal color is used"
      >
        <ExampleGroup group="Button">
          <TabsDemo />
        </ExampleGroup>
        <ExampleGroup group="Pagination">
          <PaginationDemo />
        </ExampleGroup>
        <ExampleGroup group="Menu">
          <Select>
            <Select.Trigger placeholder="Open to see hover states" className="min-w-60" />
            <Select.Content>
              <Select.Item value="light">Light</Select.Item>
              <Select.Item value="dark">Dark</Select.Item>
              <Select.Item value="system">System</Select.Item>
            </Select.Content>
          </Select>
        </ExampleGroup>
      </ColorGroup>
    </Grid>
  );
}
