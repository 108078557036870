import { useTranslation } from 'react-i18next';

import { type MenuView } from '@/types/schema/views/MenuView';
import { isPageEditor } from '@/utils/iframe';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { MenuLinksSortableWrapper } from './menu-links-sortable/MenuLinksSortableWrapper';
import { MenuLink } from './MenuLink';

export function MenuViewRender() {
  const [t] = useTranslation();

  const { view } = useViewContext<MenuView>();

  if (view.links.length === 0) {
    return (
      <div>
        <ViewHeaderSection view={view} className="mb-4" />
        {isPageEditor() && (
          <div className="py-4 text-subtle">{t('components.views.menu.empty_menu')}</div>
        )}
      </div>
    );
  }

  const linksContainerClasses =
    view.format === 'tabs'
      ? 'relative flex flex-wrap gap-y-2 after:absolute after:bottom-[1px] after:h-[2px] after:w-full after:bg-emphasis'
      : 'flex flex-wrap gap-2';

  return (
    <div>
      <ViewHeaderSection view={view} className="mb-4" />

      <div className={linksContainerClasses}>
        {isPageEditor() ? (
          <MenuLinksSortableWrapper view={view}>
            {view.links.map((link) => (
              <MenuLink key={link.id} link={link} view={view} />
            ))}
          </MenuLinksSortableWrapper>
        ) : (
          view.links.map((link) => <MenuLink key={link.id} link={link} view={view} />)
        )}
      </div>
    </div>
  );
}
