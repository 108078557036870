import { useEffect } from 'react';

import { type KnackFieldKey } from '@/types/schema/fields/KnackField';
import { type LiveAppTableResponse } from '@/types/schema/LiveAppTable';
import { useViewSearchParams, type ViewWithSearchParams } from './useViewSearchParams';

export type ViewFilterRule = Record<'operator' | 'value' | 'field_name' | 'match', string> & {
  field: KnackFieldKey;
  range?: string;
  type?: string;
};

export interface ViewFilters {
  match: string;
  rules: ViewFilterRule[];
}

interface UseViewFiltersProps {
  view: ViewWithSearchParams;
  sourceTable: LiveAppTableResponse;
}

export function useViewFilters({ view, sourceTable }: UseViewFiltersProps) {
  const { activeViewFilters, mapFilters, setViewParam } = useViewSearchParams(view);

  const allowPresetFilters =
    view.type === 'map' ? view.details.allow_preset_filters : view.allow_preset_filters;
  const filterType = view.type === 'map' ? view.details.filter_type : view.filter_type;

  const getPresetFilters = () => {
    const presetFilters = view.type === 'map' ? view.details.preset_filters : view.preset_filters;
    return presetFilters.map((filter) => ({
      match: 'and',
      field: filter.field,
      operator: filter.operator,
      value: filter.value,
      field_name: sourceTable.fields.find((field) => field.key === filter.field)?.name ?? ''
    }));
  };

  const initializeFilters = () => {
    const shouldInitializeFilters =
      !activeViewFilters && allowPresetFilters && filterType !== 'menu' && filterType !== 'none';
    if (shouldInitializeFilters) {
      const presetFilters = getPresetFilters();
      if (presetFilters.length > 0) {
        setViewParam({ filters: { match: 'and', rules: presetFilters } });
      }
    }
  };

  useEffect(() => {
    initializeFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveActiveFilters = (filters: ViewFilters | null) => {
    let newFilters = filters;
    if (mapFilters && mapFilters.rules.length > 0) {
      const rules = filters?.rules || [];
      const mapRules = mapFilters.rules.filter((rule) => rule.operator === 'near');
      const filtersRules = rules.filter((rule) => rule.operator !== 'near');
      newFilters = {
        match: filters ? filters.match : 'and',
        rules: [...filtersRules, ...mapRules]
      };
    }

    setViewParam({ filters: newFilters, page: null });
  };

  const resetFilters = () => {
    setViewParam({ filters: mapFilters?.rules.length ? mapFilters : null });
  };

  const removeActiveFilter = (index: number) => {
    if (!activeViewFilters) return;

    if (activeViewFilters.rules.length === 1) {
      setViewParam({ filters: mapFilters?.rules.length ? mapFilters : null });
      return;
    }

    const newRules = activeViewFilters.rules.filter((_, i) => i !== index);
    if (newRules.length === 0) {
      setViewParam({ filters: mapFilters?.rules.length ? mapFilters : null });
      return;
    }

    const filtersRules = newRules.filter((rule) => rule.operator !== 'near');
    const mapRules = mapFilters?.rules.filter((rule) => rule.operator === 'near') || [];
    setViewParam({
      filters: {
        ...activeViewFilters,
        rules: [...filtersRules, ...mapRules]
      }
    });
  };

  const getDefaultFilters = () => {
    let defaultFieldInputKey: KnackFieldKey | undefined;
    let defaultFieldInputName: string | undefined;

    if (view.type === 'table') {
      const fieldColumn = view.columns.find((column) => column.type === 'field');

      if (fieldColumn) {
        defaultFieldInputKey = fieldColumn.field.key;
        defaultFieldInputName = fieldColumn.header;
      }
    }

    if (view.type === 'list' || view.type === 'map') {
      const columns = view.type === 'map' ? view.details.columns : view.columns;
      // Use `some` to leverage early exits once the field input is found
      columns.some((column) =>
        column.groups.some((group) =>
          group.columns.some((innerColumn) =>
            innerColumn.some((listItem) => {
              if (listItem.type === 'field') {
                defaultFieldInputKey = listItem.key;
                defaultFieldInputName = listItem.name;
                return true;
              }
              return false;
            })
          )
        )
      );
    }

    // If no field input is found, return a filter with empty rules
    if (!defaultFieldInputKey || !defaultFieldInputName) {
      const defaultFilters: ViewFilters = {
        match: 'and',
        rules: []
      };

      return defaultFilters;
    }

    const defaultFilters: ViewFilters = {
      match: 'and',
      rules: [
        {
          match: 'and',
          field: defaultFieldInputKey,
          operator: '',
          value: '',
          field_name: defaultFieldInputName
        }
      ]
    };

    return defaultFilters;
  };

  return {
    saveActiveFilters,
    resetFilters,
    removeActiveFilter,
    getDefaultFilters,
    getPresetFilters
  };
}
