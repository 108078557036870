import { createContext, useContext, useMemo } from 'react';

import {
  type ChartData,
  type ReportView,
  type ReportViewChart
} from '@/types/schema/views/ReportView';
import { useReportPreviewQuery } from '@/hooks/api/queries/useReportPreviewQuery';
import { useViewContext } from '@/components/views/ViewContext';

type SortableChartContextState = {
  chart: ReportViewChart;
  chartData: ChartData | undefined;
} | null;

type SortableChartContextProviderProps = {
  chart: ReportViewChart;
  children: React.ReactNode;
};

const SortableChartContext = createContext<SortableChartContextState>(null);

export function SortableChartContextProvider({
  chart,
  children
}: SortableChartContextProviderProps) {
  const { view } = useViewContext<ReportView>();

  // We fetch the data for each chart individually when inside the page editor or page editor preview
  const { data: chartData } = useReportPreviewQuery({
    viewKey: view.key,
    report: chart
  });

  const value = useMemo(
    () => ({
      chart,
      chartData
    }),
    [chart, chartData]
  );

  return <SortableChartContext.Provider value={value}>{children}</SortableChartContext.Provider>;
}

export const useSortableChartContext = () => {
  const context = useContext(SortableChartContext);

  if (!context) {
    throw new Error('useSortableChartContext must be used within an SortableChartContextProvider');
  }

  return context;
};
