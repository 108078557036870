import { type ChartLayout } from '@/types/schema/views/ReportView';

// This function is created to generate a default layout for the Reports Elements that come corrupt.
// In some Knack template apps, the Reports Elements come corrupt and cannot be displayed correctly.
// https://knack.atlassian.net/browse/FE-3732
export function getDefaultChartLayout(): ChartLayout {
  return {
    dimensions: 'auto',
    chart_width: '500',
    chart_height: '350',
    legend_width: '170',
    legend: 'below',
    stacking: 'none',
    tilt_labels: false,
    data_labels: true,
    bar_type: 'bar',
    colors: 'monochromatic'
  };
}
