import { lazy, Suspense } from 'react';
import { Spinner } from '@knack/asterisk-react';

import { type FontAwesomeIconLazyProps } from '@/components/font-awesome/FontAwesomeIconLazy';

export const FontAwesomeIconLazy = lazy(() =>
  import('@/components/font-awesome/FontAwesomeIconLazy').then((module) => ({
    default: module.FontAwesomeIconLazy
  }))
);

export function KnackFontAwesomeIcon({
  icon,
  spinnerClassName,
  ...props
}: FontAwesomeIconLazyProps) {
  return (
    <Suspense fallback={<Spinner className={spinnerClassName} />}>
      <FontAwesomeIconLazy key={icon} icon={icon} spinnerClassName={spinnerClassName} {...props} />
    </Suspense>
  );
}
