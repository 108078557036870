import { useTranslation } from 'react-i18next';
import { HiMagnifyingGlass as SearchIcon } from 'react-icons/hi2';

import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';

export function ChartEmptyState() {
  const [t] = useTranslation();
  const { theme } = useThemingContext();

  return (
    <div
      className={cn('bg-muted p-6', {
        'rounded-lg': theme.appearance.corners === 'rounded'
      })}
    >
      <div className="mb-4 flex flex-col items-center justify-center">
        <SearchIcon size={40} className="text-muted" />
        <div className="mt-2 font-semibold">{t('components.views.report.no_data_title')}</div>
      </div>
      <p className="mb-4 text-center">{t('components.views.report.no_data_1')}</p>
      <p className="text-center">{t('components.views.report.no_data_2')}</p>
    </div>
  );
}
