import { useTranslation } from 'react-i18next';
import { Button, useToast } from '@knack/asterisk-react';

import { type TableViewColumn } from '@/types/schema/views/TableView';
import { useDeleteRecordMutation } from '@/hooks/api/mutations/useDeleteRecordMutation';
import { useUpdateRecordMutation } from '@/hooks/api/mutations/useUpdateRecordMutation';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { useActivePageHelpers } from '@/hooks/helpers/useActivePageHelpers';
import { useViewRules } from '@/hooks/useViewRules';
import { LabelWithIcon } from '@/components/LabelWithIcon';
import { useViewContext } from '@/components/views/ViewContext';
import { ActionButton } from './ActionButton';
import { DeleteRecordConfirmDialog } from './DeleteRecordConfirmDialog';

interface ViewActionButtonProps {
  column: TableViewColumn;
  record: ViewRecord['values'];
}

export function TableActionButton({ column, record }: ViewActionButtonProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { view } = useViewContext();
  const { getActivePagePathname } = useActivePageHelpers();

  const { mutate: deleteRecord } = useDeleteRecordMutation();
  const { mutate: updateRecord } = useUpdateRecordMutation({ viewKey: view.key });
  const { handleSubmitRules, checkCriteriaRules } = useViewRules();

  if (!record.id) return null;

  const recordId = record.id;

  const handleAction = (actionLinkIndex?: number) => {
    switch (column.type) {
      case 'delete':
        deleteRecord(
          {
            viewKey: view.key,
            recordId
          },
          {
            onSuccess: () => {
              presentToast({
                title: t('components.views.table.record_delete_success')
              });
            },
            onError: () => {
              presentToast({
                title: t('components.views.table.record_delete_error'),
                intent: 'destructive'
              });
            }
          }
        );
        break;
      default:
        updateRecord(
          {
            data: {
              id: record.id,
              action_link_index: 0
            },
            recordId: record.id
          },
          {
            onSuccess: () => {
              if (actionLinkIndex === undefined) return;

              handleSubmitRules({
                data: {},
                rules: column.action_rules?.[actionLinkIndex].submit_rules || []
              });
            }
          }
        );
    }
  };

  if (column.type === 'link') {
    return (
      <ActionButton
        icon={column.icon}
        label={column.link_text}
        linkTo={`${getActivePagePathname()}/${column.scene}/${record.id}`}
      />
    );
  }

  if (column.link_type !== 'text') {
    if (!record.htmlValue) return null;

    return (
      <span className="flex items-center">
        <LabelWithIcon icon={column.icon?.icon} iconPosition={column.icon?.align || 'left'}>
          <Button
            intent="link"
            onClick={() => handleAction()}
            dangerouslySetInnerHTML={{ __html: record.htmlValue }}
          />
        </LabelWithIcon>
      </span>
    );
  }

  if (column.type === 'action_link') {
    if (!column.action_rules) return null;

    let actionLinkIndex: number = 0;

    // Find the first set of rules that is met to show the button - we only display one button per cell
    const activeActionRule = column.action_rules.find((rule, index) => {
      actionLinkIndex = index;
      return (
        checkCriteriaRules(record as { [key: string]: any }, rule.criteria) ||
        rule.criteria.length === 0
      );
    });

    if (!activeActionRule) return null;

    return (
      <ActionButton
        onClick={() => handleAction(actionLinkIndex)}
        icon={column.icon}
        label={activeActionRule.link_text}
      />
    );
  }

  if (column.type === 'delete') {
    return (
      <DeleteRecordConfirmDialog onConfirm={handleAction}>
        <ActionButton icon={column.icon} label={column.link_text} isDelete />
      </DeleteRecordConfirmDialog>
    );
  }

  return (
    <ActionButton onClick={() => handleAction()} icon={column.icon} label={column.link_text} />
  );
}
