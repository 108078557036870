import { type ViewRecordsResponse } from '@/hooks/api/queries/useViewMultipleRecordsQuery';

export const tableDemoData: ViewRecordsResponse = {
  location_fail: false,
  total_pages: 2,
  current_page: 1,
  total_records: 20,
  records: [
    {
      id: '66b23116ffa83100ac719f86',
      field_1: 'Example Data',
      field_1_raw: 'Example Data',
      field_2: 'Example Data',
      field_2_raw: 'Example Data'
    },
    {
      id: '66b23119ffa83100ac71a509',
      field_1: 'Example Data',
      field_1_raw: 'Example Data',
      field_2: 'Example Data',
      field_2_raw: 'Example Data'
    },
    {
      id: '66b23116ffa83104ac719f86',
      field_1: 'Example Data',
      field_1_raw: 'Example Data',
      field_2: 'Example Data',
      field_2_raw: 'Example Data'
    },
    {
      id: '66b23119f4a83100ac71a509',
      field_1: 'Example Data',
      field_1_raw: 'Example Data',
      field_2: 'Example Data',
      field_2_raw: 'Example Data'
    },
    {
      id: '66b23116ffa83100a9719f86',
      field_1: 'Example Data',
      field_1_raw: 'Example Data',
      field_2: 'Example Data',
      field_2_raw: 'Example Data'
    },
    {
      id: '66b23112ffa83100ac71a509',
      field_1: 'Example Data',
      field_1_raw: 'Example Data',
      field_2: 'Example Data',
      field_2_raw: 'Example Data'
    },
    {
      id: '66b23116ffa83100a7719f86',
      field_1: 'Example Data',
      field_1_raw: 'Example Data',
      field_2: 'Example Data',
      field_2_raw: 'Example Data'
    },
    {
      id: '63b23119ffa83100ac71a509',
      field_1: 'Example Data',
      field_1_raw: 'Example Data',
      field_2: 'Example Data',
      field_2_raw: 'Example Data'
    },
    {
      id: '66b23116f1a83100ac719f86',
      field_1: 'Example Data',
      field_1_raw: 'Example Data',
      field_2: 'Example Data',
      field_2_raw: 'Example Data'
    },
    {
      id: '66b23129ffa83100ac71a509',
      field_1: 'Example Data',
      field_1_raw: 'Example Data',
      field_2: 'Example Data',
      field_2_raw: 'Example Data'
    }
  ]
};
