import { Button } from '@knack/asterisk-react';

import { type MenuView, type MenuViewLink } from '@/types/schema/views/MenuView';
import { cn } from '@/utils/tailwind';
import { usePageContext } from '@/context/PageContext';
import { MenuLinkContent } from './MenuLinkContent';

interface MenuLinkProps {
  link: MenuViewLink;
  view: MenuView;
  forceActive?: boolean;
}

export function MenuLink({ link, view, forceActive }: MenuLinkProps) {
  const { activePage } = usePageContext();

  if (view.format === 'none' || !view.format) {
    return (
      <Button
        intent="primary"
        asChild
        className={cn({
          'h-full': !link.name
        })}
      >
        <MenuLinkContent link={link} />
      </Button>
    );
  }

  if (view.format === 'tabs') {
    const isActive = (link.type !== 'url' && link.scene === activePage?.slug) || forceActive;

    return (
      <Button
        intent="minimal"
        className={cn(
          'relative rounded-none font-normal text-default after:absolute after:bottom-[1px] after:z-10 after:h-[2px] after:w-full after:bg-emphasis hover:bg-transparent hover:text-brand-default hover:after:bg-brand-accent',
          {
            'text-brand-default after:bg-brand-accent': isActive,
            'h-full': !link.name
          }
        )}
        asChild
      >
        <MenuLinkContent link={link} />
      </Button>
    );
  }

  return null;
}
