import { useState } from 'react';
import {
  Label,
  MultiSelect,
  Pagination,
  RadioGroup,
  Select,
  Tabs,
  type MultiSelectOption
} from '@knack/asterisk-react';

const options = [
  {
    label: 'Option 1',
    key: 'option1',
    value: 1
  },
  {
    label: 'Option 2',
    key: 'option2',
    value: 2,
    isDisabled: true
  },
  {
    label: 'Option 3',
    key: 'option3',
    value: 3
  },
  {
    label: 'Option 4',
    key: 'option4',
    value: 4,
    isDisabled: true
  },
  {
    label:
      'Option 5 - Ultra super very long title that does not fit in three lines and needs to be truncated so that it does not affect the readbility',
    key: 'option5',
    value: 5
  },
  {
    label: 'Option 6',
    key: 'option6',
    value: 6
  },
  {
    label: 'Option 7',
    key: 'option7',
    value: 7
  },
  {
    label: 'Option 8',
    key: 'option8',
    value: 8
  },
  {
    label: 'Option 9',
    key: 'option9',
    value: 9
  },
  {
    label: 'Option 10',
    key: 'option10',
    value: 10
  },
  {
    label: 'Option 11',
    key: 'option11',
    value: 11
  },
  {
    label: 'Option 12',
    key: 'option12',
    value: 12
  }
];

export function SelectDemo({ intent }: { intent?: 'default' | 'destructive' }) {
  const [selectedOptions, setSelectedOptions] = useState<MultiSelectOption[]>([]);

  return (
    <div className="flex gap-4">
      <Select>
        <Select.Trigger placeholder="Dropdown Field" intent={intent} />
        <Select.Content>
          <Select.Item value="light">Light</Select.Item>
          <Select.Item value="dark">Dark</Select.Item>
          <Select.Item value="system">System</Select.Item>
        </Select.Content>
      </Select>
      <MultiSelect
        id="demo-multiselect"
        intent={intent}
        triggerClassName="min-w-60"
        selectedOptions={selectedOptions}
        onSelectOptions={setSelectedOptions}
        maxVisibleChips={5}
        options={options}
      />
    </div>
  );
}

export function PaginationDemo() {
  return (
    <Pagination>
      <Pagination.Content>
        <Pagination.Item>
          <Pagination.Previous />
        </Pagination.Item>
        <Pagination.Item>
          <Pagination.Link>1</Pagination.Link>
        </Pagination.Item>
        <Pagination.Item>
          <Pagination.Link isActive>{2}</Pagination.Link>
        </Pagination.Item>
        <Pagination.Item>
          <Pagination.Link>{3}</Pagination.Link>
        </Pagination.Item>
        <Pagination.Item>
          <Pagination.Link>{4}</Pagination.Link>
        </Pagination.Item>
        <Pagination.Item>
          <Pagination.Ellipsis />
        </Pagination.Item>
        <Pagination.Item>
          <Pagination.Link>{20}</Pagination.Link>
        </Pagination.Item>
        <Pagination.Item>
          <Pagination.Next />
        </Pagination.Item>
      </Pagination.Content>
    </Pagination>
  );
}

export function RadioGroupDemo() {
  return (
    <RadioGroup defaultValue="comfortable">
      <RadioGroup.Container>
        <RadioGroup.Item value="default" id="r1" />
        <Label htmlFor="r1">Default</Label>
      </RadioGroup.Container>
      <RadioGroup.Container>
        <RadioGroup.Item value="comfortable" id="r2" />
        <Label htmlFor="r2">Comfortable</Label>
      </RadioGroup.Container>
      <RadioGroup.Container>
        <RadioGroup.Item value="disabled" id="r4" disabled />
        <Label htmlFor="r4">Disabled</Label>
      </RadioGroup.Container>
      <RadioGroup.Container>
        <RadioGroup.Item value="disabled-checked" id="r5" disabled checked />
        <Label htmlFor="r5">Disabled Checked</Label>
      </RadioGroup.Container>
    </RadioGroup>
  );
}

export function TabsDemo() {
  return (
    <div className="w-full">
      <Tabs defaultValue="account">
        <Tabs.List
          items={[
            {
              value: 'account',
              children: <span>Account</span>
            },
            {
              value: 'password',
              children: <span>Password</span>
            },
            {
              value: 'settings',
              children: <span>Settings</span>
            },
            {
              value: 'profile',
              children: <span>Profile</span>
            }
          ]}
        />
        <Tabs.Content value="account">Make changes to your account here.</Tabs.Content>
        <Tabs.Content value="password">Change your password here.</Tabs.Content>
        <Tabs.Content value="settings">Change the app settings here.</Tabs.Content>
        <Tabs.Content value="profile">Change your profile here.</Tabs.Content>
      </Tabs>
    </div>
  );
}
