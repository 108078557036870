import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

import { useLogoutUserMutation } from '@/hooks/api/mutations/useLogoutUserMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useSessionContext } from '@/context/SessionContext';

// Threshold (in milliseconds) for when the warning modal is triggered before the timeout expires
export const WARNING_TRIGGER_THRESHOLD = 30000; // 30 seconds

function InactivityTracker({
  inactivityMessage,
  timeoutInMinutes
}: {
  inactivityMessage: string;
  timeoutInMinutes: number;
}) {
  const [t] = useTranslation();
  const logout = useLogoutUserMutation();

  const timeoutInMilliseconds = timeoutInMinutes * 60 * 1000;
  const warningTimeoutInMilliseconds = timeoutInMilliseconds - WARNING_TRIGGER_THRESHOLD;

  const [secondsLeft, setSecondsLeft] = useState(
    (timeoutInMilliseconds - warningTimeoutInMilliseconds) / 1000
  );
  const [showModal, setShowModal] = useState(false);

  const inactivityTimer = useRef<number | null>(null);
  const warningTimer = useRef<number | null>(null);

  const events = ['mousemove', 'keydown', 'scroll'];

  const handleLogout = () => {
    logout.mutate();
  };

  const resetTimers = () => {
    if (inactivityTimer.current) window.clearTimeout(inactivityTimer.current);
    if (warningTimer.current) window.clearTimeout(warningTimer.current);

    setSecondsLeft((timeoutInMilliseconds - warningTimeoutInMilliseconds) / 1000);

    inactivityTimer.current = window.setTimeout(() => {
      setShowModal(false);
      handleLogout();
    }, timeoutInMilliseconds);

    warningTimer.current = window.setTimeout(() => {
      setShowModal(true);
      events.forEach((event) => {
        window.removeEventListener(event, resetTimers);
      });
    }, warningTimeoutInMilliseconds);
  };

  const startTracking = () => {
    resetTimers();
    events.forEach((event) => {
      window.addEventListener(event, resetTimers);
    });
  };

  useEffect(() => {
    startTracking();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimers);
      });
      if (inactivityTimer.current) window.clearTimeout(inactivityTimer.current);
      if (warningTimer.current) window.clearTimeout(warningTimer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showModal) {
      const countdown = setInterval(() => {
        setSecondsLeft((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(countdown);
    }

    return undefined;
  }, [showModal]);

  return (
    <Dialog
      open={showModal}
      onOpenChange={(isOpen) => {
        if (!isOpen) startTracking();
        setShowModal(isOpen);
      }}
    >
      <Dialog.Content data-testid="inactivity-timeout-modal" aria-describedby={undefined}>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{t('components.inactivity_timeout.title')}</Dialog.Title>
          </Dialog.Header>
          <div className="mt-6">
            {inactivityMessage !== ''
              ? inactivityMessage
              : t('components.inactivity_timeout.countdown_message', {
                  countdown: secondsLeft
                })}
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Button
            intent="minimal"
            onClick={handleLogout}
            data-testid="inactivity-timeout-logout-button"
          >
            {t('components.inactivity_timeout.logout_now')}
          </Button>
          <Dialog.Close asChild>
            <Button
              onClick={() => startTracking()}
              intent="primary"
              data-testid="inactivity-timeout-stay-logged-in-button"
            >
              {t('components.inactivity_timeout.stay_logged_in')}
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}

export function InactivityTimeout() {
  const { data: application } = useApplicationQuery();
  const session = useSessionContext();

  if (!session || !application?.settings.inactivityTimeout.isEnabled) {
    return null;
  }

  return (
    <InactivityTracker
      inactivityMessage={application.settings.inactivityTimeout?.inactivityMessage ?? ''}
      timeoutInMinutes={application.settings.inactivityTimeout.timeoutInMinutes}
    />
  );
}
