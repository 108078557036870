import { useEffect, useState } from 'react';

type Coordinates = {
  latitude: number;
  longitude: number;
};

type UserLocationHook = {
  coordinates: Coordinates | null;
  isLoading: boolean;
};

export function useGeolocationCurrentPosition({ enabled }: { enabled: boolean }): UserLocationHook {
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    setIsLoading(true);
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        const { latitude, longitude } = coords;
        const currentCoordinates = { latitude, longitude };
        setCoordinates(currentCoordinates);
        setIsLoading(false);
      },
      () => {
        console.error('Error getting current position');
        setIsLoading(false);
      }
    );
  }, [enabled]);

  return { coordinates, isLoading };
}
