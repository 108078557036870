import { type ChartCalculation, type ChartRecord } from '@/types/schema/views/ReportView';

export const CALCULATION_PREFIX = 'calc_';

export function useChartHelpers() {
  // Get the calculation key with the maximum value. It's useful to set the YAxis domain
  const getMaxValueCalculation = (
    chartCalculations: ChartCalculation[],
    calculationRecords: ChartRecord[]
  ) => {
    let maxCalculationKey = `${CALCULATION_PREFIX}0`;
    let maxCalculationValue = 0;

    chartCalculations.forEach((calculation, index) => {
      const calculationKey = `${CALCULATION_PREFIX}${index}`;
      const calculationValues = calculationRecords.map(
        (record) => record[calculationKey] as number
      );
      const calculationMaxValue = Math.max(...calculationValues);

      if (calculationMaxValue > maxCalculationValue) {
        maxCalculationKey = calculationKey;
        maxCalculationValue = calculationMaxValue;
      }
    });

    return maxCalculationKey;
  };

  // Get the records data in percentage format
  const getRecordsInPercentage = (records: ChartRecord[]) => {
    // Calculate the percentage per row only in the numeric values
    const recordsInPercentage = records.map((record) => {
      const currentRecord = { ...record };

      // Filter only the numeric values
      const calculationValues = Object.keys(currentRecord).filter(
        (key) => typeof currentRecord[key] === 'number' && key.startsWith(CALCULATION_PREFIX)
      );

      // Calculate the total sum of the numeric values
      const totalSum = calculationValues.reduce(
        (acc, key) => acc + (currentRecord[key] as number),
        0
      );

      // Calculate the percentage of each numeric value
      calculationValues.forEach((key) => {
        currentRecord[key] = (((currentRecord[key] as number) / totalSum) * 100).toFixed(2);
      });

      return currentRecord;
    });

    return recordsInPercentage;
  };

  // Transform negative values to zero
  const transformNegativeValuesToZero = (records: ChartRecord[]) =>
    records.map((record) => {
      const currentRecord = { ...record };

      Object.keys(currentRecord).forEach((key) => {
        if (
          typeof currentRecord[key] === 'number' &&
          key.startsWith(CALCULATION_PREFIX) &&
          currentRecord[key] < 0
        ) {
          currentRecord[key] = 0;
        }
      });

      return currentRecord;
    });

  // Filters the empty groups from the records
  const filterEmptyGroups = (records: ChartRecord[]) =>
    records.filter((record) => {
      const calculationValues = Object.keys(record).filter(
        (key) => typeof record[key] === 'number' && key.startsWith(CALCULATION_PREFIX)
      );

      return calculationValues.some((key) => record[key] !== null);
    });

  return {
    filterEmptyGroups,
    getMaxValueCalculation,
    getRecordsInPercentage,
    transformNegativeValuesToZero
  };
}
