import { Trans, useTranslation } from 'react-i18next';
import { Banner, Button } from '@knack/asterisk-react';

import { useAuthFlow } from '@/pages/page/AuthFlowContext';

export type SignUpError = {
  type: 'unique' | 'schema';
  message: string;
  field: string;
};

export function SignUpErrorBanner({ error }: { error: SignUpError }) {
  const [t] = useTranslation();
  const { setActiveAuthFlow } = useAuthFlow();

  if (error.type !== 'unique') {
    return (
      <Banner intent="destructive" className="font-medium">
        <p>{t('components.views.form.sign_up_error')}</p>
      </Banner>
    );
  }

  return (
    <Banner intent="warning" className="font-medium">
      <p>{t('components.views.form.email_already_in_use')}</p>
      <Trans i18nKey="components.views.form.please_login">
        <Button
          intent="link"
          className="text-warning-emphasis"
          onClick={() => setActiveAuthFlow('login')}
        >
          log in
        </Button>
        <Button
          intent="link"
          className="text-warning-emphasis"
          onClick={() => setActiveAuthFlow('forgot-password')}
        >
          reset your password
        </Button>
      </Trans>
    </Banner>
  );
}
