import { useTranslation } from 'react-i18next';
import { MdLockClock as LockIcon } from 'react-icons/md';
import { Banner, type BannerProps } from '@knack/asterisk-react';
import { isAxiosError, type AxiosError } from 'axios';

import { ApiErrorBanner } from '@/components/errors/ApiErrorBanner';
import { PendingApprovalBanner } from './PendingApprovalBanner';

function MaxAttemptsErrorBanner(bannerProps: BannerProps) {
  const [t] = useTranslation();
  return (
    <Banner
      intent="destructive"
      icon={LockIcon}
      title={t('components.views.login.errors.too_many_failed_attempts')}
      {...bannerProps}
    >
      <Banner.Message>
        {t('components.views.login.errors.too_many_failed_attempts_description')}
      </Banner.Message>
    </Banner>
  );
}

export function LoginErrorBanner({ error }: { error: Error | AxiosError }) {
  const isMaxAttemptsError = isAxiosError(error) ? error.response?.status === 423 : false;
  const isPendingApprovalWarning = isAxiosError(error)
    ? error?.response?.data?.errors?.[0].status === 'pending approval'
    : false;

  if (isMaxAttemptsError) {
    return <MaxAttemptsErrorBanner />;
  }

  if (isPendingApprovalWarning) {
    return <PendingApprovalBanner />;
  }

  return (
    <ApiErrorBanner
      error={error}
      translationPrefix="components.views.login.errors"
      fallbackKey="components.views.login.errors.fallback_error"
    />
  );
}
