import { type ViewRecordsResponse } from '@/hooks/api/queries/useViewMultipleRecordsQuery';

export const listDemoData: ViewRecordsResponse = {
  location_fail: false,
  total_pages: 40,
  current_page: 1,
  total_records: 1000,
  records: [
    {
      id: '66b23116ffa83100ac719f86',
      field_1: 'Allison Lubin',
      field_1_raw: 'Allison Lubin',
      field_2: 'Some short text',
      field_2_raw: 'Some short text',
      field_3: '$ 1,234.56',
      field_3_raw: '$ 1,234.56',
      field_4: '(+34) 868 646 424',
      field_4_raw: '(+34) 868 646 424',
      field_5: 'allison.lubin@knack.com',
      field_5_raw: 'allison.lubin@knack.com',
      field_6: '1234 Example St\nSeattle, WA\n98043',
      field_6_raw: '1234 Example St\nSeattle, WA\n98043',
      field_7: 'Silvain Whyley',
      field_7_raw: 'Silvain Whyley'
    },
    {
      id: '66b23116ffa83100ac719f86',
      field_1: 'Allison Lubin',
      field_1_raw: 'Allison Lubin',
      field_2: 'Some short text',
      field_2_raw: 'Some short text',
      field_3: '$ 1,234.56',
      field_3_raw: '$ 1,234.56',
      field_4: '(+34) 868 646 424',
      field_4_raw: '(+34) 868 646 424',
      field_5: 'allison.lubin@knack.com',
      field_5_raw: 'allison.lubin@knack.com',
      field_6: '1234 Example St\nSeattle, WA\n98043',
      field_6_raw: '1234 Example St\nSeattle, WA\n98043',
      field_7: 'Silvain Whyley',
      field_7_raw: 'Silvain Whyley'
    },
    {
      id: '66b23116ffa83100ac719f86',
      field_1: 'Allison Lubin',
      field_1_raw: 'Allison Lubin',
      field_2: 'Some short text',
      field_2_raw: 'Some short text',
      field_3: '$ 1,234.56',
      field_3_raw: '$ 1,234.56',
      field_4: '(+34) 868 646 424',
      field_4_raw: '(+34) 868 646 424',
      field_5: 'allison.lubin@knack.com',
      field_5_raw: 'allison.lubin@knack.com',
      field_6: '1234 Example St\nSeattle, WA\n98043',
      field_6_raw: '1234 Example St\nSeattle, WA\n98043',
      field_7: 'Silvain Whyley',
      field_7_raw: 'Silvain Whyley'
    },
    {
      id: '66b23116ffa83100ac719f86',
      field_1: 'Allison Lubin',
      field_1_raw: 'Allison Lubin',
      field_2: 'Some short text',
      field_2_raw: 'Some short text',
      field_3: '$ 1,234.56',
      field_3_raw: '$ 1,234.56',
      field_4: '(+34) 868 646 424',
      field_4_raw: '(+34) 868 646 424',
      field_5: 'allison.lubin@knack.com',
      field_5_raw: 'allison.lubin@knack.com',
      field_6: '1234 Example St\nSeattle, WA\n98043',
      field_6_raw: '1234 Example St\nSeattle, WA\n98043',
      field_7: 'Silvain Whyley',
      field_7_raw: 'Silvain Whyley'
    }
  ]
};
