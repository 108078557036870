import { Table } from '@knack/asterisk-react';

import { type ChartRecord, type ReportViewChart } from '@/types/schema/views/ReportView';
import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';

interface ChartDataTableProps {
  chart: ReportViewChart;
  chartRecords: ChartRecord[];
}

export function ChartDataTable({ chart, chartRecords }: ChartDataTableProps) {
  const { theme } = useThemingContext();

  return (
    <div
      className={cn('m-6 h-fit overflow-hidden border border-default', {
        'rounded-lg': theme.appearance.corners === 'rounded'
      })}
    >
      <Table className="table overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>{chart.groups[0].label}</Table.Head>
            {chart.calculations.map((calculation) => (
              <Table.Head key={calculation.calculation}>{calculation.label}</Table.Head>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {chartRecords.map((record) => (
            <Table.Row key={record.key}>
              <Table.Cell key={record.group_0}>{record.group_0}</Table.Cell>
              {chart.calculations.map((calculation, index) => (
                <Table.Cell key={`${calculation.calculation}-${record.key}`}>
                  {record[`calc_${index}`]}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
