import {
  amberTokens,
  blueTokens,
  cyanTokens,
  defaultTokens,
  fuchsiaTokens,
  greenTokens,
  limeTokens,
  pinkTokens,
  violetTokens,
  type AppTheme
} from '@knack/asterisk-react';

import { type ChartLayout } from '@/types/schema/views/ReportView';

export function getColors(
  theme: AppTheme,
  isDarkMode: boolean,
  colors: ChartLayout['colors'] = 'monochromatic'
) {
  const themeMode = isDarkMode ? 'dark' : 'light';

  switch (theme.colors.preset) {
    case 'default':
    case 'custom':
      return defaultTokens.charts[themeMode][colors];
    case 'blue':
      return blueTokens.charts[themeMode][colors];
    case 'green':
      return greenTokens.charts[themeMode][colors];
    case 'amber':
      return amberTokens.charts[themeMode][colors];
    case 'lime':
      return limeTokens.charts[themeMode][colors];
    case 'pink':
      return pinkTokens.charts[themeMode][colors];
    case 'cyan':
      return cyanTokens.charts[themeMode][colors];
    case 'fuchsia':
      return fuchsiaTokens.charts[themeMode][colors];
    case 'violet':
      return violetTokens.charts[themeMode][colors];

    default:
      throw new Error(`Unknown preset: ${theme.colors.preset}`);
  }
}
