import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddConnectionIcon, HiInformationCircle as InfoIcon } from 'react-icons/hi2';
import { Button, Dialog, Tooltip } from '@knack/asterisk-react';

import { type FormViewConnectionInput } from '@/types/schema/views/form/Connection';
import { type FormView } from '@/types/schema/views/FormView';
import { cn } from '@/utils/tailwind';
import { FormViewRender } from '@/components/views/form/FormViewRender';
import { useViewContext, ViewContextProvider } from '@/components/views/ViewContext';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { useSectionEditorContext } from '@/pages/page/page-editor/SectionEditorContext';

export function AddConnectedRecordModalPageEditor({
  connectionInput
}: {
  connectionInput: FormViewConnectionInput;
}) {
  const [t] = useTranslation();

  const { page, selectItem } = usePageEditorContext();
  const { currentHoveredViewInput } = useSectionEditorContext();
  const { view: parentView } = useViewContext();

  const [isOpen, setIsOpen] = useState(false);

  if (!connectionInput.view) {
    return null;
  }

  let childView: FormView | undefined;
  let isNewlyCreatedChildForm = false;

  // If the connection input's `view` references a view key, we'll find the child view in the page's views array
  if (typeof connectionInput.view === 'string') {
    page.views.forEach((view) => {
      if (view.type === 'form' && view.key === connectionInput.view) {
        childView = view;
      }
    });
  } else {
    // Otherwise, it means the connection input's `view` stores a newly created child form view, which means that the user has enabled the ability to insert connected records
    // but the child view hasn't been created and processed by the server yet, so it doesn't exist in the page's views array.
    isNewlyCreatedChildForm = true;
  }

  const isInputHovered = !!currentHoveredViewInput?.includes(parentView.key);

  // If it's a newly created child form, we render a disabled button with a tooltip explaining to the user that the child form view will be available to edit after saving the changes
  if (isNewlyCreatedChildForm) {
    return (
      <div className="flex items-center">
        <Button disabled intent="secondary" className="relative items-center gap-2 p-2" size="sm">
          <AddConnectionIcon size={16} />
          {t('components.views.form.connection_input.add_new_connection')}
        </Button>

        <Tooltip>
          <Tooltip.Trigger asChild>
            <button
              type="button"
              className="z-10 ml-2"
              aria-label={t(
                'components.views.form.connection_input.add_new_connection_tooltip_aria_label'
              )}
            >
              <InfoIcon size={18} />
            </button>
          </Tooltip.Trigger>
          <Tooltip.Content className="max-w-72">
            {t('components.views.form.connection_input.add_new_connection_tooltip')}
          </Tooltip.Content>
        </Tooltip>
      </div>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen} modal={false}>
      <Dialog.Trigger asChild>
        <Button
          intent="secondary"
          className={cn('relative items-center gap-2 p-2', {
            'z-10': isInputHovered // We want to ensure the button is above the backdrop/overlay when hovered so it's clickable
          })}
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <AddConnectionIcon size={16} />
          {t('components.views.form.connection_input.add_new_connection')}
        </Button>
      </Dialog.Trigger>

      {childView && (
        <Dialog.Content
          modal={false}
          onOpenAutoFocus={(e) => {
            e.preventDefault();

            if (!childView) {
              return;
            }

            selectItem({ type: 'view', viewKey: childView.key });

            // Since the dialog is rendered in a non-modal way, we don't have automatic scroll prevention on the body from Radix,
            // so we need to manually prevent scrolling at the body level when the dialog is open
            document.body.classList.add('overflow-hidden');
          }}
          onCloseAutoFocus={() => {
            selectItem({ type: 'view', viewKey: parentView.key });
            document.body.classList.remove('overflow-hidden');
          }}
        >
          <Dialog.Title className="sr-only">
            {t('components.views.form.connection_input.add_new_connection')}
          </Dialog.Title>
          <Dialog.MainContent className="pt-8">
            <ViewContextProvider view={childView}>
              <FormViewRender />
            </ViewContextProvider>
          </Dialog.MainContent>
        </Dialog.Content>
      )}
    </Dialog>
  );
}
