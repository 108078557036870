import { useRef, type ComponentPropsWithoutRef } from 'react';
import { Chart } from '@knack/asterisk-react';
import {
  Bar,
  BarChart,
  Legend as RechartsLegend,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis
} from 'recharts';
import { type HorizontalAlignmentType } from 'recharts/types/component/DefaultLegendContent';

import type { ChartData, ReportViewChart } from '@/types/schema/views/ReportView';
import { getDefaultChartLayout } from '@/utils/schema/patch/reports';
import { ChartDataTable } from './shared/ChartDataTable';
import { ChartEmptyState } from './shared/ChartEmptyState';
import { ChartExportButton } from './shared/ChartExportButton';
import { ChartHeader } from './shared/ChartHeader';
import { useChart } from './shared/useChart';

interface BarChartRenderProps extends ComponentPropsWithoutRef<'div'> {
  chart: ReportViewChart;
  chartData: ChartData;
}

export function BarChartRender({ chart, chartData, ...rest }: BarChartRenderProps) {
  const { chartRecords, chartKeys, chartConfig, hasMultipleGroups, maxCalculationChartKey } =
    useChart({ chart, chartData });

  const chartRef = useRef<HTMLDivElement>(null);

  if (chartRecords.length === 0) {
    return <ChartEmptyState />;
  }

  const chartLayout = chart.layout || getDefaultChartLayout();
  const shouldUseVerticalLegend = chartLayout.legend === 'right' || chartLayout.legend === 'left';
  const shouldStackCalculations = chartLayout.stacking === 'normal';
  const shouldTiltLabels = chartLayout.tilt_labels;
  const { shouldShowDataTable, export_links: shouldShowExportButton } = chart.options;

  const addBarBorderRadius = (
    index: number
  ): number | [number, number, number, number] | undefined => {
    const defaultBorderRadius = 5;

    if (!shouldStackCalculations) {
      return defaultBorderRadius;
    }

    if (index === 0) {
      return [0, 0, defaultBorderRadius, defaultBorderRadius];
    }

    if (index === Object.keys(chartKeys).length - 1) {
      return [defaultBorderRadius, defaultBorderRadius, 0, 0];
    }

    return undefined;
  };

  return (
    <div data-testid="bar-chart" {...rest}>
      <ChartHeader chart={chart} />
      {shouldShowExportButton && <ChartExportButton chart={chart} chartRef={chartRef} />}
      <Chart ref={chartRef} config={chartConfig} className="w-full">
        <BarChart
          accessibilityLayer
          data={chartRecords}
          margin={{
            left: 0,
            top: 20,
            right: 5,
            bottom: 20
          }}
        >
          {!hasMultipleGroups && (
            <YAxis
              dataKey={shouldStackCalculations ? undefined : maxCalculationChartKey}
              type="number"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value}
            />
          )}
          <XAxis
            dataKey="group_0"
            type="category"
            tickLine={false}
            axisLine={false}
            {...(shouldTiltLabels ? { angle: -45, textAnchor: 'end' } : {})}
          />
          <RechartsTooltip cursor={false} content={<Chart.TooltipContent hideLabel />} />
          {chartLayout.legend !== 'none' && (
            <RechartsLegend
              content={<Chart.LegendContent />}
              layout={shouldUseVerticalLegend ? 'vertical' : 'horizontal'}
              verticalAlign={shouldUseVerticalLegend ? 'middle' : undefined}
              align={
                chartLayout.legend === 'bottom'
                  ? 'center'
                  : (chartLayout.legend as HorizontalAlignmentType)
              }
            />
          )}
          {Object.keys(chartKeys).map((key, index) => (
            <Bar
              key={`${chart.id}-bar-${key}`}
              dataKey={key}
              fill={`var(--color-${key})`}
              radius={addBarBorderRadius(index)}
              label={
                chartLayout.data_labels
                  ? { position: shouldStackCalculations ? 'center' : 'top', fill: '#fff' }
                  : false
              }
              stackId={shouldStackCalculations ? '1' : undefined}
              isAnimationActive={false}
            />
          ))}
        </BarChart>
      </Chart>
      {shouldShowDataTable && <ChartDataTable chart={chart} chartRecords={chartRecords} />}
    </div>
  );
}
