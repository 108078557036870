import { usePageQuery } from '@/hooks/api/queries/usePageQuery';
import { cn } from '@/utils/tailwind';
import { ForgotPasswordForm } from '@/components/views/auth/ForgotPasswordForm';
import { useAuthFlow } from '@/pages/page/AuthFlowContext';
import { usePageContext } from '@/context/PageContext';
import { LoginForm } from './LoginForm';

export function LoginViewRender() {
  const { activeAuthFlow } = useAuthFlow();
  const { activePage } = usePageContext();

  const { data: fullPage } = usePageQuery({ pageSlug: activePage?.slug });

  const hasOnlyOneColumn = Number(fullPage?.groups[0]?.layout?.columns) < 2;

  return (
    <div
      className={cn('flex flex-col items-center justify-center sm:p-10', {
        'mx-auto sm:max-w-[600px]': hasOnlyOneColumn
      })}
    >
      {activeAuthFlow === 'login' && <LoginForm />}
      {activeAuthFlow === 'forgot-password' && <ForgotPasswordForm />}
    </div>
  );
}
