import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, Label, RadioGroup, Select } from '@knack/asterisk-react';

import { type FormViewBooleanInput } from '@/types/schema/views/form/Boolean';
import { type FormView } from '@/types/schema/views/FormView';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';
import { booleanOptions, getFormattedBooleanDefaultValue } from './helper';

export function BooleanInput({ input }: { input: FormViewBooleanInput }) {
  const { view } = useViewContext<FormView>();
  const {
    setValue: setFormValue,
    formState: { errors },
    getValues
  } = useFormContext();

  const inputFormat = input.format;
  const inputValue = getValues(input.field.key);
  const booleanInputType = inputFormat?.input;
  const isReadOnly = input.read_only;

  useEffect(() => {
    if (view.action === 'update') {
      if (booleanInputType === 'checkbox') {
        setFormValue(input.field.key, inputValue);
        return;
      }
      setFormValue(input.field.key, getFormattedBooleanDefaultValue(inputValue, inputFormat));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (booleanInputType === 'radios') {
    return (
      <>
        <Controller
          name={input.field.key}
          render={({ field }) => (
            <RadioGroup
              disabled={isReadOnly}
              defaultValue={field.value}
              onValueChange={(val: string) => field.onChange(val)}
            >
              {booleanOptions[inputFormat.format].map((option, index) => {
                const radioInputId = `${view.key}-${input.id}-boolean-${index}`;
                return (
                  <RadioGroup.Container key={radioInputId}>
                    <RadioGroup.Item value={option} id={radioInputId} />
                    <Label htmlFor={radioInputId}>{option}</Label>
                  </RadioGroup.Container>
                );
              })}
            </RadioGroup>
          )}
        />
        <FormErrorMessage errors={errors} name={input.field.key} />
      </>
    );
  }

  if (booleanInputType === 'checkbox') {
    const checkboxesInputId = `${view.key}-${input.id}-boolean-checkbox`;
    return (
      <>
        <div key={`${view.key}-${input.id}`} className="flex items-center gap-2">
          <Controller
            name={input.field.key}
            render={({ field }) => {
              const fieldValue = field.value || inputValue;
              return (
                <Checkbox
                  intent={errors[input.field.key] ? 'destructive' : undefined}
                  disabled={isReadOnly}
                  checked={fieldValue}
                  id={checkboxesInputId}
                  onClick={() => field.onChange(!fieldValue)}
                />
              );
            }}
          />
          {inputFormat.text && (
            <Label
              htmlFor={checkboxesInputId}
              intent={errors[input.field.key] ? 'destructive' : undefined}
            >
              {inputFormat.text}
            </Label>
          )}
        </div>
        <FormErrorMessage errors={errors} name={input.field.key} />
      </>
    );
  }

  if (booleanInputType === 'dropdown') {
    return (
      <>
        <Controller
          name={input.field.key}
          render={({ field }) => (
            <Select
              disabled={isReadOnly}
              defaultValue={field.value}
              value={field.value}
              onValueChange={(val: string) => field.onChange(val)}
            >
              <Select.Trigger
                id={`${view.key}-${input.id}`}
                data-testid="form-view-boolean-input-select-trigger"
                className={cn('py-2', {
                  'border-destructive': errors[input.field.key]
                })}
              />
              <Select.Content className="min-w-20">
                {booleanOptions[inputFormat.format].map((option, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Select.Item key={index} value={option}>
                    {option}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
        <FormErrorMessage errors={errors} name={input.field.key} />
      </>
    );
  }

  return null;
}
