import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios, getAppBasedRequestHeaders } from '@/utils/axiosConfig';

interface CredentialsResponse {
  key: string;
}

async function getCredentials(credential: string) {
  const url = `/v1/credentials/${credential}`;

  const { data } = await axios.get<CredentialsResponse>(url, {
    withCredentials: true,
    headers: getAppBasedRequestHeaders()
  });

  return data;
}

export function useCredentialsQuery({
  enabled = true,
  credential
}: {
  enabled?: boolean;
  credential: string;
}) {
  const queryKey = [queryKeys.credential, credential];

  return useQuery({
    queryKey,
    queryFn: () => getCredentials(credential),
    enabled
  });
}
