import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';

export const detailsDemoData: FormattedViewRecord = {
  values: {
    field_1: 'Example Data',
    field_2: 'Example Data',
    field_3: 'Example Data'
  },
  rawValues: {
    field_1: 'Example Data',
    field_2: 'Example Data',
    field_3: 'Example Data'
  }
};
