import { useTranslation } from 'react-i18next';
import { Skeleton } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';

export function PivotTableRender() {
  const [t] = useTranslation();
  const { theme } = useThemingContext();

  return (
    <div className="relative h-full">
      <div className="flex w-full flex-col items-center justify-center blur-sm">
        <Skeleton className="my-2 w-[90%] p-3" />
        <Skeleton className="my-2 w-[80%] p-3" />
        <Skeleton className="my-2 w-[85%] p-3" />
        <Skeleton className="my-2 w-[60%] p-3" />
        <Skeleton className="my-2 w-[80%] p-3" />
      </div>
      <div className="absolute top-0 flex size-full flex-col items-center justify-center text-center">
        <p
          className={cn('w-[90%] border border-dashed border-brand bg-base p-2', {
            'rounded-lg': theme.appearance.corners === 'rounded'
          })}
        >
          {t('components.views.report.pivot_table_coming_soon')}
        </p>
      </div>
    </div>
  );
}
