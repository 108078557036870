import { type TableDisplayRuleIcon, type TableViewColumn } from '@/types/schema/views/TableView';
import { cn } from '@/utils/tailwind';
import { LabelWithIcon } from '@/components/LabelWithIcon';

interface TableViewCellContentProps {
  shouldHideContent?: boolean;
  columnAlign: TableViewColumn['align'];
  icon: TableDisplayRuleIcon & { color: string };
  value: string;
  children?: React.ReactNode;
  shouldCenterContent?: boolean;
}

export function TableViewCellContent({
  icon,
  columnAlign,
  shouldHideContent,
  value,
  children,
  shouldCenterContent
}: TableViewCellContentProps) {
  return (
    <div
      className={cn('flex items-center', {
        hidden: shouldHideContent,
        'justify-center': shouldCenterContent || columnAlign === 'center',
        'justify-start': columnAlign === 'left' && !shouldCenterContent,
        'justify-end': columnAlign === 'right' && !shouldCenterContent
      })}
    >
      <LabelWithIcon
        icon={icon?.icon}
        iconPosition={icon?.align || 'left'}
        iconStyle={{ color: icon.color }}
      >
        {/* eslint-disable-next-line react/no-danger */}
        {children || <div dangerouslySetInnerHTML={{ __html: value }} />}
      </LabelWithIcon>
    </div>
  );
}
