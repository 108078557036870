import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@knack/asterisk-react';

import { type FormViewNameInput } from '@/types/schema/views/form/Name';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';

export function NameInput({ input }: { input: FormViewNameInput }) {
  const [t] = useTranslation();
  const { view } = useViewContext();
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const nameFormat = input.format?.format;
  const isReadOnly = input.read_only;

  const titleOptions = [
    { label: t('components.views.form.name_input.none'), value: '' },
    { label: t('components.views.form.name_input.ms'), value: 'Ms.' },
    { label: t('components.views.form.name_input.miss'), value: 'Miss.' },
    { label: t('components.views.form.name_input.mr'), value: 'Mr.' },
    { label: t('components.views.form.name_input.mrs'), value: 'Mrs.' },
    { label: t('components.views.form.name_input.dr'), value: 'Dr.' },
    { label: t('components.views.form.name_input.prof'), value: 'Prof.' }
  ];

  return (
    <>
      <div className="flex gap-2">
        {nameFormat === 'Title First Last' && (
          <Controller
            name={`${input.field.key}.title`}
            render={({ field }) => (
              <>
                <Select
                  disabled={isReadOnly}
                  data-testid="form-view-name-title-select"
                  defaultValue="none"
                  value={field.value}
                  onValueChange={(selectedOption) => field.onChange(selectedOption)}
                >
                  <Select.Trigger
                    className={cn('h-10 w-[88px] p-2 [&_svg]:size-4 [&_svg]:text-default', {
                      '[&_span]:text-muted': field.value === 'none'
                    })}
                  />
                  <Select.Content className="min-w-32">
                    {titleOptions.map((option) => (
                      <Select.Item key={option.value} value={option.value || 'none'}>
                        {option.label}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
                <FormErrorMessage errors={errors} name={`${input.field.key}.title`} />
              </>
            )}
          />
        )}
        <Input
          id={`${view.key}-${input.id}-first`}
          disabled={isReadOnly}
          intent={errors[input.field.key] ? 'destructive' : 'default'}
          placeholder={t('components.views.form.name_input.first')}
          className="flex-1"
          data-testid="form-view-name-first-input"
          {...register(`${input.field.key}.first`)}
        />
        {(nameFormat === 'First Middle Last' || nameFormat === 'Last, First Middle') && (
          <Input
            id={`${view.key}-${input.id}-middle`}
            disabled={isReadOnly}
            intent={errors[input.field.key]?.type === 'schema' ? 'destructive' : 'default'}
            className="flex-1"
            placeholder={t('components.views.form.name_input.middle')}
            data-testid="form-view-name-middle-input"
            {...register(`${input.field.key}.middle`)}
          />
        )}
        <Input
          id={`${view.key}-${input.id}-last`}
          disabled={isReadOnly}
          intent={errors[input.field.key] ? 'destructive' : 'default'}
          placeholder={t('components.views.form.name_input.last')}
          className="flex-1"
          data-testid="form-view-name-last-input"
          {...register(`${input.field.key}.last`)}
        />
      </div>
      <FormErrorMessage errors={errors} name={`${input.field.key}`} />
    </>
  );
}
