import { type DetailsView } from '@/types/schema/views/DetailsView';

export const detailsViewDemoView: DetailsView = {
  id: '66ba08315c4e01010ffd8bf4',
  columns: [
    {
      id: 'column_1',
      groups: [
        {
          id: 'group_1',
          columns: [
            [
              {
                id: 'field_1',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_1',
                name: 'Example Label'
              },
              {
                id: 'field_2',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_2',
                name: 'Example Label'
              },
              {
                id: 'field_3',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_3',
                name: 'Example Label'
              }
            ]
          ]
        }
      ],
      width: 100
    }
  ],
  links: [],
  inputs: [],
  name: 'Sample Details View',
  type: 'details',
  source: {
    criteria: {
      match: 'all',
      rules: [],
      groups: []
    },
    limit: '',
    sort: [
      {
        field: 'field_1',
        order: 'asc'
      }
    ],
    object: 'object_1'
  },
  description: '',
  layout: 'full',
  label_format: 'left',
  key: 'view_1',
  groups: [],
  hide_fields: false,
  rules: {
    fields: []
  },
  design: undefined
};
