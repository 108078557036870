import { cn } from '@/utils/tailwind';
import { KnackFontAwesomeIcon } from './font-awesome/KnackFontAwesomeIcon';

interface LabelWithIconProps {
  icon: string | undefined;
  iconPosition: 'left' | 'right';
  iconStyle?: React.CSSProperties;
  spacing?: 'none' | 'sm' | 'md' | 'lg';
  children: React.ReactNode;
}

export function LabelWithIcon({
  icon,
  iconPosition,
  iconStyle,
  children,
  spacing = 'sm'
}: LabelWithIconProps) {
  if (!icon) {
    return children;
  }

  const spacingLeftClass = {
    none: '',
    sm: 'ml-1',
    md: 'ml-2',
    lg: 'ml-3'
  }[spacing];

  const spacingRightClass = {
    none: '',
    sm: 'mr-1',
    md: 'mr-2',
    lg: 'mr-3'
  }[spacing];

  const wrapperClassName = cn('size-4', {
    [spacingRightClass]: children && iconPosition === 'left',
    [spacingLeftClass]: children && iconPosition === 'right'
  });

  const renderIcon = () => (
    <KnackFontAwesomeIcon
      icon={icon}
      style={iconStyle}
      className="size-4"
      wrapperClassName={wrapperClassName}
      spinnerClassName={wrapperClassName}
    />
  );

  return (
    <>
      {iconPosition === 'left' && renderIcon()}
      {children}
      {iconPosition === 'right' && renderIcon()}
    </>
  );
}
