import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiMagnifyingGlass as SearchIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input, Select } from '@knack/asterisk-react';
import { z } from 'zod';

import { type MapView } from '@/types/schema/views/MapView';
import { AddressGoogleAutocompleteInput } from '@/components/AddressGoogleAutocompleteInput';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useMapLocationContext } from '@/components/views/map/MapLocationContext';
import { useViewContext } from '@/components/views/ViewContext';

export function MapSearch() {
  const [t] = useTranslation();
  const { view, sourceTable } = useViewContext<MapView>();
  const { location, range, onLocationChange, resetLocation } = useMapLocationContext();

  const addressField = sourceTable.fields.find((field) => field.key === view.address_field.key);
  const isAddressGoogleAutocompleteEnabled =
    addressField &&
    addressField.type === 'address' &&
    addressField.format &&
    addressField.format.enable_address_autocomplete === true;

  const mapSearchFormSchema = z.object({
    location: z.string().min(1, t('components.views.map.errors.location_is_required')),
    range: z.string()
  });

  type MapSearchFormSchemaType = z.infer<typeof mapSearchFormSchema>;

  const form = useForm<MapSearchFormSchemaType>({
    resolver: zodResolver(mapSearchFormSchema),
    defaultValues: { location, range }
  });

  useEffect(() => {
    form.reset({ location, range });
  }, [form, location, range]);

  const handleFormSubmit = (data: MapSearchFormSchemaType) => {
    if (!data.location) return;
    onLocationChange(data.location, data.range);
  };

  const handleFormReset = () => {
    resetLocation();
    form.reset({ location: '', range: '10' });
  };

  const locationPlaceholder = t('components.views.map.search_placeholder');
  const locationInputIntent = form.formState.errors.location ? 'destructive' : 'default';

  return (
    <div>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleFormSubmit)} className="flex flex-wrap gap-2">
          <div className="max-w-96 flex-1">
            <Input.Container className="">
              {isAddressGoogleAutocompleteEnabled ? (
                <AddressGoogleAutocompleteInput
                  placeholder={locationPlaceholder}
                  intent={locationInputIntent}
                  className="min-w-52 text-sm"
                  {...form.register('location')}
                />
              ) : (
                <Input
                  placeholder={locationPlaceholder}
                  intent={locationInputIntent}
                  className="min-w-52 text-sm"
                  {...form.register('location')}
                />
              )}
              <Input.Icon>
                <SearchIcon size={14} />
              </Input.Icon>
            </Input.Container>
            {form.formState.errors.location && (
              <div className="mt-2 h-2">
                <FormErrorMessage errors={form.formState.errors} name="location" />
              </div>
            )}
          </div>
          <div className="flex h-10 gap-2">
            <Controller
              name="range"
              render={({ field }) => (
                <Select
                  defaultValue={field.value}
                  value={field.value}
                  onValueChange={(val: string) => field.onChange(val)}
                >
                  <Select.Trigger className="min-w-36" />
                  <Select.Content>
                    <Select.Item value="10">
                      {t('components.views.map.range_within')} 10 {view.units}
                    </Select.Item>
                    <Select.Item value="25">
                      {t('components.views.map.range_within')} 25 {view.units}
                    </Select.Item>
                    <Select.Item value="50">
                      {t('components.views.map.range_within')} 50 {view.units}
                    </Select.Item>
                    <Select.Item value="100">
                      {t('components.views.map.range_within')} 100 {view.units}
                    </Select.Item>
                    <Select.Item value="100000">{t('components.views.map.range_any')}</Select.Item>
                  </Select.Content>
                </Select>
              )}
            />

            <Button onClick={form.handleSubmit(handleFormSubmit)}>
              {t('components.views.map.search_button')}
            </Button>
            <Button intent="secondary" onClick={() => handleFormReset()}>
              {t('components.views.map.search_reset_button')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
