import { type UniqueIdentifier } from '@dnd-kit/core';

import { type ReportViewChart } from '@/types/schema/views/ReportView';
import { ChartEditor } from './ChartEditor';
import { SortableChartContextProvider } from './SortableChartContext';

interface DragOverlayContentProps {
  charts: ReportViewChart[];
  beingDraggedChartId: UniqueIdentifier | null;
}
export function ChartDragOverlay({ charts, beingDraggedChartId }: DragOverlayContentProps) {
  const chart = charts.find((c) => c.id === beingDraggedChartId);

  if (!chart) {
    return null;
  }

  return (
    <SortableChartContextProvider chart={chart}>
      <ChartEditor isDragOverlay />
    </SortableChartContextProvider>
  );
}
