import { useRef } from 'react';

import type { ListViewColumn } from '@/types/schema/views/ListView';
import type { FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { useContainerQuery } from '@/hooks/useContainerQuery';
import { cn } from '@/utils/tailwind';
import { ListItem } from '@/components/views/list/ListItem';

type DetailsItemContentProps = {
  record: FormattedViewRecord;
  group: { columns: ListViewColumn[][] };
};

export function DetailsItemContent({ record, group }: DetailsItemContentProps) {
  const containerRef = useRef(null);
  const isSmallVersion = useContainerQuery(containerRef, { maxWidth: 450 });

  return (
    <div ref={containerRef} className={cn('flex gap-4 *:w-full', { 'flex-wrap': isSmallVersion })}>
      {group.columns.map((groupColumn, groupColumnIndex) => (
        <ListItem
          // eslint-disable-next-line react/no-array-index-key
          key={`${record.values.id}-${groupColumnIndex}`}
          listRows={groupColumn}
          record={record}
        />
      ))}
    </div>
  );
}
